import { Record, Set } from 'immutable';
import { IConfig } from '../../types';

export class Config extends Record<IConfig>(
  {
    active: false, // should the portable editor run on this page?
    contextAfterLength: 1000,
    contextBeforeLength: 1000,
    contextWindowLeft: 0, // how many preceding sentences sent as relevant context for grammar
    contextWindowRight: 0, // how many succeeding sentences sent as relevant context for grammar
    customCss: '',
    customWords: Set<string>(), // additional custom words to be sent with requests
    debounceTime: 2000, // delay time between user's keystroke and sending request (ms)
    debounceTimeOnAccept: 0, // delay time between user's accept correction action and sending request (ms)
    debounceTimeOnPaste: 500, // delay time between user's paste action and sending request (ms)
    dictionaries: Set<string>(), // additional dictionaries to be sent with requests
    disableAutoPopup: false,
    grammarScore: false, // whether or not to calculate + display grammar score
    isSensitive: true, // indicate whether any request text can be saved
    linkUrl: 'https://www.perfecttense.com',
    maxBatch: 5, // maximum number of requests that can be sent in parallel
    maxLength: 2000, // maximum request length that can be sent at a time
    responseBatchSize: 30, // max buffer size for correction response
    responseMaxTimeDelay: 100, // max delay for correction response
    sendFeedback: false, // indicate whether or not to send feedback with user interactions
    throttleTime: 500, // rate at which to send requests (ms)
  },
  'Config',
) {
  constructor(obj: Partial<IConfig> = {}) {
    super(obj);
  }
}
