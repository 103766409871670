import { combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  withLatestFrom,
} from 'rxjs/operators';
import {
  IAssets,
  ICorrectionCardInfo,
  IHighlightedCorrection,
  IScrollTargetOffsetToTarget,
  ITargetScroll,
} from '../types';

export const correctionCardPropsFrom = (
  highlightedCorrection$: Observable<IHighlightedCorrection>,
  assets$: Observable<IAssets>,
  targetAbsolutePosition$: Observable<ClientRect | DOMRect>,
  targetScroll$: Observable<ITargetScroll>,
  scrollParentOffsetToTarget$: Observable<IScrollTargetOffsetToTarget>,
  scrollParentScroll$: Observable<ITargetScroll>,
): Observable<ICorrectionCardInfo | null> =>
  combineLatest(
    highlightedCorrection$,
    assets$,
    targetAbsolutePosition$,
    targetScroll$,
    scrollParentScroll$,
  ).pipe(
    withLatestFrom(scrollParentOffsetToTarget$),
    map(
      ([
        [
          { correction },
          { logo },
          {
            bottom: targetBottom,
            left: targetLeft,
            top: targetTop,
            right: targetRight,
          },
          { targetScrollX, targetScrollY },
          {
            targetScrollX: scrollParentScrollX,
            targetScrollY: scrollParentScrollY,
          },
        ],
        { leftOffset, topOffset },
      ]) => {
        if (correction === null) {
          return null;
        }
        const {
          correction: { caption, key, transformations },
          correctionRange,
          pos,
        } = correction;
        const absPos = {
          bottom:
            pos.bottom -
            targetScrollY +
            targetTop -
            topOffset -
            scrollParentScrollY,
          left:
            pos.left -
            targetScrollX +
            targetLeft -
            leftOffset -
            scrollParentScrollX,
          right:
            pos.right -
            targetScrollX +
            targetLeft -
            leftOffset -
            scrollParentScrollX,
          top:
            pos.top -
            targetScrollY +
            targetTop -
            topOffset -
            scrollParentScrollY,
        } as ClientRect;
        const { left, top } = absPos;
        // first correction scroll out of view
        if (
          pos.top - targetScrollY - scrollParentScrollY >
            targetBottom - targetTop + topOffset ||
          pos.left - targetScrollX - scrollParentScrollX >
            targetRight - targetLeft + leftOffset ||
          pos.bottom - targetScrollY - scrollParentScrollY < 5 + topOffset ||
          pos.right - targetScrollX - scrollParentScrollX < leftOffset
        ) {
          return null;
        }
        const tokensAffected = Array.from(correction.correction.tokensAffected);
        const lastTokenAffected = tokensAffected[tokensAffected.length - 1];
        const afterChanged = transformations.some(
          t =>
            t.tokensAdded[t.tokensAdded.length - 1].after !==
            lastTokenAffected.after,
        );
        const options = transformations.map(
          t => t.appliedText + (afterChanged ? t.appliedAfterText : ''),
        );
        return {
          caption,
          correctionRange,
          key,
          left,
          logo,
          options,
          top: top - 30,
        };
      },
    ),
    distinctUntilChanged(),
  );

export const correctionCardKeyFrom = (
  elementFromPoint$: Observable<Element | null>,
  sign: string,
): Observable<string | null> =>
  elementFromPoint$.pipe(
    map(el => {
      if (el === null) {
        return null;
      }
      const closestCard = el.closest(
        '[data-correction-card]',
      ) as HTMLElement | null;
      if (closestCard === null) {
        return null;
      }
      // for always show first correction card, we need to consider each editor individually
      const currentSign =
        closestCard!.dataset.correctionCard &&
        closestCard!.dataset.correctionCard.split(':')[0];
      if (currentSign !== sign) {
        return null;
      }
      return closestCard.dataset.k!;
    }),
    startWith(null),
    distinctUntilChanged(),
  );
