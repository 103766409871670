import Delta from 'quill-delta';
import { combineLatest, fromEvent, merge, NEVER, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  skip,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  IBodyScroll,
  IDiffUpdater,
  IDimension,
  IInputUpdater,
  IMouseMove,
  IMouseMoveWithEl,
  IRelativeOffset,
  IScrollDimension,
  IScrollParent,
  IScrollTargetOffsetToTarget,
  ITargetScroll,
  ITextStateMap,
  UnderlineThickness,
  ZIndex,
} from '../types';
import {
  extractTextFromContentEditable,
  generateValidZIndex,
  getScrollTarget,
  isTextarea,
  isWindow,
  parseCssValueToFloat,
} from '../utils';
import { isExpectingResponse } from './watermarkObservables';

export const stylesheetFor = (
  initialStyles: CSSStyleDeclaration,
  documentMutations$: Observable<HTMLElement>,
  contentWindow: Window,
): Observable<CSSStyleDeclaration> =>
  documentMutations$.pipe(
    map(el => contentWindow.getComputedStyle(el)),
    startWith(initialStyles),
  );

export const pluckStylesFrom = (
  styleSheet$: Observable<CSSStyleDeclaration>,
  propertiesToPluck: string[],
) =>
  styleSheet$.pipe(
    map(styles => ({
      ...propertiesToPluck.reduce((acc, prop: any) => {
        if (prop === 'whiteSpace') {
          // textarea and mirror div treat white space property differently
          if (['nowrap', 'pre'].includes(styles[prop])) {
            return {
              ...acc,
              [prop]: 'pre',
            };
          }
          return {
            ...acc,
            [prop]: 'pre-wrap',
          };
        }
        return {
          ...acc,
          [prop]: styles[prop],
        };
      }, {}),
    })),
    distinctUntilChanged((a: any, b: any) =>
      propertiesToPluck.every(prop => a[prop] === b[prop]),
    ),
  );

export const diffBetween = (
  mutations$: Observable<HTMLElement>,
  target: HTMLElement,
): Observable<IDiffUpdater> =>
  mutations$.pipe(
    map(el => {
      const extracted = isTextarea(target)
        ? target.value
        : extractTextFromContentEditable(el);
      const textContent = el.textContent!;
      const ip = new Delta().insert(extracted);
      const tc = new Delta().insert(textContent);
      const diff = ip.diff(tc);
      return {
        diff,
      };
    }),
  );

export const targetDimensionsNullified = (
  targetAbsolutePosition$: Observable<ClientRect | DOMRect>,
  el: HTMLElement,
  sign: string,
): Observable<ClientRect | DOMRect> => {
  return targetAbsolutePosition$.pipe(
    skip(1),
    filter(pos => {
      const dimensionsNullified =
        pos.height === 0 ||
        pos.width === 0 ||
        el.clientHeight === 0 ||
        el.clientWidth === 0;
      if (dimensionsNullified) {
        // tslint:disable-next-line: no-console
        console.log(
          `node with sign "${sign}" will be removed as dimensions are nullified.`,
        );
      }
      return dimensionsNullified;
    }),
  );
};

export const targetRemoved = (
  mutations$: Observable<MutationRecord[]>,
  el: HTMLElement,
  sign: string,
): Observable<MutationRecord[]> => {
  return mutations$.pipe(
    filter(mutations => {
      return mutations.some(mutation => {
        const nodes = Array.from(mutation.removedNodes);
        const directMatch = nodes.indexOf(el) > -1;
        const parentMatch = nodes.some(parent => {
          if (parent.nodeType !== Node.ELEMENT_NODE) {
            // IE11 Text Node does not have `contains` method.
            // So need to make sure parent is an Element Node.
            return false;
          }
          return parent.contains(el);
        });
        if (directMatch) {
          // tslint:disable-next-line: no-console
          console.log(`node with sign "${sign}" was directly removed!`);
          return true;
        } else if (parentMatch) {
          // tslint:disable-next-line: no-console
          console.log(`node with sign "${sign}" was removed through parent!`);
          return true;
        }
        return false;
      });
    }),
  );
};

export const textContentFor = (
  el: HTMLElement,
  mutation$: Observable<HTMLElement>,
) =>
  mutation$.pipe(
    map(_ => el.textContent!),
    startWith(el.textContent!),
    distinctUntilChanged(),
  );

export const inputFromTextarea = (
  el: HTMLTextAreaElement,
  mutation$: Observable<any>,
) =>
  merge(mutation$, fromEvent(el, 'input')).pipe(
    map(_ => el.value),
    startWith(el.value),
    distinctUntilChanged(),
  );

// offset towards positioned ancestor (with self margin) + self border
export const scrollParentRelativeOffset = (
  docMutations$: Observable<MutationRecord[]>,
  scrollParent$: Observable<IScrollParent>,
  contentWindow: Window,
  bodyScroll$: Observable<ITargetScroll>,
) => {
  return scrollParent$.pipe(
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
    switchMap(({ hasScrollParent, scrollParent }) => {
      if (hasScrollParent && scrollParent) {
        return targetRelativeOffset(
          docMutations$,
          scrollParent,
          contentWindow,
          bodyScroll$,
        );
      }
      return of({ isRelative: false, left: 0, top: 0 });
    }),
  );
};

// the offset left and top from scroll parent to target
export const scrollParentOffsetToTarget = (
  scrollParentAbsolutePosition$: Observable<DOMRect | ClientRect>,
  targetAbsolutePosition$: Observable<DOMRect | ClientRect>,
  scrollParent$: Observable<IScrollParent>,
): Observable<IScrollTargetOffsetToTarget> => {
  return scrollParent$.pipe(
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
    switchMap(({ hasScrollParent }) => {
      if (hasScrollParent) {
        return combineLatest(
          scrollParentAbsolutePosition$,
          targetAbsolutePosition$,
        ).pipe(
          map(
            ([
              { left: scrollParentAbsLeft, top: scrollParentAbsTop },
              { left: targetAbsLeft, top: targetAbsTop },
            ]) => {
              return {
                leftOffset: targetAbsLeft - scrollParentAbsLeft,
                topOffset: targetAbsTop - scrollParentAbsTop,
              };
            },
          ),
          distinctUntilChanged(
            (a, b) =>
              a.leftOffset === b.leftOffset && a.topOffset === b.topOffset,
          ),
        );
      }
      return of({ leftOffset: 0, topOffset: 0 });
    }),
  );
};

export const targetRelativeOffset = (
  docMutations$: Observable<MutationRecord[]>,
  target: HTMLElement,
  contentWindow: Window,
  scrollParentScroll$: Observable<ITargetScroll>,
): Observable<IRelativeOffset> => {
  // offset towards positioned ancestor (offsetTop/Left includes self margin) + self border
  return combineLatest(scrollParentScroll$, docMutations$).pipe(
    map(([{ targetScrollX, targetScrollY }]) => {
      const css = contentWindow.getComputedStyle(target);
      const initialOffset = {
        isRelative: false,
        left:
          target.offsetLeft +
          parseCssValueToFloat(css, 'border-left-width') -
          targetScrollX,
        top:
          target.offsetTop +
          parseCssValueToFloat(css, 'border-top-width') -
          targetScrollY,
      };
      const offset = initialOffset;
      let positionedAncestor = target.offsetParent as HTMLElement | null;
      while (
        positionedAncestor &&
        contentWindow.getComputedStyle(positionedAncestor).position === 'static'
      ) {
        offset.left += positionedAncestor.offsetLeft;
        offset.top += positionedAncestor.offsetTop;
        positionedAncestor = positionedAncestor.offsetParent as HTMLElement | null;
      }
      if (positionedAncestor) {
        offset.isRelative = true;
        return offset;
      }
      return initialOffset;
    }),
    startWith({ isRelative: false, left: 0, top: 0 }),
    distinctUntilChanged(
      (a, b) =>
        a.left === b.left && a.top === b.top && a.isRelative === b.isRelative,
    ),
  );
};

// rect position for scroll parent
export const scrollParentRectPosition = (
  scrollParent$: Observable<IScrollParent>,
  docMutations$: Observable<MutationRecord[]>,
  bodyScroll$: Observable<IBodyScroll>,
  contentWindow: Window,
): Observable<ClientRect | DOMRect> => {
  return scrollParent$.pipe(
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
    switchMap(({ hasScrollParent, scrollParent }) => {
      if (hasScrollParent && scrollParent) {
        const css = contentWindow.getComputedStyle(scrollParent);
        return combineLatest(bodyScroll$, docMutations$).pipe(
          map(() => {
            const pos = scrollParent.getBoundingClientRect();
            return {
              bottom:
                pos.bottom - parseCssValueToFloat(css, 'border-bottom-width'),
              height: scrollParent.clientHeight,
              left: pos.left + parseCssValueToFloat(css, 'border-left-width'),
              right:
                pos.right - parseCssValueToFloat(css, 'border-right-width'),
              top: pos.top + parseCssValueToFloat(css, 'border-top-width'),
              width: scrollParent.clientWidth,
            };
          }),
          distinctUntilChanged(
            (x, y) =>
              x.top === y.top &&
              x.bottom === y.bottom &&
              x.height === y.height &&
              x.left === y.left &&
              x.right === y.right &&
              x.width === y.width,
          ),
        );
      }
      return of({
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
      });
    }),
  );
};

export const targetRectPosition = (
  target: HTMLElement,
  docMutations$: Observable<MutationRecord[]>,
  bodyScroll$: Observable<IBodyScroll>,
  contentWindow: Window,
): Observable<ClientRect | DOMRect> => {
  const css = contentWindow.getComputedStyle(target);
  return combineLatest(bodyScroll$, docMutations$).pipe(
    map(() => {
      const pos = target.getBoundingClientRect();
      return {
        bottom: pos.bottom - parseCssValueToFloat(css, 'border-bottom-width'),
        height: target.clientHeight,
        left: pos.left + parseCssValueToFloat(css, 'border-left-width'),
        right: pos.right - parseCssValueToFloat(css, 'border-right-width'),
        top: pos.top + parseCssValueToFloat(css, 'border-top-width'),
        width: target.clientWidth,
      };
    }),
    distinctUntilChanged(
      (x, y) =>
        x.top === y.top &&
        x.bottom === y.bottom &&
        x.height === y.height &&
        x.left === y.left &&
        x.right === y.right &&
        x.width === y.width,
    ),
  );
};

export const scrollTargetAbsolutePositionFromDocument = (
  scrollParent$: Observable<IScrollParent>,
  docMutations$: Observable<MutationRecord[]>,
  bodyScroll$: Observable<IBodyScroll>,
  contentWindow: Window,
): Observable<ClientRect | DOMRect> => {
  return scrollParent$.pipe(
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
    switchMap(({ hasScrollParent, scrollParent }) => {
      if (hasScrollParent && scrollParent) {
        const css = contentWindow.getComputedStyle(scrollParent);
        return combineLatest(bodyScroll$, docMutations$).pipe(
          map(() => {
            const pos = scrollParent.getBoundingClientRect();
            const scrollLeft = contentWindow.pageXOffset;
            const scrollTop = contentWindow.pageYOffset;
            return {
              bottom:
                pos.bottom +
                scrollTop -
                parseCssValueToFloat(css, 'border-bottom-width'),
              height: scrollParent.clientHeight,
              left:
                pos.left +
                scrollLeft +
                parseCssValueToFloat(css, 'border-left-width'),
              right:
                pos.right +
                scrollLeft -
                parseCssValueToFloat(css, 'border-right-width'),
              top:
                pos.top +
                scrollTop +
                parseCssValueToFloat(css, 'border-top-width'),
              width: scrollParent.clientWidth,
            };
          }),
          distinctUntilChanged(
            (x, y) =>
              x.top === y.top &&
              x.bottom === y.bottom &&
              x.height === y.height &&
              x.left === y.left &&
              x.right === y.right &&
              x.width === y.width,
          ),
        );
      }
      return of({
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
      });
    }),
  );
};

export const targetAbsolutePositionFromDocument = (
  target: HTMLElement,
  docMutations$: Observable<MutationRecord[]>,
  bodyScroll$: Observable<IBodyScroll>,
  contentWindow: Window,
): Observable<ClientRect | DOMRect> => {
  const css = contentWindow.getComputedStyle(target);
  return combineLatest(bodyScroll$, docMutations$).pipe(
    map(() => {
      const pos = target.getBoundingClientRect();
      const scrollLeft = contentWindow.pageXOffset;
      const scrollTop = contentWindow.pageYOffset;
      return {
        bottom:
          pos.bottom +
          scrollTop -
          parseCssValueToFloat(css, 'border-bottom-width'),
        height: target.clientHeight,
        left:
          pos.left +
          scrollLeft +
          parseCssValueToFloat(css, 'border-left-width'),
        right:
          pos.right +
          scrollLeft -
          parseCssValueToFloat(css, 'border-right-width'),
        top:
          pos.top + scrollTop + parseCssValueToFloat(css, 'border-top-width'),
        width: target.clientWidth,
      };
    }),
    distinctUntilChanged(
      (x, y) =>
        x.top === y.top &&
        x.bottom === y.bottom &&
        x.height === y.height &&
        x.left === y.left &&
        x.right === y.right &&
        x.width === y.width,
    ),
  );
};

// without margin and border
export const targetAbsolutePositionFrom = (
  target: HTMLElement,
  styleSheet$: Observable<CSSStyleDeclaration>,
  bodyScroll$: Observable<IBodyScroll>,
  contentWindow: Window,
): Observable<ClientRect | DOMRect> =>
  combineLatest(styleSheet$, bodyScroll$).pipe(
    map<[CSSStyleDeclaration, IBodyScroll], ClientRect | DOMRect>(
      ([styles]) => {
        const scrollLeft = contentWindow.pageXOffset;
        const scrollTop = contentWindow.pageYOffset;
        const pos = target.getBoundingClientRect();
        const height =
          styles.boxSizing === 'border-box'
            ? target.clientHeight +
              parseFloat(styles.borderTopWidth!) +
              parseFloat(styles.borderBottomWidth!)
            : target.clientHeight -
              parseFloat(styles.paddingTop!) -
              parseFloat(styles.paddingBottom!);
        const width =
          styles.boxSizing === 'border-box'
            ? target.clientWidth +
              parseFloat(styles.borderLeftWidth!) +
              parseFloat(styles.borderRightWidth!)
            : target.clientWidth -
              parseFloat(styles.paddingLeft!) -
              parseFloat(styles.paddingRight!);
        return {
          ...pos,
          bottom: pos.bottom + scrollTop,
          height,
          left: pos.left + scrollLeft,
          right: pos.right + scrollLeft,
          top: pos.top + scrollTop,
          width,
        };
      },
    ),
    distinctUntilChanged(
      (x, y) =>
        x.top === y.top &&
        x.bottom === y.bottom &&
        x.height === y.height &&
        x.left === y.left &&
        x.right === y.right &&
        x.width === y.width,
    ),
  );

export const jobTextFrom = (
  bodyMutation$: Observable<HTMLElement>,
  initialValue: string = '',
): Observable<IInputUpdater> =>
  bodyMutation$.pipe(
    map(el => ({ input: extractTextFromContentEditable(el) })),
    startWith({ input: initialValue }),
    distinctUntilChanged((a, b) => a.input === b.input),
  );

export const jobTextForTextarea = (
  bodyMutation$: Observable<HTMLElement>,
  textareaTarget: HTMLTextAreaElement,
): Observable<IInputUpdater> => {
  return bodyMutation$.pipe(
    map(_ => {
      return textareaTarget.value;
    }),
    startWith(textareaTarget.value),
    distinctUntilChanged(),
    map(val => ({ input: val })),
  );
};

// in case like gmail, where scrollParent is positionedAncestor
// when scroll down, our correction container position moves up (absolute to positionedAncestor)
// so the one inside this div (position: relative, scroll synced with scrollParent)
// does not need to scroll anymore
export const scrollWithNonPositionedScrollParent = (
  parentWindow: Window,
  scrollParent$: Observable<IScrollParent>,
  scrollParentScroll$: Observable<ITargetScroll>,
): Observable<ITargetScroll> => {
  return scrollParent$.pipe(
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
    switchMap(({ hasScrollParent, scrollParent }) => {
      if (
        hasScrollParent &&
        scrollParent &&
        parentWindow.getComputedStyle(scrollParent).position === 'static'
      ) {
        return scrollParentScroll$;
      }
      return of({
        targetScrollX: 0,
        targetScrollY: 0,
      });
    }),
  );
};

// for watermark, when scrollParent is positionedAncestor
// we want to offset the scroll since we dont want watermark change position
export const scrollWithPositionedScrollParent = (
  parentWindow: Window,
  scrollParent$: Observable<IScrollParent>,
  scrollParentScroll$: Observable<ITargetScroll>,
): Observable<ITargetScroll> => {
  return scrollParent$.pipe(
    distinctUntilChanged(
      (a, b) =>
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position,
    ),
    switchMap(({ hasScrollParent, scrollParent }) => {
      if (
        hasScrollParent &&
        scrollParent &&
        parentWindow.getComputedStyle(scrollParent).position !== 'static'
      ) {
        return scrollParentScroll$;
      }
      return of({
        targetScrollX: 0,
        targetScrollY: 0,
      });
    }),
  );
};

export const dynamicTargetScrollFor = (
  scrollParent$: Observable<IScrollParent>,
  originalTarget: HTMLElement,
): Observable<ITargetScroll> => {
  return scrollParent$.pipe(
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
    switchMap(({ hasScrollParent, scrollParent }) => {
      if (hasScrollParent && scrollParent) {
        let initTargetScroll = {
          targetScrollX: scrollParent.scrollLeft,
          targetScrollY: scrollParent.scrollTop,
        };
        if (originalTarget.offsetParent === scrollParent) {
          initTargetScroll = {
            targetScrollX: 0,
            targetScrollY: 0,
          };
        }

        return fromEvent(scrollParent, 'scroll').pipe(
          map((e: any) => {
            e.stopPropagation();
            if (originalTarget.offsetParent !== scrollParent) {
              return {
                targetScrollX: scrollParent.scrollLeft,
                targetScrollY: scrollParent.scrollTop,
              };
            }
            return initTargetScroll;
          }),
          startWith(initTargetScroll),
        );
      }
      return of({
        targetScrollX: 0,
        targetScrollY: 0,
      });
    }),
  );
};

export const targetScrollFor = (
  el: Window | HTMLElement,
): Observable<ITargetScroll> => {
  let initTargetScroll: ITargetScroll;
  if (isWindow(el)) {
    initTargetScroll = {
      targetScrollX: el.pageXOffset,
      targetScrollY: el.pageYOffset,
    };
  } else {
    initTargetScroll = {
      targetScrollX: el.scrollLeft,
      targetScrollY: el.scrollTop,
    };
  }
  return fromEvent(el, 'scroll').pipe(
    map((e: any) => {
      e.stopPropagation();
      if (isWindow(el)) {
        return {
          targetScrollX: el.pageXOffset,
          targetScrollY: el.pageYOffset,
        };
      } else {
        return {
          targetScrollX: el.scrollLeft,
          targetScrollY: el.scrollTop,
        };
      }
    }),
    startWith(initTargetScroll),
  );
};

export const underlineThicknessFrom = (
  targetMutation$: Observable<HTMLElement>,
  contentWindow: Window,
) =>
  targetMutation$.pipe(
    map(el =>
      parseCssValueToFloat(contentWindow.getComputedStyle(el), 'font-size'),
    ),
    map(fontSize => {
      return fontSize > 14
        ? UnderlineThickness.Large
        : UnderlineThickness.Small;
    }),
    startWith(UnderlineThickness.Small),
    distinctUntilChanged(),
  );

// false when no job ever sent, true after first job sent
export const everSentJob = (
  textState$: Observable<ITextStateMap>,
): Observable<boolean> =>
  textState$.pipe(
    map(textStateMap => textStateMap.textState.jobMap.size > 0),
    startWith(false),
    distinctUntilChanged(),
  );

// -1 if waiting for response or no job ever sent
// otherwise return textState.score if in 0 - 100
export const grammarScoreFrom = (
  textState$: Observable<ITextStateMap>,
): Observable<number> =>
  combineLatest(
    textState$,
    isExpectingResponse(textState$),
    everSentJob(textState$),
  ).pipe(
    map(([{ textState }, loading, firstJobSent]) =>
      loading || !firstJobSent
        ? -1
        : Math.max(0, Math.min(textState.score, 100)),
    ),
    distinctUntilChanged(),
  );

export const mouseMovesToIframe = (
  isIframeEl: boolean,
  contentWindow: Window,
  targetAbsolutePosition$: Observable<ClientRect | DOMRect>,
  targetScroll$: Observable<ITargetScroll>,
): Observable<IMouseMoveWithEl> => {
  if (isIframeEl) {
    return fromEvent<MouseEvent>(contentWindow, 'mousemove').pipe(
      withLatestFrom(targetAbsolutePosition$, targetScroll$),
      map(
        ([
          { pageX, pageY },
          { left, top },
          { targetScrollX, targetScrollY },
        ]) => {
          return {
            clientX: pageX + left - targetScrollX,
            clientY: pageY + top - targetScrollY,
            inIframe: true,
          };
        },
      ),
    );
  } else {
    return NEVER;
  }
};

export const elementFromPoint = (
  mouseMove$: Observable<IMouseMove>,
  contentWindow: Window,
): Observable<Element | null> =>
  combineLatest(
    mouseMove$,
    fromEvent<MouseEvent>(contentWindow, 'click').pipe(startWith(null)),
  ).pipe(
    map(([{ clientX, clientY }]) => {
      return contentWindow.document.elementFromPoint(
        clientX - contentWindow.pageXOffset,
        clientY - contentWindow.pageYOffset,
      );
    }),
    distinctUntilChanged(),
  );

export const mouseMoveFromDoc = (
  contentWindow: Window,
): Observable<IMouseMoveWithEl> =>
  fromEvent<MouseEvent>(contentWindow, 'mousemove').pipe(
    map(e => ({ clientX: e.pageX, clientY: e.pageY, inIframe: false })),
  );

export const scrollFromBody = (
  contentWindow: Window,
): Observable<IBodyScroll> => {
  const initScroll = {
    scrollX: contentWindow.pageXOffset,
    scrollY: contentWindow.pageYOffset,
    target: (contentWindow.document as unknown) as HTMLElement,
  };
  return fromEvent(contentWindow, 'scroll', {
    capture: true,
  }).pipe(
    map((e: any) => ({
      scrollX: contentWindow.pageXOffset,
      scrollY: contentWindow.pageYOffset,
      target: e.target as HTMLElement,
    })),
  );
};

export const marginBorderOffsetFor = (
  el: HTMLElement,
  documentMutations$: Observable<MutationRecord[]>,
  contentWindow: Window,
) => {
  return documentMutations$.pipe(
    map(_ => {
      const css = contentWindow.getComputedStyle(el);
      return {
        left:
          parseCssValueToFloat(css, 'border-left-width') +
          parseCssValueToFloat(css, 'margin-left'),
        top:
          parseCssValueToFloat(css, 'border-top-width') +
          parseCssValueToFloat(css, 'margin-top'),
      };
    }),
    startWith({ left: 0, top: 0 }),
    distinctUntilChanged((a, b) => a.left === b.left && a.top === b.top),
  );
};

// get height and width for the sum of target and scroll parent scroll height/width
// these number becomes the dimension of the div inside our shadow container.
// needs to be big enough so we can set scrollTop of our container and things still align
export const scrollDimensionFor = (
  bodyMutations$: Observable<HTMLElement>,
  target: HTMLElement,
  scrollParentScroll$: Observable<IScrollParent>,
): Observable<IScrollDimension> => {
  return scrollParentScroll$.pipe(
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
    switchMap(({ hasScrollParent, scrollParent }) => {
      if (hasScrollParent && scrollParent) {
        return bodyMutations$.pipe(
          map(el => {
            return {
              scrollHeight: el.scrollHeight + scrollParent.scrollHeight,
              scrollWidth: el.scrollWidth + scrollParent.scrollWidth,
            };
          }),
          startWith({
            scrollHeight: target.scrollHeight + scrollParent.scrollHeight,
            scrollWidth: target.scrollWidth + scrollParent.scrollWidth,
          }),
          distinctUntilChanged(),
        );
      }
      return bodyMutations$.pipe(
        map(el => {
          return {
            scrollHeight: el.scrollHeight,
            scrollWidth: el.scrollWidth,
          };
        }),
        startWith({
          scrollHeight: target.scrollHeight,
          scrollWidth: target.scrollWidth,
        }),
        distinctUntilChanged(),
      );
    }),
  );
};

export const correctionContainerPos = (
  scrollTargetAbsolutePosition$: Observable<ClientRect | DOMRect>,
  targetAbsolutePosition$: Observable<ClientRect | DOMRect>,
  scrollParent$: Observable<IScrollParent>,
): Observable<IDimension> => {
  return scrollParent$.pipe(
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
    switchMap(({ hasScrollParent }) => {
      if (hasScrollParent) {
        return combineLatest(
          scrollTargetAbsolutePosition$,
          targetAbsolutePosition$,
        ).pipe(
          map(([scrollElPos, pos]) => {
            // If for some reason scroll parent is human invisible, ignore it.
            // e.g. trello card, scroll parent element height is 0
            if (scrollElPos.height === 0 || scrollElPos.width === 0) {
              return { height: pos.height, width: pos.width };
            }
            return {
              height: Math.min(pos.height, scrollElPos.height),
              width: Math.min(pos.width, scrollElPos.width),
            };
          }),
          distinctUntilChanged(
            (a, b) => a.height === b.height && a.width === b.width,
          ),
        );
      }
      return targetAbsolutePosition$.pipe(
        map(({ height, width }) => ({ height, width })),
        distinctUntilChanged(
          (a, b) => a.height === b.height && a.width === b.width,
        ),
      );
    }),
  );
};

export const dynamicScrollParentFrom = (
  parentWindow: Window,
  documentMutations$: Observable<MutationRecord[]>,
  originalTarget: HTMLElement,
): Observable<IScrollParent> => {
  const initScrollParent = getScrollTarget(originalTarget, parentWindow);
  const initHasScrollParent = !!(
    initScrollParent && initScrollParent !== originalTarget
  );
  const initPosition = initHasScrollParent
    ? (parentWindow.getComputedStyle(initScrollParent as HTMLElement)
        .position as string)
    : '';
  return documentMutations$.pipe(
    map(_ => {
      const scrollParent = getScrollTarget(
        originalTarget.parentElement,
        parentWindow,
      );
      const hasScrollParent = !!(
        scrollParent && scrollParent !== originalTarget
      );
      return {
        hasScrollParent,
        position: scrollParent
          ? (parentWindow.getComputedStyle(scrollParent).position as string)
          : '',
        scrollParent,
      };
    }),
    startWith({
      hasScrollParent: initHasScrollParent,
      position: initPosition,
      scrollParent: initScrollParent,
    }),
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
  );
};

export const ZIndexFor = (
  originalTarget: HTMLElement,
  targetMutation$: Observable<HTMLElement>,
  contentWindow: Window,
): Observable<ZIndex> => {
  return targetMutation$.pipe(
    map(_ => {
      return generateValidZIndex(
        contentWindow.getComputedStyle(originalTarget).zIndex,
      );
    }),
    distinctUntilChanged(),
    startWith(
      generateValidZIndex(
        contentWindow.getComputedStyle(originalTarget).zIndex,
      ),
    ),
  );
};

export const paddingFor = (
  originalTarget: HTMLElement,
  targetMutation$: Observable<HTMLElement>,
  contentWindow: Window,
): Observable<{ paddingRight: number; paddingBottom: number }> => {
  const initCss = contentWindow.getComputedStyle(originalTarget);
  const initPaddingRight = parseCssValueToFloat(initCss, 'padding-right');
  const initPaddingBottom = parseCssValueToFloat(initCss, 'padding-bottom');
  return targetMutation$.pipe(
    map(_ => {
      const css = contentWindow.getComputedStyle(originalTarget);
      const paddingRight = parseCssValueToFloat(css, 'padding-right');
      const paddingBottom = parseCssValueToFloat(css, 'padding-bottom');
      return { paddingBottom, paddingRight };
    }),
    distinctUntilChanged(
      (a, b) =>
        a.paddingBottom === b.paddingBottom &&
        a.paddingRight === b.paddingRight,
    ),
    startWith({
      paddingBottom: initPaddingBottom,
      paddingRight: initPaddingRight,
    }),
  );
};
