import { from, fromEvent, merge, Observable } from 'rxjs';
import { filter, map, mapTo, mergeMap, take } from 'rxjs/operators';
import { ActiveEditables } from '../state';
import { EditorType, ITargetWithType } from '../types';
import { targetFromSelectionAnchor } from '../utils';

const innerDemensions = (node: HTMLElement, contentWindow: Window) => {
  const computedStyle = contentWindow.getComputedStyle(node);

  let height = node.clientHeight; // height with padding
  let width = node.clientWidth; // width with padding

  height -=
    parseFloat(computedStyle.paddingTop!) +
    parseFloat(computedStyle.paddingBottom!);
  width -=
    parseFloat(computedStyle.paddingLeft!) +
    parseFloat(computedStyle.paddingRight!);
  return { height, width };
};

export const newlyFocusedNonIframeEditables = (
  contentWindow: Window,
): Observable<ITargetWithType> => {
  const doc = contentWindow.document;
  return merge(
    fromEvent(doc, 'focusin'),
    fromEvent(doc, 'selectionchange'),
  ).pipe(
    map(_ => {
      const sel = contentWindow.getSelection();
      if (sel === null) {
        return null;
      }
      // For IE early return if on text area,
      // as sometimes selection anchor node is null.
      if (
        contentWindow.document.activeElement &&
        contentWindow.document.activeElement.nodeName === 'TEXTAREA'
      ) {
        return {
          sign: null,
          target: contentWindow.document.activeElement as HTMLElement,
          type: EditorType.Textarea,
        } as ITargetWithType;
      }
      const target = targetFromSelectionAnchor(sel.anchorNode);
      if (!target) {
        return null;
      } else {
        return {
          sign: null,
          target,
          type: EditorType.ContentEditable,
        };
      }
    }),
    filter((x): x is ITargetWithType => x !== null),
    map(({ target, type }) => {
      return {
        sign: ActiveEditables.addEditable(type, target),
        target,
        type,
      };
    }),
    filter(({ sign, target }) => {
      if (sign === null) {
        return false;
      } else {
        const { height, width } = innerDemensions(target, contentWindow);
        return height > 0 && width > 0;
      }
    }),
  );
};

export const newlyAddedNonIframeEditables = (
  mutations$: Observable<MutationRecord[]>,
) => {
  return mutations$.pipe(
    map(mutations => {
      return mutations.reduce<HTMLElement[]>((acc, mutation) => {
        switch (mutation.type) {
          case 'attributes':
            if (
              mutation.attributeName!.toLowerCase() === 'contenteditable' &&
              (mutation.target as HTMLElement).isContentEditable
            ) {
              const target = targetFromSelectionAnchor(mutation.target);
              return target ? [...acc, target] : acc;
            } else {
              return acc;
            }
          case 'characterData':
            return acc;
          case 'childList':
            return Array.from(mutation.addedNodes).reduce((acc2, node) => {
              if (node.nodeType !== Node.ELEMENT_NODE) {
                return acc2;
              } else if (node.nodeName === 'TEXTAREA') {
                return [...acc2, node as HTMLTextAreaElement];
              } else {
                const target = targetFromSelectionAnchor(node);
                return target ? [...acc2, target] : acc2;
              }
            }, acc);
        }
      }, []);
    }),
  );
};

export const newlyFocusedIframeEditables = (
  iframes: HTMLIFrameElement[],
  contentWindow: Window,
) => {
  return from(iframes).pipe(
    filter(
      iframe =>
        iframe.contentDocument !== null &&
        iframe.contentDocument!.body &&
        iframe.contentDocument!.body.isContentEditable,
    ),
    mergeMap(iframe => {
      return fromEvent(iframe.contentDocument!, 'click').pipe(
        take(1),
        mapTo({
          sign: null,
          target: iframe,
          type: EditorType.IframeContentEditable,
        }),
      );
    }),
    map(({ target, type }) => {
      return {
        sign: ActiveEditables.addEditable(type, target),
        target,
        type,
      };
    }),
    filter(({ sign }) => sign !== null),
    filter(({ target }) => {
      const { height, width } = innerDemensions(target, contentWindow);
      return height > 0 && width > 0;
    }),
  );
};

export const newlyAddedIframeEditables = (
  mutations$: Observable<MutationRecord[]>,
) => {
  return mutations$.pipe(
    map(mutations => {
      return mutations.reduce<HTMLIFrameElement[]>((acc, mutation) => {
        return Array.from(mutation.addedNodes).reduce((acc2, node) => {
          if (node.nodeType !== Node.ELEMENT_NODE) {
            return acc2;
          } else if (node.nodeName === 'IFRAME') {
            const iframeEl = node as HTMLIFrameElement;
            if (
              iframeEl.contentDocument &&
              iframeEl.contentDocument.body &&
              iframeEl.contentDocument.body.isContentEditable
            ) {
              return [...acc2, iframeEl];
            }
          }
          return acc2;
        }, acc);
      }, []);
    }),
  );
};

export const newlyAddedFocusedIframeEditables = (
  mutations$: Observable<MutationRecord[]>,
  contentWindow: Window,
) => {
  return newlyAddedIframeEditables(mutations$).pipe(
    mergeMap(iframes => newlyFocusedIframeEditables(iframes, contentWindow)),
  );
};
