import { Set } from 'immutable';

// tslint:disable-next-line: no-namespace
export declare namespace SocketResponseType {
  export type Assets = 'assets';
  export type Config = 'config';
  export type Corrections = 'corrections';
  export type Mongo = 'mongo';
  export type Tokenized = 'tokenized';
  export type Unchanged = 'unchanged';
}

export const SocketResponseType: Readonly<{
  Assets: SocketResponseType.Assets;
  Config: SocketResponseType.Config;
  Corrections: SocketResponseType.Corrections;
  Mongo: SocketResponseType.Mongo;
  Tokenized: SocketResponseType.Tokenized;
  Unchanged: SocketResponseType.Unchanged;
}> = Object.freeze({
  Assets: 'assets',
  Config: 'config',
  Corrections: 'corrections',
  Mongo: 'mongo',
  Tokenized: 'tokenized',
  Unchanged: 'unchanged',
});

export type SocketResponseType =
  | SocketResponseType.Assets
  | SocketResponseType.Tokenized
  | SocketResponseType.Corrections
  | SocketResponseType.Unchanged
  | SocketResponseType.Mongo
  | SocketResponseType.Config;

export type JobResponseType =
  | SocketResponseType.Tokenized
  | SocketResponseType.Corrections
  | SocketResponseType.Unchanged;

export interface IMessage<C extends SocketResponseType> {
  channel: C;
  msg: ISocketResponse<C>;
}

export type SocketMessage = IMessage<SocketResponseType>;

export interface IFeedbackParams {
  backendTransformIndices: number[];
  isAccepted: boolean;
  jobId: string;
  offset: number;
  sentence: string;
  sentenceIndex: number;
  transformIndex: number;
}

export type SocketResponse =
  | IAssetsResponse
  | IConfigResponse
  | ICorrectionResponse
  | IMongoResponse
  | ITokenizedResponse
  | IUnchangedResponse;

export type JobResponse = ISocketResponse<JobResponseType>;

export interface ISocketResponse<C extends SocketResponseType> {
  key?: string;
  _?: C;
}

export interface IAssetsResponse
  extends ISocketResponse<SocketResponseType.Assets>,
    IAssets {}

export interface IConfigResponse
  extends ISocketResponse<SocketResponseType.Config>,
    Partial<IConfig> {}

export interface ICorrectionResponse
  extends ISocketResponse<SocketResponseType.Corrections> {
  corrections: IGroupedCorrection[];
  currentTokens: IToken[];
  key: string;
  score: number;
  sentenceIndex: number;
}

export interface IMongoResponse
  extends ISocketResponse<SocketResponseType.Mongo> {
  key: string;
  mongoId: number;
}

export interface ITokenizedResponse
  extends ISocketResponse<SocketResponseType.Tokenized> {
  key: string;
  tokenized: number[];
  whitespaceBefore: string;
}

export interface IUnchangedResponse
  extends ISocketResponse<SocketResponseType.Unchanged> {
  key: string;
  sentenceIndex: number;
}

export interface IAssets {
  icon: string;
  logo: string;
}

export interface ITransformation {
  appliedAfterText: string;
  appliedText: string;
  key: string;
  sentenceIndex: number;
  signature: string;
  trIdx: number;
  type: TransformationType;
  confidence: number;
  hasReplacement: boolean;
  isSuggestion: boolean;
  message: string;
  penalty: number;
  requestId?: number;
  ruleId: string;
  sentAfterTransform: string;
  status?: string;
  tokensAffected: IToken[];
  tokensAdded: IToken[];
}

export interface IGroupedCorrection {
  afterText: string;
  applicable: boolean;
  applied: number;
  caption: string;
  decoratedText: string;
  initialEndIndex: number;
  initialStartIndex: number;
  jobKey: string;
  key: string;
  message: string;
  signature: string;
  transformations: ITransformation[];
}

export enum TransformationType {
  Replacement = 'replacement',
  SentenceRemark = 'sentenceRemark',
  Suggestion = 'suggestion',
  TokenRemark = 'tokenRemark',
}

export interface IToken {
  after: string;
  id: number;
  value: string;
}

export interface IConfig {
  debounceTime: number; // delay time between user's keystroke and sending request (ms)
  throttleTime: number; // rate at which to send requests (ms)
  maxLength: number; // maximum request length that can be sent at a time
  maxBatch: number; // maximum number of requests that can be sent in parallel
  contextAfterLength: number; // number of characters to the right to send in Job.contextAfter
  contextBeforeLength: number; // number of characters to the left to send in Job.contextBefore
  contextWindowLeft: number; // how many preceding sentences sent as relevant context for grammar
  contextWindowRight: number; // how many succeeding sentences sent as relevant context for grammar
  active: boolean; // should the portable editor run on this page?
  grammarScore: boolean; // whether or not to calculate + display grammar score
  sendFeedback: boolean; // indicate whether or not to send feedback with user interactions
  isSensitive: boolean; // indicate whether any request text can be saved
  dictionaries: Set<string>; // additional dictionaries to be sent with requests
  customWords: Set<string>; // additional custom words to be sent with requests
  linkUrl: string;
  customCss: string;
  responseBatchSize: number; // max buffer size for correction response
  responseMaxTimeDelay: number; // max delay for correction response
  debounceTimeOnPaste: number; // delay time between user's paste action and sending request (ms)
  debounceTimeOnAccept: number; // delay time between user's accept correction action and sending request (ms)
  disableAutoPopup: boolean;
}
