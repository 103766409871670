export const createContainerDiv = (parentWindow: Window): HTMLDivElement => {
  const containerDiv = parentWindow.document.createElement('div');
  containerDiv.style.position = 'absolute';
  containerDiv.style.left = '0px';
  containerDiv.style.top = '0px';
  containerDiv.style.pointerEvents = 'none';
  containerDiv.style.borderStyle = 'none';
  containerDiv.style.borderColor = 'transparent';
  containerDiv.style.padding = '0px';
  return containerDiv;
};
