import { ICorrection, ICorrectionRange, INodeTraversalData } from '../types';

export const nodeTraversalDataFor = (
  node: HTMLElement | ChildNode,
  accum: INodeTraversalData[] = [],
  level: number = 0,
) => {
  Array.from(node.childNodes).forEach(child => {
    if (child.nodeType === Node.TEXT_NODE) {
      const prevTraversalData = accum[accum.length - 1] || { till: 0 };
      accum.push({
        level,
        node: child as Text,
        till: prevTraversalData.till + child.textContent!.length,
      });
    }
    nodeTraversalDataFor(child, accum, level + 1);
  });
  return accum;
};

export const findOffsetInTraversalData = (
  ranges: INodeTraversalData[],
  offset: number,
  isLast: boolean = false,
): number => {
  return ranges.findIndex((r, i, arr) => {
    if (isLast) {
      if (i === 0) {
        return offset <= r.node.textContent!.length;
      } else {
        const prevTill = arr[i - 1].till;
        return offset <= prevTill + r.node.textContent!.length;
      }
    } else {
      if (i === 0) {
        return offset < r.node.textContent!.length;
      } else {
        const prevTill = arr[i - 1].till;
        return offset < prevTill + r.node.textContent!.length;
      }
    }
  });
};

export const getRanges = (dom: HTMLElement) => {
  const doc = dom.ownerDocument || ((dom as unknown) as Document);
  const text = dom.textContent || '';
  const length = text.length;
  const parsed = nodeTraversalDataFor(dom);
  return (correction: ICorrection): ICorrectionRange[] => {
    const {
      correction: correctionRecord,
      endOffset,
      endOffsetForTextContent,
      startOffset,
      startOffsetForTextContent,
    } = correction;
    try {
      if (
        startOffset < 0 ||
        endOffset < 0 ||
        length < endOffset ||
        length < startOffset ||
        startOffset >= endOffset
      ) {
        throw new Error('Range out of bounds.');
      }

      const startRangeIdx = findOffsetInTraversalData(
        parsed,
        correction.startOffset,
      );
      const endRangeIdx = findOffsetInTraversalData(
        parsed,
        correction.endOffset,
        true,
      );

      const startRange = parsed[startRangeIdx];
      const endRange = parsed[endRangeIdx];

      const prevStartRange = parsed[startRangeIdx - 1] || { till: 0 };
      const prevEndRange = parsed[endRangeIdx - 1] || { till: 0 };

      const offsetInStartNode = startOffset - prevStartRange.till;
      const offsetInEndNode = endOffset - prevEndRange.till;

      const tr = doc.createRange();
      tr.setStart(startRange.node, offsetInStartNode);
      tr.setEnd(startRange.node, offsetInStartNode + 1);
      const docLineHeight = tr.getBoundingClientRect().height;

      const resultRange = doc.createRange();
      resultRange.setStart(startRange.node, offsetInStartNode);
      resultRange.setEnd(endRange.node, offsetInEndNode);
      const resultLineHeight = resultRange.getBoundingClientRect().height;
      if (Math.round(resultLineHeight / docLineHeight) === 1) {
        // early return as this is not multiline node
        return [
          {
            correction: correctionRecord,
            endNode: endRange.node,
            endOffset,
            endOffsetForTextContent,
            offsetInEndNode,
            offsetInStartNode,
            startNode: startRange.node,
            startOffset,
            startOffsetForTextContent,
          },
        ];
      }
      const cached: any = {
        [startOffset]: {
          start: startRange.node,
          startOffset: offsetInStartNode,
        },
      };
      return splitRanges(correction, parsed, cached, docLineHeight, doc);
    } catch (e) {
      if (e.message !== 'Range out of bounds.') {
        // tslint:disable-next-line: no-console
        console.log('failed', e.message);
        // tslint:disable-next-line: no-console
        console.log('args', { dom, parsed, correction });
      }
      return [];
    }
  };
};
const splitRanges = (
  correction: ICorrection,
  parsed: INodeTraversalData[],
  cached: any,
  docLineHeight: number,
  doc: Document,
) => {
  let i = correction.startOffset + 1;
  let lastBreak = correction.startOffset;
  const docRanges: ICorrectionRange[] = [];
  while (i <= correction.endOffset) {
    const iRangeIdx = findOffsetInTraversalData(
      parsed,
      i,
      i === correction.endOffset,
    );
    const iRangeEl = parsed[iRangeIdx];
    const prevRange = parsed[iRangeIdx - 1] || { till: 0 };
    const iOffset = Math.min(
      i - prevRange.till,
      iRangeEl.node.textContent!.length,
    );
    const range = doc.createRange();
    range.setStart(cached[lastBreak].start, cached[lastBreak].startOffset);
    range.setEnd(iRangeEl.node, iOffset);
    const pos = range.getBoundingClientRect();
    const lines = Math.round(pos.height / docLineHeight);
    if (lines > 1) {
      // wrap up the current range
      // Time to break
      docRanges.push({
        correction: correction.correction,
        endNode: cached[i - 1].start,
        endOffset: correction.endOffset,
        endOffsetForTextContent: correction.endOffsetForTextContent,
        offsetInEndNode: cached[i - 1].startOffset,
        offsetInStartNode: cached[lastBreak].startOffset,
        startNode: cached[lastBreak].start,
        startOffset: correction.startOffset,
        startOffsetForTextContent: correction.startOffsetForTextContent,
      });
      lastBreak = i - 1;
    }
    cached[i] = {
      start: iRangeEl.node,
      startOffset: iOffset,
    };
    i++;
  }
  docRanges.push({
    correction: correction.correction,
    endNode: cached[i - 1].start,
    endOffset: correction.endOffset,
    endOffsetForTextContent: correction.endOffsetForTextContent,
    offsetInEndNode: cached[i - 1].startOffset,
    offsetInStartNode: cached[lastBreak].startOffset,
    startNode: cached[lastBreak].start,
    startOffset: correction.startOffset,
    startOffsetForTextContent: correction.startOffsetForTextContent,
  });
  return docRanges;
};
