import { List, Map, Record } from 'immutable';
import {
  ICorrectionResponse,
  ITokenizedResponse,
  IUnchangedResponse,
} from '../../types';

interface IJobParams {
  changedSentences: List<number>;
  contextAfter: string;
  contextBefore: string;
  editorId: string;
  id: number;
  key: string;
  longText: boolean;
  mongoId: string | null;
  sentenceResponses: Map<number, ICorrectionResponse | IUnchangedResponse>;
  storedResponses: List<ICorrectionResponse | IUnchangedResponse>;
  text: string;
  timestamp: number;
  tokenizedResponse: ITokenizedResponse | null;
  tokenizedSentenceIds: List<number>;
  tokenizedTimestamp: number | null;
}

export class Job extends Record<IJobParams>(
  {
    changedSentences: List(), // ids of sentences sent for corrections, in order
    contextAfter: '',
    contextBefore: '',
    editorId: 'DEFAULT_JOB_EDITOR_ID',
    id: 0,
    key: 'DEFAULT_JOB_KEY',
    longText: false,
    mongoId: null,
    sentenceResponses: Map(), // map sentence index -> response TODO ?
    storedResponses: List(),
    text: 'DEFAULT_JOB_TEXT',
    timestamp: 0, // time job created
    tokenizedResponse: null, // null | tokenized
    tokenizedSentenceIds: List(), // new sentence ids after tokenized response
    tokenizedTimestamp: null, // null | datetime
  },
  'Job',
) {
  public static parseKey(
    key: string,
  ): { editorId: string; jobId: number; timestamp: number } {
    const [editorId, jobId, timestamp] = key.split(':');
    return { editorId, jobId: Number(jobId), timestamp: Number(timestamp) };
  }

  private static nextId = 1;
  constructor(obj: Partial<IJobParams> = {}) {
    const { editorId = 'DEFAULT_JOB_EDITOR_ID', timestamp = 0 } = obj;
    const id = obj.id || Job.nextId++;
    const jobKey = `${editorId}:${id}:${timestamp}`;
    super({ ...obj, id, key: jobKey });
  }

  public getMessageJSON(): string {
    const { contextAfter, contextBefore, key, text, timestamp } = this;
    return JSON.stringify({
      contextAfter,
      contextBefore,
      key,
      text,
      timestamp,
    });
  }
  public getMessage() {
    const { key, text, timestamp } = this;
    return { key, text, timestamp };
  }
}
