import { List, Map, Record } from 'immutable';
import Delta from 'quill-delta';
import { Correction, Job, Sentence } from '..';
import {
  ICorrection,
  ICorrectionResponse,
  IFeedbackParams,
  ITokenizedResponse,
  ITransformation,
  IUnchangedResponse,
  JobResponse,
} from '../../types';
import {
  correctionKey,
  parseCorrectionKey,
  parseTransformationKey,
  sentenceKey,
} from '../keys';
import { logger } from '../log';

const textStateConsole = logger(false);
const errorConsole = logger(true);

const scoreForSentences = (sentences: List<Sentence>): number => {
  const numSentences = sentences.count();
  return numSentences > 0
    ? sentences.reduce((acc, s) => acc + s.score, 0) / numSentences
    : 100;
};

interface ITextStateParams {
  sentences: List<Sentence>;
  text: string;
  tokenIdMap: Map<string, number>;
  tokenSentenceMap: Map<number, number>;
  sentenceIdMap: Map<string, number>;
  sentenceIndexMap: Map<number, number>;
  jobMap: Map<string, Job>;
  score: number;
  correctionMap: Map<string, number>;
  diffWithTextContent: Delta;
}

export class TextState extends Record<ITextStateParams>(
  {
    correctionMap: Map(), // correctionId -> sentence id
    diffWithTextContent: new Delta(),
    jobMap: Map(), // jobKey -> Job
    score: 0,
    sentenceIdMap: Map(), // sentence key -> sentence id
    sentenceIndexMap: Map(), // sentence id -> sentence index
    sentences: List(),
    text: 'DEFAULT_TEXT_STATE_TEXT',
    tokenIdMap: Map(), // token key -> token id
    tokenSentenceMap: Map(), // token id -> sentence id
  },
  'TextState',
) {
  constructor(args: Partial<ITextStateParams> = {}) {
    let sentenceList = List();
    if (args.sentences) {
      sentenceList = args.sentences.reduce(
        ({ acc, offset, offsetWithoutNewline }, s) => ({
          acc:
            s.text.length > 0
              ? acc.push(
                  s.merge({
                    offsetInTextState: offset,
                    offsetInTextStateWithoutNewline: offsetWithoutNewline,
                  }),
                )
              : acc,
          offset: offset + s.text.length,
          offsetWithoutNewline:
            offsetWithoutNewline + s.text.replace(/\r?\n|\r/g, '').length,
        }),
        { acc: List(), offset: 0, offsetWithoutNewline: 0 },
      ).acc;
    }
    super({
      ...args, // fix token offsets:
      correctionMap: sentenceList.reduce((acc, s) => {
        for (const key of s.corrections.keys()) {
          acc = acc.set(key, s.id);
        }
        return acc;
      }, Map()),
      score: sentenceList.isEmpty()
        ? 100
        : sentenceList.reduce((acc, s) => acc + s.score, 0) /
          sentenceList.count(),
      sentenceIndexMap: !sentenceList.isEmpty()
        ? Map(sentenceList.map((s, i) => [s.id, i]))
        : Map(),
      sentences: sentenceList,
      text: sentenceList.reduce((acc, s) => acc + s.text, ''),
    });
  } // constructor

  public *getAllCorrections() {
    for (const [sentenceIndex, sentence] of this.sentences.entries()) {
      for (const correction of sentence.corrections.values()) {
        const { id: sentenceId } = sentence;
        yield { correction, sentenceId, sentenceIndex };
      }
    }
  } // getAllCorrections

  public getCorrectionsWithOffsets(): ICorrection[] {
    return Array.from(
      this.sentences.flatMap((sentence: Sentence) => {
        return sentence.getCorrectionsWithOffsets(this.diffWithTextContent);
      }),
    );
  } // getCorrectionsWithOffsets

  public getCorrection(cKey: string): Correction | null {
    const sentenceId = this.correctionMap.get(cKey, 0);
    if (0 === sentenceId) {
      return null;
    }
    const sentenceIndex = this.sentenceIndexMap.get(sentenceId, -1);
    if (-1 === sentenceIndex) {
      return null;
    }
    const sentence = this.sentences.get(sentenceIndex);
    if (!sentence) {
      return null;
    }
    return sentence.corrections.get(cKey, null);
  } // getCorrection

  public attachDiff(diff: Delta): TextState {
    const newts = new TextState({
      ...this.toObject(),
      diffWithTextContent: diff,
    });
    return newts;
  } // attachDiff

  /**
   * Applies a Delta to the TextState, returning a new TextState
   * @this {TextState}
   * @param {Delta} delta
   * @param {number} timestamp
   * @return
   */
  public applyDelta(delta: Delta | null, timestamp: number): TextState {
    textStateConsole.log('apply delta', delta ? delta.ops : delta);
    // TODO fix for multiple sentences ?
    const {
      sentences,
      tokenIdMap,
      tokenSentenceMap,
      sentenceIndexMap,
      sentenceIdMap,
    } = this;
    let curDelta = delta;
    let outputSentences = List();
    let outputTokenIdMap = tokenIdMap;
    let outputTokenSentenceMap = tokenSentenceMap;
    let outputSentenceIndexMap = sentenceIndexMap;
    let outputSentenceIdMap = sentenceIdMap;
    let outputCorrectionMap = this.correctionMap;

    if (sentences.isEmpty()) {
      // if no sentences in input state,
      // set output sentences structure directly
      // instead of going thru the loop in the else branch
      const { sentence, tokenSentenceMapDiff } = new Sentence({
        timestamp,
      }).applyDelta(delta, timestamp, 0, 0);
      // TODO
      if (sentence && sentence.text.length > 0) {
        outputSentences = List.of(sentence);
        outputTokenSentenceMap = tokenSentenceMapDiff.filter(v => v !== 0);
        outputSentenceIndexMap = Map.of(sentence.id, 0);
      } else {
        outputTokenIdMap = Map();
        outputTokenSentenceMap = Map();
        outputSentenceIndexMap = Map();
      }
    } else {
      // sentences not empty
      const inSentences = sentences;
      let index = 0;
      let sentOffset = 0;
      let sentOffsetWithoutNewline = 0;
      let prevChanged = false;
      let curChanged = false;
      const inputSentenceCount = inSentences.count();
      for (let i = 0; i < inputSentenceCount; ++i) {
        const curSentence = inSentences.get(i) as Sentence;
        // pass if the sentence is last sentence to apply any remaining delta
        const {
          delta: newDelta,
          sentence: newSentence,
          tokenSentenceMapDiff,
          removedCorrections,
        } = curSentence.applyDelta(
          curDelta,
          timestamp,
          sentOffset,
          sentOffsetWithoutNewline,
          i === inputSentenceCount - 1,
        );

        outputCorrectionMap = outputCorrectionMap.deleteAll(removedCorrections);

        if (!newSentence || newSentence.text !== curSentence.text) {
          // if changed, mark prev and next sentences as changed
          if (!outputSentences.isEmpty()) {
            outputSentences = outputSentences.setIn([-1, 'changed'], true);
          }
          curChanged = true;
        } else {
          curChanged = false;
        }
        if (newSentence) {
          if (newSentence.text.length === 0) {
            // empty sentence, update outputCorrectionMap, sentenceIdMap and sentenceIndexMap
            textStateConsole.log(
              'Deleted. textState applyDelta empty newSentence',
            );
            outputSentenceIndexMap = outputSentenceIndexMap.delete(
              newSentence.id,
            );
            outputSentenceIdMap = outputSentenceIdMap.filterNot(
              sentenceId => sentenceId === newSentence.id,
            );
          } else {
            textStateConsole.log('newSentence is NOT changed.');
            // current sentence is not changed
            // then push current unchanged sentence
            sentOffset += newSentence.text.length;
            sentOffsetWithoutNewline += newSentence.text.replace(
              /\r?\n|\r/g,
              '',
            ).length;
            textStateConsole.log({
              sentOffset,
              sentOffsetWithoutNewline,
            });
            outputSentences = outputSentences.push(newSentence);
            // only update sentenceIndexMap if index has changed
            if (index !== sentenceIndexMap.get(newSentence.id, -1)) {
              outputSentenceIndexMap = outputSentenceIndexMap.set(
                newSentence.id,
                index,
              );
            }
            index += 1;
            if (prevChanged) {
              outputSentences = outputSentences.setIn([-1, 'changed'], true);
            }
          }
        } else {
          // sentence deleted, remove from sentenceIdMap and sentenceIndexMap
          textStateConsole.log('Deleted. textState applyDelta no newSentence');
          outputSentenceIndexMap = outputSentenceIndexMap.delete(
            curSentence.id,
          );
          outputSentenceIdMap = outputSentenceIdMap.filterNot(
            sentenceId => sentenceId === curSentence.id,
          );
        }
        curDelta = newDelta;
        prevChanged = curChanged;

        if (!curDelta || !curDelta.ops || curDelta.ops.length === 0) {
          textStateConsole.log('all Delta checked');
          for (const [tokenId, sentenceId] of tokenSentenceMapDiff.entries()) {
            if (sentenceId) {
              outputTokenSentenceMap = outputTokenSentenceMap.set(
                tokenId,
                sentenceId,
              );
            } else {
              outputTokenSentenceMap = outputTokenSentenceMap.delete(tokenId);
              outputTokenIdMap = outputTokenIdMap.filterNot(
                tId => tId === tokenId,
              );
            }
          }
        } // if delta empty
      } // for
    } // else sentence not empty

    const outputScore = scoreForSentences(outputSentences);

    const outputTextState = new TextState({
      ...this.toObject(),
      score: outputScore,
      sentenceIdMap: outputSentenceIdMap,
      sentenceIndexMap: outputSentenceIndexMap,
      sentences: outputSentences,
      text: outputSentences.reduce((acc, { text }) => acc + text, ''),
      tokenIdMap: outputTokenIdMap,
      tokenSentenceMap: outputTokenSentenceMap,
    });
    return outputTextState;
  } // applyDelta()

  public applyResponses(
    responses: List<JobResponse> | JobResponse[],
    timestamp: number,
  ): TextState {
    if (responses instanceof Array) {
      return responses.reduce(
        (acc, response) => acc.applyResponse(response, timestamp),
        this as TextState,
      );
    } else {
      return responses.reduce(
        (acc, response) => acc.applyResponse(response, timestamp),
        this as TextState,
      );
    }
  } // applyResponses

  /**
   * Applies a response from the server to the text state.
   * Delegates to separate methods for each type of response.
   * @this {TextState}
   * @param {object} response the response object
   * @param {number} timestamp timestamp of the response
   * @return {TextState}
   */
  public applyResponse(response: JobResponse, timestamp: number): TextState {
    textStateConsole.log('current response', response);
    const { key } = response;
    const job = this.jobMap.get(key!);
    if (job) {
      if ('tokenized' in response) {
        textStateConsole.log('applying tokenized response');
        const newState = this.applyTokenizedResponse(response, timestamp);
        const storedResponses = job.storedResponses;
        if (storedResponses.isEmpty()) {
          return newState;
        } else {
          textStateConsole.log('applying stored responses');
          // if there are stored corrections/unchanged responses for this job, apply them now
          return newState
            .updateIn(['jobMap', key, 'storedResponses'], () => List())
            .applyResponses(storedResponses, timestamp);
        }
      } else if ('corrections' in response) {
        textStateConsole.log(
          'job tokenized response is:',
          job.tokenizedResponse,
        );
        if (job.tokenizedResponse) {
          textStateConsole.log('applying corrections response');
          // if tokenized response has been received, apply the corrections response
          return this.applyCorrectionsResponse(response, timestamp);
        } else {
          textStateConsole.log('storing corrections response');
          // if tokenized response has not yet been received, store the corrections response
          return this.updateIn(['jobMap', key, 'storedResponses'], m =>
            m.push(response),
          );
        }
      } else {
        if (job.tokenizedResponse) {
          textStateConsole.log('applying unchanged response');
          // if tokenized response has been received, apply the unchanged response
          return this.applyUnchangedResponse(
            response as IUnchangedResponse,
            timestamp,
          );
        } else {
          textStateConsole.log('storing unchanged response');
          // if tokenized response has not yet been received, store the unchanged response
          return this.updateIn(['jobMap', key, 'storedResponses'], m =>
            m.push(response),
          );
        }
      }
    } else {
      // job stale/not found
      textStateConsole.log(`No job found for key ${key}`);
      return this;
    }
  } // applyResponse()

  /**
   * Applies a tokenized response to the text state.
   * Returns a new TextState object resulting from applying the response,
   * with the new data added to the token and sentence maps,
   * and with the relevant Sentences tokenized.
   * @this {TextState}
   * @param {object} response a tokenized response object received from the server
   * @param {number} timestamp
   * @return {TextState}
   */
  public applyTokenizedResponse(
    response: ITokenizedResponse,
    timestamp: number,
  ): TextState {
    textStateConsole.log('apply tokenized response', response);
    const { tokenized, key } = response;
    const job = this.jobMap.get(key);
    if (!job) {
      // TODO log
      return this;
    }
    const { changedSentences, timestamp: jobTimestamp } = job;
    const numJobSentences = changedSentences.count();
    // Sentence Map has been altered such that
    // some sentences are now not
    const staleSentences = changedSentences.some(
      s => this.sentenceIndexMap.get(s, -1) === -1,
    );
    if (staleSentences) {
      return this;
    }
    const jobSentenceIndices = changedSentences.map(
      s => this.sentenceIndexMap.get(s) as number,
    );
    const jobSentences = jobSentenceIndices.map(
      i => this.sentences.get(i) as Sentence,
    );
    const staleTimestamp = jobSentences.some(
      s => Number(s.timestamp) > Number(jobTimestamp),
    );
    if (staleTimestamp) {
      return this;
    }

    const startSentenceIndex = jobSentenceIndices.first() as number;
    let newSentences = List();
    let updatedSentenceIndexMap = this.sentenceIndexMap;
    let updatedSentenceIdMap = this.sentenceIdMap;
    let newSentenceIds = List();
    const theSentence = jobSentences.reduce(
      (acc: Sentence | null, jobSentence) =>
        jobSentence.mergeWithExistingSentence(acc),
      null,
    ) as Sentence;
    const theSentenceText = theSentence.text;
    const theSentenceLength = theSentenceText.length;
    let sentenceIndex = startSentenceIndex;
    // for each new sentence range from tokenized response
    for (let i = 0; i < tokenized.length; ++i) {
      const startOffset = tokenized[i];
      const startOffsetWithoutNewline = theSentenceText
        .slice(0, tokenized[i])
        .replace(/\r?\n|\r/g, '').length;
      const endOffset =
        i + 1 < tokenized.length ? tokenized[i + 1] : theSentenceLength; // why we used this.text.length instead of sentence length?
      const curTokenizedSentenceLength = endOffset - startOffset;
      const postLength =
        theSentenceLength - (startOffset + curTokenizedSentenceLength); // <-- This was wrong

      textStateConsole.log('building delta in tokenized response', {
        curTokenizedSentenceLength,
        endOffset,
        i,
        postLength,
        startOffset,
      });
      const delta = new Delta()
        .delete(startOffset) // skip til start
        .retain(curTokenizedSentenceLength) // retain tokenized section
        .delete(postLength); // skip til end
      // apply delta to select new sentence tokens

      // TODO do something less hacky here?
      const { sentence: outputSentence } = theSentence.applyDelta(
        delta,
        timestamp,
        startOffset + theSentence.offsetInTextState,
        startOffsetWithoutNewline + theSentence.offsetInTextStateWithoutNewline,
      ); // << TODO this seems wrong
      if (outputSentence) {
        // outputSentence can be null
        const {
          tokens,
          text,
          corrections,
          tokenIndexMap,
          offsetInTextState,
          offsetInTextStateWithoutNewline,
          score,
        } = outputSentence;
        const newSentence = new Sentence({
          corrections,
          offsetInTextState,
          offsetInTextStateWithoutNewline,
          score,
          text,
          timestamp,
          tokenIndexMap,
          tokens,
        });
        newSentences = newSentences.push(newSentence);
        updatedSentenceIndexMap = updatedSentenceIndexMap.set(
          newSentence.id,
          sentenceIndex,
        );
        updatedSentenceIdMap = updatedSentenceIdMap.set(
          sentenceKey({
            jobKey: key,
            sentenceIndex,
          }),
          newSentence.id,
        );
        newSentenceIds = newSentenceIds.push(newSentence.id);
        sentenceIndex += 1;
      }
    } // for

    const lastNewSentenceIndex = startSentenceIndex + newSentences.count() - 1;

    const splicedSentences = this.sentences.splice(
      startSentenceIndex,
      numJobSentences,
      ...newSentences,
    );
    for (
      let i = startSentenceIndex + newSentences.count();
      i < splicedSentences.count();
      ++i
    ) {
      updatedSentenceIndexMap = updatedSentenceIndexMap.set(
        (splicedSentences.get(i) as Sentence).id,
        i,
      );
    }
    let newTextState = this.merge({
      jobMap: this.jobMap.mergeIn([key], {
        tokenizedResponse: response,
        tokenizedTimestamp: timestamp,
      }),
      sentenceIdMap: updatedSentenceIdMap,
      sentenceIndexMap: updatedSentenceIndexMap,
      sentences: splicedSentences,
    });
    newTextState = newTextState.updateMap();

    newTextState = newTextState.setIn(
      ['jobMap', job.key, 'tokenizedSentenceIds'],
      newSentenceIds,
    );

    if (job.longText) {
      // if job was originally too long, set things up to ignore response for last sentence and send it again
      textStateConsole.log('long text');
      newTextState = newTextState
        .updateIn(['sentences', lastNewSentenceIndex], s =>
          s.merge({
            changed: true,
            timestamp: Date.now(),
          }),
        )
        .setIn(
          // put a value in sentenceResponses for the last sentence so no corrections/unchanged response will be applied to it
          ['jobMap', job.key, 'sentenceResponses', tokenized.length - 1],
          'ignore response',
        );
    }
    // newTextState = this.updateSentenceIdMap(newTextState);
    return new TextState({ ...newTextState.toObject() });
  } // applyTokenizedResponse()

  /**
   * Applies a corrections response object to the TextState.
   * Returns a new TextState object resulting from applying it,
   * with corrections and scores updated on the relevant Sentences,
   * and with the new token keys from the response added to tokenIdMap
   *
   * @this {TextState}
   * @param {Object} response the corrections response object to apply
   * @return {TextState}
   */
  public applyCorrectionsResponse(
    response: ICorrectionResponse,
    timestamp: number,
  ): TextState {
    textStateConsole.log('applyCorrectionsResponse', response);
    const { key } = response; // <- this sentenceIndex here before was wrong.
    // ^ if dirty sentence is index 1, and only one sentence is touched, the sentenceIndex in returned response will be 0
    const { sentenceIndex: jobSentenceIndex } = response;
    const job = this.jobMap.get(key);
    if (!job) {
      // TODO log
      return this;
    }
    const sentenceId = job.tokenizedSentenceIds.get(jobSentenceIndex, 0);
    textStateConsole.log('correction', {
      jobId: job.id,
      jobSentenceIndex,
      responseText: response.currentTokens.reduce(
        (acc, token) => acc + token.value + token.after,
        '',
      ),
    });
    if (job.sentenceResponses.has(jobSentenceIndex)) {
      textStateConsole.log(
        '======job.sentenceResponses has jobSentenceIndex======',
      );
      // TODO: Do we need this check?
      return this;
    }

    let outputTokenIdMap = this.tokenIdMap;
    let outputTokenSentenceMap = this.tokenSentenceMap;
    let outputCorrectionMap = this.correctionMap;
    const sentenceIndex = this.sentenceIndexMap.get(sentenceId, -1);
    if (-1 === sentenceIndex) {
      // sentence not in sentence map
      errorConsole.log(
        '!!!!!!!!!!!!ERROR: sentenceIndex is -1, sentence not in sentence map',
      );
      return this;
    }
    const oldSentence = this.sentences.get(sentenceIndex);
    if (!oldSentence) {
      // sentence not in sentence list
      errorConsole.log(
        '!!!!!!!!!!!!ERROR: no oldSentence, sentence not in sentence list',
      );
      return this;
    }

    if (Number(oldSentence.timestamp) > Number(job.tokenizedTimestamp)) {
      textStateConsole.log(
        'sentence modified since tokenized received; ignoring corrections',
      );
      return this.merge({
        jobMap: this.jobMap.updateIn([key, 'sentenceResponses'], sr =>
          sr.set(jobSentenceIndex, response),
        ),
      });
    }

    // add corrections to sentence
    const {
      updatedSentence,
      tokenIdMapDiff,
      tokenSentenceMapDiff,
      correctionMapDiff,
    } = oldSentence.applyCorrections(
      response,
      timestamp,
      job,
      this.tokenIdMap,
      jobSentenceIndex,
    );

    for (const [k, v] of tokenIdMapDiff.entries()) {
      if (v) {
        outputTokenIdMap = outputTokenIdMap.set(k, v);
        outputTokenSentenceMap = outputTokenSentenceMap.set(v, sentenceId);
      } else {
        const id = outputTokenIdMap.get(k, 0);
        outputTokenIdMap = outputTokenIdMap.delete(k);
        outputTokenSentenceMap = outputTokenSentenceMap.delete(id);
      }
    }

    for (const [k, v] of tokenSentenceMapDiff.entries()) {
      outputTokenSentenceMap = v
        ? outputTokenSentenceMap.set(k, v)
        : outputTokenSentenceMap.delete(k);
    }

    for (const [k, v] of correctionMapDiff.entries()) {
      outputCorrectionMap = outputCorrectionMap.set(k, v);
    }

    // for each correction, update sentence.tokens
    return this.merge({
      correctionMap: outputCorrectionMap,
      jobMap: this.jobMap.updateIn([key, 'sentenceResponses'], sr =>
        sr.set(jobSentenceIndex, response),
      ),
      score:
        this.score +
        (updatedSentence.score - oldSentence.score) / this.sentences.count(),
      sentences: this.sentences.set(sentenceIndex, updatedSentence),
      tokenIdMap: outputTokenIdMap,
      tokenSentenceMap: outputTokenSentenceMap,
    });
  } // applyCorrectionsResponse()

  /**
   * Applies an unchangedresponse object to the TextState.
   * Returns a new TextState object with the score updated.
   *
   * @this {TextState}
   * @param {Object} response
   * @returns {TextState}
   */
  public applyUnchangedResponse(
    response: IUnchangedResponse,
    timestamp: number,
  ): TextState {
    const { key, sentenceIndex: jobSentenceIndex } = response;
    const job = this.jobMap.get(key);
    if (!job) {
      // TODO log
      return this;
    }
    const sentenceId = job.tokenizedSentenceIds.get(jobSentenceIndex, 0);
    textStateConsole.log('unchanged', {
      jobId: job.id,
      responseSentenceIndex: jobSentenceIndex,
      sentenceId,
    });

    // TODO: should this be index from job or actual index
    if (job.sentenceResponses.has(jobSentenceIndex)) {
      return this;
    }

    const sentenceIndex = this.sentenceIndexMap.get(
      sentenceId,
      this.sentences.count(),
    );
    const oldSentence = this.sentences.get(sentenceIndex);

    if (!oldSentence) {
      errorConsole.log(
        '====oldSentence does not exist in unchanged response===',
      );
      return this;
    }

    const newSentence =
      Number(oldSentence.timestamp) <= Number(timestamp)
        ? oldSentence.applyUnchangeResponse(timestamp)
        : oldSentence;

    const newSentences = this.sentences.set(sentenceIndex, newSentence);

    return this.merge({
      jobMap: this.jobMap.updateIn([key, 'sentenceResponses'], sr =>
        sr.set(jobSentenceIndex, response),
      ),
      score: scoreForSentences(newSentences),
      sentences: newSentences,
    });
  } // applyUnchangedResponse()

  /**
   * Accepts a transformation.
   * Updates the Sentence, replacing the tokens and updating score.
   * Updates score and removes no longer needed key mappings.
   * Returns a new TextState object with the result.
   *
   * @this {TextState}
   * @param {string} tKey transformation key for transformation to accept
   * @param {number} timestamp
   * @returns {TextState}
   */
  public accept(
    tKey: string,
    timestamp: number,
    propSentenceIndex: number = -1,
  ): { feedbackParams: IFeedbackParams | null; textState: TextState } {
    textStateConsole.log({ tKey });
    const args = parseTransformationKey(tKey);
    const {
      jobKey,
      sentenceIndex: jobSentenceIndex,
      correctionIndex,
      transformationIndex,
    } = args;
    const cKey = correctionKey({
      correctionIndex,
      jobKey,
      sentenceIndex: jobSentenceIndex,
    });
    const job = this.jobMap.get(jobKey);
    if (!job) {
      // TODO
    }

    let sentenceId = this.correctionMap.get(cKey);
    if (!sentenceId) {
      sentenceId = this.sentenceIdMap.get(sentenceKey(args), 0);
    }
    let sentenceIndex = this.sentenceIndexMap.get(sentenceId, -1);
    if (sentenceIndex === -1) {
      if (propSentenceIndex !== -1) {
        sentenceIndex = propSentenceIndex;
      } else {
        sentenceIndex = this.sentences.findIndex(s => s.corrections.has(cKey));
      }
    }
    // TODO check if still no sentenceIndex found
    const oldSentence = this.sentences.get(sentenceIndex);
    if (!oldSentence) {
      // TODO log
      return { feedbackParams: null, textState: this };
    }
    const {
      updatedSentence,
      tokenIdMapDiff,
      tokenSentenceMapDiff,
    } = oldSentence.accept(tKey, this.tokenIdMap, timestamp);
    const offsetDiff =
      updatedSentence.offsetInTextState +
      updatedSentence.text.length -
      (oldSentence.offsetInTextState + oldSentence.text.length);
    const offsetDiffWithoutNewline =
      updatedSentence.offsetInTextStateWithoutNewline +
      updatedSentence.text.replace(/\r?\n|\r/g, '').length -
      (oldSentence.offsetInTextStateWithoutNewline +
        oldSentence.text.replace(/\r?\n|\r/g, '').length);
    const updatedSentences = this.sentences
      .set(sentenceIndex, updatedSentence)
      .map((s, i) => {
        if (i > sentenceIndex) {
          return s
            .update('offsetInTextState', offset => offset + offsetDiff)
            .update(
              'offsetInTextStateWithoutNewline',
              offsetWithoutNewline =>
                offsetWithoutNewline + offsetDiffWithoutNewline,
            );
        } else {
          return s;
        }
      });

    const updatedTokenIdMap = tokenIdMapDiff.reduce(
      (tokenIdMap, id, key) =>
        id ? tokenIdMap.set(key, id) : tokenIdMap.delete(key),
      this.tokenIdMap,
    );
    const updatedTokenSentenceMap = tokenSentenceMapDiff.reduce(
      (tokenSentenceMap, updatedSentenceId, tokenId) =>
        updatedSentenceId
          ? tokenSentenceMap.set(tokenId, updatedSentenceId)
          : tokenSentenceMap.delete(tokenId),
      this.tokenSentenceMap,
    );
    const newText = updatedSentences.reduce((acc, s) => acc + s.text, '');
    const newTextState = this.merge({
      correctionMap: this.correctionMap.delete(cKey),
      score:
        this.score +
        (updatedSentence.score - oldSentence.score) / this.sentences.count(),
      sentences: updatedSentences,
      text: newText,
      tokenIdMap: updatedTokenIdMap,
      tokenSentenceMap: updatedTokenSentenceMap,
    });
    const mongoId: string | null = this.jobMap.getIn([jobKey, 'mongoId'], null);
    if (!mongoId) {
      return { feedbackParams: null, textState: newTextState };
    }
    const sentence = this.sentences.get(sentenceIndex)!;
    const tokens = sentence.tokens;
    const preTokens = tokens.takeUntil(token => token.correction === cKey);
    const preOffset = preTokens.reduce(
      (acc, token) => acc + token.text.length,
      0,
    );
    const correction = sentence.corrections.get(cKey);
    if (!correction) {
      return { feedbackParams: null, textState: newTextState };
    }
    const transformation: ITransformation =
      correction.transformations[transformationIndex];
    const backendTransformIndices = [transformation.trIdx];
    const feedbackParams: IFeedbackParams = {
      backendTransformIndices,
      isAccepted: true,
      jobId: mongoId,
      offset: preOffset,
      sentence: sentence.text,
      sentenceIndex: jobSentenceIndex,
      transformIndex: transformationIndex,
    };
    return { feedbackParams, textState: newTextState };
  } // accept()

  /**
   * Ignores a correction.
   * Removes no-longer-needed key mappings related to the ignored correction.
   * Updates the score.
   * Returns a new TextState object with the result.
   *
   * @this {TextState}
   * @param {string} cKey the correction key of the correction to ignore
   * @param {number} timestamp
   */
  public ignore(
    cKey: string,
    timestamp: number,
    propSentenceIndex = -1,
  ): { feedbackParams: IFeedbackParams | null; textState: TextState } {
    const args = parseCorrectionKey(cKey);
    const { jobKey, sentenceIndex: jobSentenceIndex } = args;
    let sentenceId = this.correctionMap.get(cKey);
    if (!sentenceId) {
      sentenceId = this.sentenceIdMap.get(sentenceKey(args), 0);
    }
    let sentenceIndex = this.sentenceIndexMap.get(sentenceId, -1);
    if (sentenceIndex === -1) {
      if (propSentenceIndex !== -1) {
        sentenceIndex = propSentenceIndex;
      } else {
        sentenceIndex = this.sentences.findIndex(s => s.corrections.has(cKey));
      }
    }
    const oldSentence = this.sentences.get(sentenceIndex);
    if (!oldSentence) {
      return { feedbackParams: null, textState: this };
    }
    const updatedSentence = oldSentence.ignore(cKey, timestamp);

    const newTextState = this.merge({
      correctionMap: this.correctionMap.delete(cKey),
      score:
        this.score +
        (updatedSentence.score - oldSentence.score) / this.sentences.count(),
      sentences: this.sentences.set(sentenceIndex, updatedSentence),
    });
    const mongoId: string | null = this.jobMap.getIn([jobKey, 'mongoId'], null);
    if (!mongoId) {
      return { feedbackParams: null, textState: newTextState };
    }
    const sentence = this.sentences.get(sentenceIndex)!;
    const tokens = sentence.tokens;
    const preTokens = tokens.takeUntil(token => token.correction === cKey);
    const preOffset = preTokens.reduce(
      (acc, token) => acc + token.text.length,
      0,
    );
    const correction = sentence.corrections.get(cKey);
    if (!correction) {
      return { feedbackParams: null, textState: newTextState };
    }
    const backendTransformIndices = correction.transformations.map(
      t => t.trIdx,
    );
    const feedbackParams: IFeedbackParams = {
      backendTransformIndices,
      isAccepted: false,
      jobId: mongoId,
      offset: preOffset,
      sentence: sentence.text,
      sentenceIndex: jobSentenceIndex,
      transformIndex: -1,
    };
    return { feedbackParams, textState: newTextState };
  } // ignore()

  /**
   * update necessary map with updated sentences
   * @param {TextState} newTextState
   * @return {TextState}
   */
  private updateMap() {
    // TODO: in commit e4bd1acd I convert id toString to make it work, but seems it's not the case anymore. why?
    const newTextState = this;
    const newSentenceIndexMap = newTextState.sentences.reduce(
      (newMap, sentence, index) => newMap.set(sentence.id, index),
      Map<number, number>(),
    );
    const newSentenceKeys = newTextState.sentenceIdMap.reduce(
      (acc, sentenceId, newSentenceKey) =>
        newSentenceIndexMap.has(sentenceId) ? acc.push(newSentenceKey) : acc,
      List(),
    );
    const newCorrectionMap = newTextState.correctionMap.filter(
      (correction, newCorrectionKey) =>
        newSentenceKeys.includes(
          sentenceKey(parseCorrectionKey(newCorrectionKey)),
        ),
    );
    const newTokenSentenceMap = newTextState.tokenSentenceMap.filter(
      sentenceId => newSentenceIndexMap.has(sentenceId),
    );
    const newTokenIdMap = newTextState.tokenIdMap.filter(tokenId =>
      newTokenSentenceMap.has(tokenId),
    );
    return newTextState.merge({
      correctionMap: newCorrectionMap,
      sentenceIndexMap: newSentenceIndexMap,
      tokenIdMap: newTokenIdMap,
      tokenSentenceMap: newTokenSentenceMap,
    });
  }
} // class TextState
