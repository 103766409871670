import {
  animationFrameScheduler,
  combineLatest,
  interval,
  Observable,
  of,
} from 'rxjs';
import {
  bufferCount,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  scan,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  IBodyScroll,
  ICorrection,
  ICorrectionPosition,
  ICStyle,
  ICstyleCss,
  IHighlightedCorrection,
  IHighlightedCorrectionInfo,
  IMouseMove,
  IMouseMoveWithEl,
  IScrollParent,
  IScrollTargetOffsetToTarget,
  ITargetScroll,
  ITextStateMap,
  UnderlineThickness,
} from '../types';
import { cursorInCoordinates, getRanges, isIframe } from '../utils';

export const correctionsFrom = (textState$: Observable<ITextStateMap>) =>
  textState$.pipe(
    pluck('textState'),
    bufferCount(2, 1),
    filter(
      ([prev, curr]) =>
        prev.text !== curr.text || prev.correctionMap !== curr.correctionMap,
    ),
    map(([_, textState]) => textState.getCorrectionsWithOffsets()),
  );

const forceUpdater = (obs: Observable<any>): Observable<number> => {
  return interval(0, animationFrameScheduler).pipe(
    withLatestFrom(
      obs.pipe(
        scan((count, _) => {
          // console.log(_);
          return count + 1;
        }, 0),
      ),
    ),
    map(([_, count]) => count),
    distinctUntilChanged(),
  );
};

interface ICorrectionPositionsMap {
  [key: string]: ICorrectionPosition[];
}

export const computeRangesWith = (
  el: HTMLElement,
  corrections$: Observable<ICorrection[]>,
  update$: Observable<any>,
  elIsIframe: boolean,
  contentWindow: Window,
  targetRectPosition$: Observable<ClientRect | DOMRect>,
  targetScroll$: Observable<ITargetScroll>,
  scrollParentScroll$: Observable<ITargetScroll>,
  scrollParentOffsetToTarget$: Observable<IScrollTargetOffsetToTarget>,
): Observable<ICorrectionPosition[]> =>
  combineLatest(
    targetRectPosition$,
    corrections$,
    forceUpdater(update$),
    targetScroll$,
  ).pipe(
    withLatestFrom(scrollParentScroll$, scrollParentOffsetToTarget$),
    scan(
      (
        { prevCorrections, prevForceUpdate, prevDims },
        [
          [
            { left, top, height, width },
            sortedCorrections,
            forceUpdate,
            { targetScrollX, targetScrollY },
          ],

          {
            targetScrollX: scrollParentScrollX,
            targetScrollY: scrollParentScrollY,
          },
          { leftOffset, topOffset },
        ],
      ) => {
        const rangeFactory = getRanges(el);
        const newDims = {
          height,
          left: left + targetScrollX,
          top: top + targetScrollY,
          width,
        };
        const forceUpdatedChanged = forceUpdate !== prevForceUpdate;
        const dimsChanged =
          newDims.left !== prevDims.left ||
          newDims.top !== prevDims.top ||
          newDims.height !== prevDims.height ||
          newDims.width !== prevDims.width;
        // console.log(`Dims Updated: ${dimsChanged}`, prevDims, newDims);
        const calculateNewPositionsForCorrection = (
          correction: ICorrection,
        ): ICorrectionPosition[] => {
          // work
          // console.log(`Working for ${k}`);
          const corrOffsets = rangeFactory(correction);
          return corrOffsets.map(correctionRange => {
            const {
              endNode,
              offsetInEndNode,
              offsetInStartNode,
              startNode,
            } = correctionRange;
            const domRange = contentWindow.document.createRange();
            domRange.setStart(startNode, offsetInStartNode);
            domRange.setEnd(endNode, offsetInEndNode);
            const rangeRect = domRange.getBoundingClientRect();
            let baseLeft = left;
            let baseTop = top;
            if (elIsIframe) {
              baseLeft = 0;
              baseTop = 0;
            }
            const pos: ClientRect = {
              bottom:
                rangeRect.bottom -
                baseTop +
                targetScrollY +
                scrollParentScrollY +
                topOffset,
              height: rangeRect.height,
              left:
                rangeRect.left +
                targetScrollX +
                scrollParentScrollX -
                baseLeft +
                leftOffset,
              right:
                rangeRect.right +
                targetScrollX +
                scrollParentScrollX -
                baseLeft +
                leftOffset,
              top:
                rangeRect.top +
                targetScrollY +
                scrollParentScrollY -
                baseTop +
                topOffset,
              width: rangeRect.width,
            };
            return {
              correction: correctionRange.correction,
              correctionRange,
              correctionRect: rangeRect,
              pos,
              visible: shouldCorrectionVisible(
                rangeRect,
                left,
                top,
                height,
                width,
                elIsIframe,
              ),
            };
          });
        };
        let newPositionsForCorrection: (
          correction: ICorrection,
        ) => ICorrectionPosition[];
        if (forceUpdatedChanged || dimsChanged) {
          newPositionsForCorrection = calculateNewPositionsForCorrection;
        } else {
          newPositionsForCorrection = correction => {
            const key = correction.correction.key;
            if (key in prevCorrections) {
              for (const correctionPosition of prevCorrections[key]) {
                const prevCorrectionRange = correctionPosition.correctionRange;
                if (
                  !(
                    correction.startOffset ===
                      prevCorrectionRange.startOffset &&
                    correction.endOffset === prevCorrectionRange.endOffset
                  )
                ) {
                  // console.log(`No work for ${k}`);
                  return calculateNewPositionsForCorrection(correction);
                }
              }
              return prevCorrections[key];
            }
            return calculateNewPositionsForCorrection(correction);
          };
        }
        const newCorrectionPositions = sortedCorrections.reduce(
          (acc: ICorrectionPositionsMap, correction: ICorrection) => ({
            ...acc,
            [correction.correction.key]: newPositionsForCorrection(correction),
          }),
          {},
        );
        return {
          prevCorrections: newCorrectionPositions,
          prevDims: newDims,
          prevForceUpdate: forceUpdate,
          sortedCorrections,
        };
      },
      {
        prevCorrections: {} as ICorrectionPositionsMap,
        prevDims: {
          height: 0,
          left: 0,
          top: 0,
          width: 0,
        },
        prevForceUpdate: 0,
        sortedCorrections: [] as ICorrection[],
      },
    ),
    map(
      (obj: {
        prevCorrections: ICorrectionPositionsMap;
        prevDims: {
          height: number;
          left: number;
          top: number;
          width: number;
        };
        prevForceUpdate: number;
        sortedCorrections: ICorrection[];
      }) =>
        obj.sortedCorrections.reduce(
          (acc: ICorrectionPosition[], c) =>
            acc.concat(obj.prevCorrections[c.correction.key]),
          [],
        ),
    ),
  ); // computeRangesWith

const shouldCorrectionVisible = (
  correctionRect: DOMRect | ClientRect,
  targetRectLeft: number,
  targetRectTop: number,
  originalTargetHeight: number,
  originalTargetWidth: number,
  elIsIframe: boolean,
): boolean => {
  if (elIsIframe) {
    return (
      correctionRect.bottom < originalTargetHeight &&
      correctionRect.bottom > 0 &&
      correctionRect.right > 0 &&
      correctionRect.left < originalTargetWidth
    );
  }
  return (
    correctionRect.bottom < targetRectTop + originalTargetHeight &&
    correctionRect.bottom > targetRectTop &&
    correctionRect.right > targetRectLeft &&
    correctionRect.left < targetRectLeft + originalTargetWidth
  );
};

export const styleFromRects = (
  rects$: Observable<ICorrectionPosition[]>,
  underlineThickness$: Observable<UnderlineThickness>,
): Observable<ICStyle[]> =>
  combineLatest(rects$, underlineThickness$).pipe(
    map(([rp, underlineThickness]) => {
      return rp.map(
        ({ correction, correctionRange, correctionRect, pos, visible }) => {
          const style: ICstyleCss = {
            height: pos.height,
            left: pos.left,
            top: pos.top,
            width: pos.width,
          };
          return {
            correction,
            correctionRange,
            correctionRect,
            highlighted: false,
            pos,
            style,
            underlineThickness,
            visible,
          };
        },
      );
    }),
  );

export const enhanceCorrectionWithHighlights = (
  corrections$: Observable<ICStyle[]>,
  highlightedCorrectionKey$: Observable<IHighlightedCorrectionInfo>,
): Observable<ICStyle[]> =>
  combineLatest(corrections$, highlightedCorrectionKey$).pipe(
    map(([corrections, { key }]) => {
      if (key === null) {
        return corrections;
      }
      return corrections.map(correction =>
        correction.correction.key === key
          ? { ...correction, highlighted: true }
          : correction,
      );
    }),
  );

// when target has positioned ancestor and is not iframe (isRelative && !isIframe)
// mouseMove is relatve to ancestor, so we need to minus offset to get contentbox coordinate
// then plus targetAbsolutePosition, which excludes margin and border
// and offset with the scroll, then we get mousemove to viewport
export const highlightedCorrectionUnderline = (
  cStyles$: Observable<ICStyle[]>,
  mouseMove$: Observable<IMouseMoveWithEl>,
  elIsIframe: boolean,
  targetRectPosition$: Observable<ClientRect | DOMRect>,
  bodyScroll$: Observable<IBodyScroll>,
  scrollParent$: Observable<IScrollParent>,
  scrollParentRectPosition$: Observable<ClientRect | DOMRect>,
): Observable<IHighlightedCorrection> =>
  scrollParent$.pipe(
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
    switchMap(({ hasScrollParent }) => {
      return combineLatest(cStyles$, mouseMove$).pipe(
        withLatestFrom(
          targetRectPosition$,
          bodyScroll$,
          scrollParentRectPosition$,
        ),
        map(
          ([
            [corrections, { clientX, clientY, inIframe }],
            targetRect,
            { scrollX, scrollY },
            scrollTargetRect,
          ]) => {
            const viewportMouseCoordinates: IMouseMove = {
              clientX: clientX - scrollX,
              clientY: clientY - scrollY,
            };
            if (elIsIframe && !inIframe) {
              return {
                correction: null,
                idx: -1,
                key: null,
              };
            }

            let idx = -1;
            if (
              cursorInsideTarget(
                hasScrollParent,
                viewportMouseCoordinates,
                targetRect,
                scrollTargetRect,
              )
            ) {
              idx = corrections.findIndex(c =>
                cursorInCoordinates(
                  c.correctionRect,
                  viewportMouseCoordinates,
                  elIsIframe,
                  targetRect,
                ),
              );
            }
            return {
              correction: corrections[idx] || null,
              idx,
              key: idx === -1 ? null : corrections[idx].correction.key,
            };
          },
        ),
        startWith({
          correction: null,
          idx: -1,
          key: null,
        }),
      );
    }),
  );

const cursorInsideTarget = (
  hasScrollParent: boolean,
  viewportMouseCoordinates: IMouseMove,
  targetRect: ClientRect | DOMRect,
  scrollTargetRect: ClientRect | DOMRect,
): boolean => {
  const { clientX, clientY } = viewportMouseCoordinates;
  const {
    height: targetHeight,
    left: targetRectLeft,
    top: targetRectTop,
    width: targetWidth,
  } = targetRect;
  const {
    height: scrollTargetHeight,
    left: scrollTargetRectLeft,
    top: scrollTargetRectTop,
    width: scrollTargetWidth,
  } = scrollTargetRect;
  if (hasScrollParent) {
    return (
      clientX > targetRectLeft &&
      clientX < targetRectLeft + targetWidth &&
      clientY > targetRectTop &&
      clientY < targetRectTop + targetHeight &&
      clientX > scrollTargetRectLeft &&
      clientX < scrollTargetRectLeft + scrollTargetWidth &&
      clientY > scrollTargetRectTop &&
      clientY < scrollTargetRectTop + scrollTargetHeight
    );
  }
  return (
    clientX > targetRectLeft &&
    clientX < targetRectLeft + targetWidth &&
    clientY > targetRectTop &&
    clientY < targetRectTop + targetHeight
  );
};

const getFirstVisibleCorrection = (
  corrections$: Observable<ICStyle[]>,
  targetRectPosition$: Observable<ClientRect | DOMRect>, // original target or scroll parent
  targetScroll$: Observable<ITargetScroll>,
  originalTargetRectPosition$: Observable<ClientRect | DOMRect>,
  originalTargetScroll$: Observable<ITargetScroll>,
  originalTarget: HTMLElement,
  parentWindow: Window,
): Observable<string | null> =>
  combineLatest(corrections$, targetRectPosition$, targetScroll$).pipe(
    withLatestFrom(originalTargetRectPosition$, originalTargetScroll$),
    map(([[corrections, targetRectPosition], originalTargetRectPosition]) => {
      const elIsIframe = isIframe(originalTarget);
      for (const correction of corrections) {
        // check if elementFromPoint is child of originalTarget
        if (
          !elIsIframe &&
          (!originalTarget.contains(
            parentWindow.document.elementFromPoint(
              correction.correctionRect.left,
              correction.correctionRect.top,
            ),
          ) ||
            !originalTarget.contains(
              parentWindow.document.elementFromPoint(
                correction.correctionRect.right,
                correction.correctionRect.bottom,
              ),
            ))
        ) {
          continue;
        }

        // correction.pos is relative to shadow div (scroll parent)
        if (
          // only consider original target is iframe for now
          rectWithinRect(
            correction.correctionRect,
            targetRectPosition,
            elIsIframe,
          ) &&
          rectWithinRect(
            correction.correctionRect,
            originalTargetRectPosition,
            elIsIframe,
          )
        ) {
          return correction.correction.key;
        }
      }
      return null;
    }),
    distinctUntilChanged(),
  );

const rectWithinRect = (
  innerRect: DOMRect | ClientRect,
  outerRect: DOMRect | ClientRect,
  elIsIframe: boolean,
): boolean => {
  if (elIsIframe) {
    return (
      innerRect.top + outerRect.top > 5 &&
      outerRect.top <= innerRect.top + outerRect.top &&
      outerRect.bottom >= innerRect.bottom + outerRect.top &&
      outerRect.left <= innerRect.left + outerRect.left &&
      outerRect.right >= innerRect.right + outerRect.left
    );
  }
  return (
    innerRect.top > 5 &&
    outerRect.top <= innerRect.top &&
    outerRect.bottom >= innerRect.bottom &&
    outerRect.left <= innerRect.left &&
    outerRect.right >= innerRect.right
  );
};

export const getFirstVisibleCorrectionFrom = (
  corrections$: Observable<ICStyle[]>,
  targetRectPosition$: Observable<ClientRect | DOMRect>,
  targetScroll$: Observable<ITargetScroll>,
  scrollParent$: Observable<IScrollParent>,
  scrollParentRectPosition$: Observable<ClientRect | DOMRect>,
  scrollParentScroll$: Observable<ITargetScroll>,
  originalTarget: HTMLElement,
  parentWindow: Window,
): Observable<string | null> =>
  scrollParent$.pipe(
    distinctUntilChanged(
      (a, b) =>
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position,
    ),
    switchMap(({ hasScrollParent, scrollParent }) => {
      if (hasScrollParent && scrollParent) {
        return getFirstVisibleCorrection(
          corrections$,
          scrollParentRectPosition$,
          scrollParentScroll$,
          targetRectPosition$,
          targetScroll$,
          originalTarget,
          parentWindow,
        );
      } else {
        return getFirstVisibleCorrection(
          corrections$,
          targetRectPosition$,
          targetScroll$,
          targetRectPosition$,
          targetScroll$,
          originalTarget,
          parentWindow,
        );
      }
    }),
  );

export const highlightedCorrectionKey = (
  highlightedCorrectionUnderline$: Observable<IHighlightedCorrection>,
  mouseMoveOnCorrectionCard$: Observable<string | null>,
  firstVisibleCorrectionKey$: Observable<string | null>,
): Observable<IHighlightedCorrectionInfo> =>
  combineLatest(
    highlightedCorrectionUnderline$,
    mouseMoveOnCorrectionCard$,
    firstVisibleCorrectionKey$,
  ).pipe(
    map(([correction, correctionCardKey, firstVisibleCorrectionKey]) => {
      if (correctionCardKey) {
        return {
          delay: 0,
          idx: -1,
          key: correctionCardKey,
        };
      } else if (correction.key) {
        return {
          delay: 0,
          idx: correction.idx,
          key: correction.key,
        };
      } else {
        return {
          delay: 500,
          idx: -1,
          key: firstVisibleCorrectionKey,
        };
      }
    }),
    distinctUntilChanged((a, b) => a.idx === b.idx && a.key === b.key),
    switchMap(({ delay: delayMilliSecond, idx, key }) =>
      of({
        idx,
        key,
      }).pipe(delay(delayMilliSecond)),
    ),
  );

export const mouseOverCorrectionOrCard = (
  cStyles$: Observable<ICStyle[]>,
  highlightedCorrectionKey$: Observable<IHighlightedCorrectionInfo>,
): Observable<IHighlightedCorrection> =>
  combineLatest(cStyles$, highlightedCorrectionKey$).pipe(
    map(([corrections, { idx, key }]) => {
      const cIdx = corrections.findIndex(
        (c, i) => c.correction.key === key && (idx === -1 || i === idx),
      );
      return {
        correction: corrections[cIdx] || null,
        idx,
        key: cIdx === -1 ? null : corrections[cIdx].correction.key,
      };
    }),
    distinctUntilChanged(
      (a, b) =>
        a.key === b.key &&
        (a.correction &&
          b.correction &&
          a.correction.pos.left === b.correction.pos.left &&
          a.correction.pos.top === b.correction.pos.top),
    ),
  );
