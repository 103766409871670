import { findRangeAndReplace } from '.';

/**
 *
 * @param replacement replacement text
 * @param startOffset start offset in first node
 * @param endOffset end offset in last node
 * @param nodes array of text nodes whose content will be replaced
 */
export const replaceTextInNodes = (
  nodes: Text[],
  replacement: string,
  startOffset: number,
  endOffset: number,
): void => {
  try {
    if (nodes.length === 1) {
      // get out early if start and end node are same
      const pre = nodes[0].textContent!.slice(0, startOffset);
      const suf = nodes[0].textContent!.slice(endOffset);
      findRangeAndReplace(
        nodes[0],
        startOffset,
        endOffset,
        replacement,
        `${pre}${replacement}${suf}`,
      );
      return;
    }
    let remainingText = replacement.slice(0);
    let i = 0;
    while (remainingText.length > 0) {
      if (!nodes[i]) {
        throw new Error(
          `Unable to find next node at index ${i}. Remaining Text: "${remainingText}". Args: ${JSON.stringify(
            {
              endOffset,
              nodeTexts: nodes.map(n => n.textContent!),
              replacement,
              startOffset,
            },
          )}`,
        );
      }
      const nodeTextContent = nodes[i].textContent!;
      if (i === 0) {
        const pre = nodeTextContent.slice(0, startOffset);
        const slicedReplacedText = remainingText.slice(
          0,
          nodeTextContent.length - pre.length,
        );
        findRangeAndReplace(
          nodes[i],
          startOffset,
          nodeTextContent.length,
          slicedReplacedText,
          `${pre}${slicedReplacedText}`,
        );
        remainingText = remainingText.slice(
          nodeTextContent.length - pre.length,
        );
      } else if (i === nodes.length - 1) {
        if (remainingText.length >= nodeTextContent.length) {
          findRangeAndReplace(
            nodes[i],
            0,
            nodeTextContent.length,
            remainingText,
            remainingText,
          );
        } else {
          const pre = remainingText;
          const rest = nodeTextContent.slice(endOffset);
          findRangeAndReplace(
            nodes[i],
            0,
            endOffset,
            remainingText,
            `${pre}${rest}`,
          );
        }
        remainingText = '';
      } else {
        // full text
        findRangeAndReplace(
          nodes[i],
          0,
          nodeTextContent.length,
          remainingText.slice(0, nodeTextContent.length),
          remainingText.slice(0, nodeTextContent.length),
        );
        remainingText = remainingText.slice(nodeTextContent.length);
      }
      i++;
    }
    if (i < nodes.length) {
      if (i !== nodes.length - 1) {
        throw new Error(
          `Invalid starting arguments. ${JSON.stringify({
            endOffset,
            nodeTexts: nodes.map(n => n.textContent!),
            replacement,
            startOffset,
          })}`,
        );
      }
      findRangeAndReplace(
        nodes[i],
        0,
        endOffset,
        '',
        nodes[i].textContent!.slice(endOffset),
      );
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.log(`Error in "replaceTextNode": Message: ${e.message}`);
  }
};
