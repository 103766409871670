export const getScrollTarget = (
  node: HTMLElement | null,
  parentWindow: Window,
): HTMLElement | null => {
  if (node == null || node.nodeName === 'BODY') {
    return null;
  }
  if (
    node.scrollHeight > node.clientHeight &&
    (parentWindow.getComputedStyle(node).overflowY === 'auto' ||
      parentWindow.getComputedStyle(node).overflowY === 'scroll')
  ) {
    return node;
  } else {
    return getScrollTarget(node.parentElement, parentWindow);
  }
};
