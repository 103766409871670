import * as React from 'react';
import { MdNotInterested } from 'react-icons/md';
import '../styles/CorrectionCard.css';
import '../styles/Main.css';
import { IgnoreHandler } from '../types';

interface ICorrectionCardProps {
  accept: (cKey: string, tIdx: number) => void;
  options: string[];
  correctionKey: string;
  left: number;
  ignore: IgnoreHandler;
  top: number;
}

export const CorrectionCard = (props: ICorrectionCardProps) => {
  const { accept, correctionKey, left, options, ignore, top } = props;
  const acceptFactory = (i: number) => (_: React.MouseEvent) =>
    accept(correctionKey, i);
  const ignoreFactory = () => (_: React.MouseEvent) => ignore(correctionKey);
  let firstLineOptions = options;
  let moreOptions: string[] = [];
  if (options.length > 3) {
    firstLineOptions = options.slice(0, 2);
    moreOptions = options.slice(2);
  }
  const [showSupLine, setShowSupLine] = React.useState(false);
  const [dismissSupLineTimeout, setDismissSupLineTimeout] = React.useState();
  const handleShowSupLine = () => {
    window.clearTimeout(dismissSupLineTimeout);
    setShowSupLine(true);
  };
  const handleDismissSupLine = () => {
    const currentTimeout = setTimeout(() => {
      setShowSupLine(false);
    }, 500);
    setDismissSupLineTimeout(currentTimeout);
  };
  return (
    <div
      data-cy="correction-card"
      data-correction-card={correctionKey}
      data-k={correctionKey}
      className="perfecttense-general pt-correction-card-container"
      style={{
        transform: `translate(${left}px, ${top}px)`,
      }}
    >
      <div className="perfecttense-general pt-correction-card-options">
        <div className="perfecttense-general pt-correction-card-line">
          {firstLineOptions.map((option, i) => (
            <div
              className="perfecttense-general pt-correction-card-item"
              key={i}
              onClick={acceptFactory(i)}
            >
              {option}
            </div>
          ))}
          {moreOptions.length > 0 && (
            <div className="perfecttense-general pt-correction-card-item pt-correction-card-more">
              <div
                onMouseEnter={handleShowSupLine}
                onMouseLeave={handleDismissSupLine}
              >
                ...
              </div>
              <div
                className={`perfecttense-general pt-correction-card-sup-line ${
                  showSupLine ? 'show-sup-line' : 'hide-sup-line'
                }`}
              >
                {moreOptions.map((option, i) => (
                  <div
                    onMouseEnter={handleShowSupLine}
                    onMouseLeave={handleDismissSupLine}
                    className="perfecttense-general pt-correction-card-item"
                    key={i + 2}
                    onClick={acceptFactory(i + 2)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div
            className="perfecttense-general pt-correction-card-item pt-correction-card-trash"
            onClick={ignoreFactory()}
          >
            <svg
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="perfecttense-general default-trash-icon"
            >
              <path
                d="M1.5 2.25H8.5C8.63807 2.25 8.75 2.36193 8.75 2.5V10C8.75 10.9665 7.9665 11.75 7 11.75H3C2.0335 11.75 1.25 10.9665 1.25 10V2.5C1.25 2.36193 1.36193 2.25 1.5 2.25Z"
                stroke="#5A5D64"
                strokeWidth="0.5"
              />
              <path
                d="M5 4V10"
                stroke="#5A5D64"
                strokeWidth="0.5"
                strokeLinecap="round"
              />
              <rect y="2" width="10" height="0.5" rx="0.25" fill="#5A5D64" />
              <path
                d="M6.75 2V2.25H3.25V2C3.25 1.0335 4.0335 0.25 5 0.25C5.9665 0.25 6.75 1.0335 6.75 2Z"
                stroke="#5A5D64"
                strokeWidth="0.5"
              />
            </svg>
            <svg
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="perfecttense-general hover-trash-icon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.5 2C1.22386 2 1 2.22386 1 2.5V10C1 11.1046 1.89543 12 3 12H7C8.10457 12 9 11.1046 9 10V2.5C9 2.22386 8.77614 2 8.5 2H1.5ZM5.25 4C5.25 3.86193 5.13807 3.75 5 3.75C4.86193 3.75 4.75 3.86193 4.75 4V10C4.75 10.1381 4.86193 10.25 5 10.25C5.13807 10.25 5.25 10.1381 5.25 10V4Z"
                fill="white"
              />
              <rect y="2" width="10" height="0.5" rx="0.25" fill="white" />
              <path
                d="M6.75 2V2.25H3.25V2C3.25 1.0335 4.0335 0.25 5 0.25C5.9665 0.25 6.75 1.0335 6.75 2Z"
                stroke="white"
                strokeWidth="0.5"
              />
            </svg>
            <span className="ignore-correction">Ignore</span>
          </div>
        </div>
      </div>
    </div>
  );
};
