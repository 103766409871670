export const findRangeAndReplace = (
  node: Text,
  startOffset: number,
  endOffset: number,
  replacement: string,
  fallbackReplacement: string,
) => {
  const ownerDoc = node.ownerDocument!;
  const selection = ownerDoc.defaultView!.getSelection()!;
  selection.removeAllRanges();
  const range = ownerDoc.createRange();
  range.setStart(node, startOffset);
  range.setEnd(node, endOffset);
  selection.addRange(range);
  const selectionHasChanged = selection.getRangeAt(0) === range;
  let execCommandSucceeded = false;

  // for gmail and ck inline, even after we set range, selection still goes to cursor position.
  // fall back to change node textContent directly in such cases
  if (selectionHasChanged) {
    execCommandSucceeded = ownerDoc.execCommand(
      'insertText',
      false,
      replacement,
    );
  }
  // For IE even if slection matches, execCommand is unable to replace text
  //  and so we fallback to changing textContent directly.
  if (!execCommandSucceeded || !selectionHasChanged) {
    node.textContent = fallbackReplacement;
    // Revert selection to end of the replacement
    const sel = ownerDoc.defaultView!.getSelection()!;
    sel.removeAllRanges();
    const r = ownerDoc.createRange();
    r.setStart(node, startOffset + replacement.length);
    r.setEnd(node, startOffset + replacement.length);
    selection.addRange(r);
    selection.collapseToEnd();
  }
};
