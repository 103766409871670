import { Record } from 'immutable';
import { ITransformation, TransformationType } from '../types';

export class Transformation extends Record<ITransformation>(
  {
    appliedAfterText: 'DEFAULT_APPLIED_AFTER_TEXT', // String
    appliedText: 'DEFAULT_APPLIED_TEXT', // String
    confidence: 0, // Number
    hasReplacement: false, // Boolean
    isSuggestion: false, // Boolean
    key: 'DEFAULT_TRANSFORMATION_JOB_KEY', // String
    message: 'DEFAULT_TRANSFORMATION_MESSAGE', // String
    penalty: 0, // Number
    requestId: -1, // TODO
    ruleId: 'DEFAULT_TRANSFORMATION_RULE_ID', // String
    sentAfterTransform: 'DEFAULT_SENT_AFTER_TRANSFORM', // Array<Token> or String ? TODO
    sentenceIndex: -1, // Int
    signature: 'DEFAULT_TRANSFORMATION_SIGNATURE', // String
    tokensAdded: [], // Array<Token> or List<Token> ? TODO
    tokensAffected: [], // Array<Token> or List<Token> ? TODO
    trIdx: -1,
    type: TransformationType.Replacement,
  },
  'Transformation',
) {
  constructor(obj: Partial<ITransformation> = {}) {
    super(obj);
  }
}
