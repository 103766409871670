const newLineTags = new Set([
  'P',
  'BR',
  'LI',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'PRE',
  'BLOCKQUOTE',
  'TD',
  'TH',
  'DIV',
  'BUTTON',
]);

const inlineNewLineTags = new Set(['BR', 'TD', 'TH']);

export const addManualSeparator = (node: Element) => {
  const contentWindow = node.ownerDocument!.defaultView!;
  if (inlineNewLineTags.has(node.nodeName)) {
    return '\n';
  } else if (/inline/.test(contentWindow.getComputedStyle(node).display!)) {
    return '';
  } else if (newLineTags.has(node.nodeName)) {
    return '\n';
  } else {
    return '';
  }
};
