export const isIframe = (el: HTMLElement): el is HTMLIFrameElement =>
  el.nodeName === 'IFRAME';

export const isTextarea = (el: HTMLElement): el is HTMLTextAreaElement =>
  el.nodeName === 'TEXTAREA';

export const isWindow = (el: Window | HTMLElement): el is Window => {
  return (el as Window).pageXOffset !== undefined;
};

export const isDiv = (el: HTMLElement): el is HTMLDivElement =>
  el.nodeName === 'DIV';

export const isMemoryModelBasedEditable = (target: HTMLElement) => {
  if (target.querySelector('[data-offset-key][data-editor]')) {
    // DraftJS
    return true;
  } else if (target.dataset.slateEditor === 'true') {
    // SlateJS
    return true;
  }
  return false;
};
