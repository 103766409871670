import * as React from 'react';
import { render } from 'react-dom';
import { Observable } from 'rxjs';

import { take } from 'rxjs/operators';
import { TextareaMirror } from '../components';
import { ITargetScroll } from '../types';

export const renderTextareaMirror = (
  mirrorEl: HTMLDivElement,
  sign: string,
  targetAbsolutePosition$: Observable<ClientRect | DOMRect>,
  targetScroll$: Observable<ITargetScroll>,
  text$: Observable<string>,
  style$: Observable<any>,
) => {
  targetAbsolutePosition$.pipe(take(1)).subscribe(({ width }) => {
    render(
      <TextareaMirror
        sign={sign}
        pos$={targetAbsolutePosition$}
        text$={text$}
        style$={style$}
        targetScroll$={targetScroll$}
        initWidth={width}
      />,
      mirrorEl,
    );
  });
};
