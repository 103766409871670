export const TEXTAREA_STYLE_LIST_FOR_OVERLAY = [
  'clear',
  'direction',
  'font',
  'letterSpacing',
  'overflowWrap',
  'padding',
  'textAlign',
  'textIndent',
  'textShadow',
  'verticalAlign',
  'wordBreak',
  'wordSpacing',
  'writingMode',
];

export const TEXTAREA_ATTRIBUTES = [
  'backgroundPosition',
  'backgroundRepeat',
  'blockSize',
  'borderBlockEndStyle',
  'borderBlockEndWidth',
  'borderBlockStartStyle',
  'borderBlockStartWidth',
  'borderInlineStartStyle',
  'borderInlineStartStyle',
  'borderInlineEndStyle',
  'borderInlineEndWidht',
  'borderBottomRadius',
  'borderBottomStyle',
  'borderBottomWidth',
  'borderLeftStyle',
  'borderLeftWidth',
  'borderRadius',
  'borderRightStyle',
  'borderRightWidth',
  'borderTopStyle',
  'borderTopWidth',
  'boxSizing',
  'boxShadow',

  'clear',
  'cursor',

  'direction',

  'flexDirection',
  'flexFlow',
  'fontFamily',
  'fontSize',
  'fontStretch',
  'fontStyle',
  'fontVariant',
  'fontWeight',

  'letterSpacing',
  'lineBreak',
  'lineHeight',

  'minBlockSize',
  'minInlineSize',

  'outlineStyle',
  'outlineWidth',
  'overflowWrap',

  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'perspectiveOrigin',

  'textAlign',
  'textDecoration', // might not make a difference, but better be safe
  'textIndent',
  'textShadow',
  'textTransform',
  'transformOrigin',

  'verticalAlign',

  'webkitFlexDirection',
  'webkitFlexFlow',
  'webkitLineBreak',
  'webkitPerspectiveOrigin',
  'webkitUserModify',
  'whiteSpace',
  'wordBreak',
  'wordSpacing',
  'wordWrap',
  'writingMode',
];
