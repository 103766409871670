// take any amount of args, do nothing
const nop = (..._: any[]) => {
  return;
};

// object with console methods all nop
const nopConsole: { [key: string]: any } = {};
// tslint:disable-next-line: forin
for (const field in console) {
  nopConsole[field] = nop;
}

const prefixConsole = (prefix: string = '') => {
  const con: { [key: string]: any } = {};
  // tslint:disable-next-line: forin
  for (const field in console) {
    const key = field as keyof Console;
    if (prefix && console[key] instanceof Function) {
      con[key] = (...args: any[]) => console[key](prefix, ...args);
    } else {
      con[key] = console[key];
    }
  }
  return con;
};

export const logger = (cond: boolean, prefix: string = '') =>
  (cond ? prefixConsole(prefix) : nopConsole) as Console;
