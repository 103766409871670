import * as React from 'react';
import { render } from 'react-dom';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap, take } from 'rxjs/operators';
import { CorrectionContainer } from '../components';
import {
  IDimension,
  IRelativeOffset,
  IScrollDimension,
  IScrollParent,
  ITargetScroll,
  ZIndex,
} from '../types';

export const renderCorrectionContainer = (
  sign: string,
  containerDiv: HTMLDivElement,
  targetScroll$: Observable<ITargetScroll>,
  relativeOffset$: Observable<IRelativeOffset>,
  scrollDimension$: Observable<IScrollDimension>,
  scrollParentScroll$: Observable<ITargetScroll>,
  correctionContainerPos$: Observable<IDimension>,
  targetZIndex$: Observable<ZIndex>,
  scrollParent$: Observable<IScrollParent>,
  scrollTargetAbsolutePosition$: Observable<ClientRect | DOMRect>,
  scrollParentRelativeOffset$: Observable<IRelativeOffset>,
  contentWindow: Window,
) => {
  const finalCorrectionContainerPos$ = scrollParent$.pipe(
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
    switchMap(({ hasScrollParent }) => {
      if (hasScrollParent) {
        return scrollTargetAbsolutePosition$;
      }
      return correctionContainerPos$;
    }),
  );
  const finalRelativeOffset$ = scrollParent$.pipe(
    distinctUntilChanged((a, b) => {
      return (
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position
      );
    }),
    switchMap(({ hasScrollParent, scrollParent }) => {
      if (hasScrollParent && scrollParent) {
        // when scroll parent is positioned, we put offset to 0,0 instead of using scrollParentRelativeOffset
        // e.g. test page quill editor
        if (
          contentWindow.getComputedStyle(scrollParent).position === 'static'
        ) {
          return scrollParentRelativeOffset$;
        }
        return of({ isRelative: false, left: 0, top: 0 });
      }
      return relativeOffset$;
    }),
  );

  scrollDimension$.pipe(take(1)).subscribe(({ scrollHeight, scrollWidth }) => {
    render(
      <CorrectionContainer
        style={{
          boxSizing: 'content-box',
          left: 0,
          overflow: 'hidden',
          pointerEvents: 'none',
          position: 'relative',
          top: 0,
        }}
        targetScroll$={targetScroll$}
        relativeOffset$={finalRelativeOffset$}
        scrollDimension$={scrollDimension$}
        scrollParentScroll$={scrollParentScroll$}
        correctionContainerPos$={finalCorrectionContainerPos$}
        initScrollHeight={scrollHeight * 5}
        initScrollWidth={scrollWidth * 5}
        targetZIndex$={targetZIndex$}
      />,
      containerDiv,
    );
  });
};
