import { List } from 'immutable';
import { Observable, OperatorFunction, pipe, timer } from 'rxjs';
import {
  concatMap,
  debounce,
  delayWhen,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  pluck,
  withLatestFrom,
} from 'rxjs/operators';
import { ITextStateMap } from '../../types';
import { Sentence } from '../sentence';
import { TextState } from '../textState';
import { Config } from './configSchema';
import { Job } from './job';

interface ISentenceWithIndex {
  sentence: Sentence;
  index: number;
}

type DirtySentenceGroup = ISentenceWithIndex[];

const textStateNonEmpty = (textState: TextState): boolean =>
  !textState.sentences.isEmpty() && textState.text.trim().length > 0;

const groupToJob = (
  editorId: string,
  maxLength: number,
  contextBeforeLength: number,
  contextAfterLength: number,
) => (group: ISentenceWithIndex[], textState: TextState): Job => {
  const text = group.map(s => s.sentence.text).join('');

  const firstSentence = group[0].sentence;
  // get preceding 1,000 characters context
  const contextBefore = textState.text.substring(
    Math.max(0, firstSentence.offsetInTextState - contextBeforeLength),
    firstSentence.offsetInTextState,
  );

  const lastSentence = group[group.length - 1].sentence;
  // get succeeding 1,000 characters context
  const contextAfter = textState.text.substring(
    lastSentence.offsetInTextState + lastSentence.text.length,
    Math.min(
      textState.text.length,
      lastSentence.offsetInTextState +
        lastSentence.text.length +
        contextAfterLength,
    ),
  );

  return new Job({
    changedSentences: List(group.map(s => s.sentence.id)),
    contextAfter,
    contextBefore,
    editorId,
    longText: text.length > maxLength,
    text: text.substring(0, maxLength),
    timestamp: Date.now(),
  });
};

const groupsToJobs = (editorId: string, config$: Observable<Config>) => {
  const groupToJob$ = config$.pipe(
    distinctUntilChanged(
      (a, b) =>
        a.maxLength === b.maxLength &&
        a.contextAfterLength === b.contextAfterLength &&
        a.contextBeforeLength === b.contextBeforeLength,
    ),
    distinctUntilKeyChanged('maxLength'),
    map(({ contextAfterLength, contextBeforeLength, maxLength }) =>
      groupToJob(editorId, maxLength, contextBeforeLength, contextAfterLength),
    ),
  );
  return pipe(
    withLatestFrom(groupToJob$),
    concatMap<
      [
        { groups: DirtySentenceGroup[]; textState: TextState },
        (group: DirtySentenceGroup, textState: TextState) => Job,
      ],
      Job[]
    >(([{ groups, textState }, mapFunc]) =>
      groups.map(group => mapFunc(group, textState)),
    ),
  );
};

const toDirtySentencesWithIndices = (
  textState: TextState,
): List<ISentenceWithIndex> =>
  textState.sentences
    .map((sentence, index) => ({
      index,
      sentence,
    }))
    .filter(({ sentence }) => sentence.changed);

const groupSentences = (
  dirty: List<ISentenceWithIndex>,
): DirtySentenceGroup[] => {
  const groups: DirtySentenceGroup[] = [];
  let prevIndex = -2; // make sure first iteration prevIndex is not actual index
  for (const elem of dirty) {
    if (prevIndex + 1 === elem.index) {
      // add to group
      groups[groups.length - 1].push(elem);
    } else {
      // new group
      groups.push([elem]);
    }
    prevIndex = elem.index;
  }
  return groups;
};

const debounceInput = debounce<ITextStateMap>(({ debounceTime }) =>
  timer(debounceTime),
);

const limitJobRate = (config$: Observable<Config>) => {
  const throttleTime$ = config$.pipe(
    distinctUntilKeyChanged('throttleTime'),
    pluck<Config, number>('throttleTime'),
  );
  return pipe(
    withLatestFrom(throttleTime$),
    delayWhen<[Job, number]>(([job, throttleTime]) => timer(throttleTime)),
    map(([job, throttleTime]) => job),
  );
};

const toDirtySentenceGroups: OperatorFunction<
  TextState,
  { textState: TextState; groups: DirtySentenceGroup[] }
> = map(textState => ({
  groups: groupSentences(toDirtySentencesWithIndices(textState)),
  textState,
}));

export const jobPipeline = (
  editorId: string,
  config$: Observable<Config>,
): OperatorFunction<ITextStateMap, Job> => {
  return pipe(
    debounceInput,
    map(obj => obj.textState),
    filter(textStateNonEmpty),
    toDirtySentenceGroups,
    groupsToJobs(editorId, config$),
    filter<Job>(job => job.text.trim().length > 0),
    limitJobRate(config$),
  );
};
