const replaceTextInNodeFactory = (
  target: HTMLDivElement,
  contentWindow: Window,
) => (
  node: Text,
  offsetInStartNode: number,
  offsetInEndNode: number,
  replacement: string,
) => {
  const parentElement = node.parentElement!;
  const mouseupEvent = contentWindow.document.createEvent('MouseEvent');
  // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/initMouseEvent
  mouseupEvent.initMouseEvent(
    'mouseup',
    true,
    true,
    contentWindow,
    1,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null,
  );
  const r = contentWindow.document.createRange();
  r.selectNode(node);
  r.setStart(node, offsetInStartNode);
  r.setEnd(node, offsetInEndNode);
  contentWindow.getSelection()!.removeAllRanges();
  contentWindow.getSelection()!.addRange(r);
  parentElement.dispatchEvent(mouseupEvent);
  const textEvent = contentWindow.document.createEvent('TextEvent');
  try {
    // 9 means through script execution.
    // More info https://msdn.microsoft.com/en-us/ie/ff974806(v=vs.94)
    textEvent.initTextEvent(
      'textInput',
      true,
      true,
      contentWindow,
      replacement,
      9,
      'en-US',
    );
    target.dispatchEvent(textEvent);
  } catch (e) {
    // Firefox does not support initTextEvent
    contentWindow.document.execCommand('insertText', false, replacement);
  }
};

const deleteTextInNode = (
  node: Text,
  target: HTMLDivElement,
  charsToBeRemoved: number,
  contentWindow: Window,
) => {
  const parentElement = node.parentElement!;
  const mouseupEvent = contentWindow.document.createEvent('MouseEvent');
  // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/initMouseEvent
  mouseupEvent.initMouseEvent(
    'mouseup',
    true,
    true,
    contentWindow,
    1,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null,
  );
  const r = contentWindow.document.createRange();
  r.selectNode(node);
  r.collapse(true);
  contentWindow.getSelection()!.removeAllRanges();
  contentWindow.getSelection()!.addRange(r);
  parentElement.dispatchEvent(mouseupEvent);
  const delEvent = new KeyboardEvent('keydown', {
    // KeyboardEvent init options doesn't have `keyCode` in it's defn
    // so casting it as any
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/initKeyboardEvent
    bubbles: true,
    cancelable: true,
    code: 'Delete',
    key: 'Delete',
    keyCode: 46,
    view: contentWindow,
  } as any);
  Array.from(Array(charsToBeRemoved)).forEach(() => {
    target.dispatchEvent(delEvent);
  });
};

export const replaceTextInMemoryModelBasedEditable = (
  target: HTMLDivElement,
  nodes: Text[],
  replacement: string,
  offsetInStartNode: number,
  offsetInEndNode: number,
  contentWindow: Window,
) => {
  try {
    target.focus();
    const replaceTextInNode = replaceTextInNodeFactory(target, contentWindow);
    if (nodes.length === 1) {
      replaceTextInNode(
        nodes[0],
        offsetInStartNode,
        offsetInEndNode,
        replacement,
      );
      return;
    }
    let remainingText = replacement.slice(0);
    let i = 0;
    while (remainingText.length > 0) {
      if (!nodes[i]) {
        throw new Error(
          `Unable to find next node at index ${i}. Remaining Text: "${remainingText}". Args: ${JSON.stringify(
            {
              nodeTexts: nodes.map(n => n.textContent!),
              offsetInEndNode,
              offsetInStartNode,
              replacement,
            },
          )}`,
        );
      }
      const nodeTextContent = nodes[i].textContent!;
      if (i === 0) {
        const pre = nodeTextContent.slice(0, offsetInStartNode);
        const replacementTextForNode = remainingText.slice(
          0,
          nodeTextContent.length - pre.length,
        );
        replaceTextInNode(
          nodes[i],
          offsetInStartNode,
          nodeTextContent.length,
          replacementTextForNode,
        );
        remainingText = remainingText.slice(
          nodeTextContent.length - pre.length,
        );
      } else if (i === nodes.length - 1) {
        replaceTextInNode(nodes[i], 0, offsetInEndNode, remainingText);
        remainingText = '';
      } else {
        const replacementTextForNode = remainingText.slice(
          0,
          nodeTextContent.length,
        );
        replaceTextInNode(
          nodes[i],
          0,
          nodeTextContent.length,
          replacementTextForNode,
        );
        // full text
        remainingText = remainingText.slice(nodeTextContent.length);
      }
      i++;
    }
    if (i < nodes.length) {
      if (i !== nodes.length - 1) {
        throw new Error(
          `Invalid starting arguments. ${JSON.stringify({
            nodeTexts: nodes.map(n => n.textContent!),
            offsetInEndNode,
            offsetInStartNode,
            replacement,
          })}`,
        );
      }
      const replacementTextForNode = nodes[i].textContent!.slice(
        offsetInEndNode,
      );
      if (replacementTextForNode.length === 0) {
        deleteTextInNode(
          nodes[i],
          target,
          nodes[i].textContent!.length,
          contentWindow,
        );
      } else {
        replaceTextInNode(
          nodes[i],
          0,
          nodes[i].textContent!.length,
          replacementTextForNode,
        );
      }
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.log(
      `Error in "replaceTextInMemoryModelBasedEditable": Message: ${e.message}`,
    );
  }
};
