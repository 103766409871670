import { Correction } from '../state';

// Make sure none of the enum has hyphen `-` in the string.
export enum EditorType {
  Textarea = 'textarea',
  Kendo = 'kendo',
  Tiny = 'tiny',
  CkInline = 'ckinline',
  Ck4 = 'ck4',
  Ck5 = 'ck5',
  Quill = 'quill',
  ContentEditable = 'contenteditable',
  IframeContentEditable = 'iframecontenteditable',
}

export interface IScrollTargetOffsetToTarget {
  leftOffset: number;
  topOffset: number;
}

export interface ICorrection {
  correction: Correction;
  startOffset: number;
  endOffset: number;
  startOffsetForTextContent: number;
  endOffsetForTextContent: number;
}

export interface IOffset {
  left: number;
  top: number;
}

export interface ICorrectionRange {
  correction: Correction;
  endNode: Node;
  offsetInEndNode: number;
  startNode: Node;
  offsetInStartNode: number;
  startOffset: number;
  endOffset: number;
  startOffsetForTextContent: number;
  endOffsetForTextContent: number;
}

export interface IHighlightedCorrectionInfo {
  idx: number;
  key: string | null;
}

export interface IHighlightedCorrection extends IHighlightedCorrectionInfo {
  correction: ICStyle | null;
}

export interface ICorrectionDomRange {
  correctionRange: ICorrectionRange;
  domRange: Range;
}

export interface IMouseMove {
  clientX: number;
  clientY: number;
}

export interface IMouseMoveWithEl extends IMouseMove {
  inIframe: boolean;
}

export interface INodeTraversalData {
  node: Text;
  level: number;
  till: number;
}

export interface ICorrectionPosition {
  correction: Correction;
  pos: ClientRect | DOMRect;
  correctionRect: ClientRect | DOMRect;
  correctionRange: ICorrectionRange;
  visible: boolean;
}

export interface IDimension {
  height: number;
  width: number;
}

export interface IScrollDimension {
  scrollHeight: number;
  scrollWidth: number;
}

export interface ITargetScroll {
  targetScrollX: number;
  targetScrollY: number;
}

export interface IBodyScroll {
  target: HTMLElement;
  scrollX: number;
  scrollY: number;
}

export interface ICstyleCss extends IOffset {
  height: number;
  width: number;
}

export interface ICStyle {
  correction: Correction;
  highlighted: boolean;
  pos: ClientRect | DOMRect;
  style: ICstyleCss;
  underlineThickness: UnderlineThickness;
  correctionRect: ClientRect | DOMRect;
  correctionRange: ICorrectionRange;
  visible: boolean;
}

export interface ICorrectionCardInfo extends IOffset {
  options: string[];
  key: string;
  logo: string;
  caption: string;
  correctionRange: ICorrectionRange;
}

export interface IWatermarkPosition {
  x: number;
  y: number;
}

export interface IScrollParent {
  hasScrollParent: boolean;
  position: string;
  scrollParent: HTMLElement | null;
}

export interface IRelativeOffset extends IOffset {
  isRelative: boolean;
}

export interface IWatermarkPadding {
  paddingBottom: number;
  paddingRight: number;
}

export enum UnderlineThickness {
  Large = 'thick',
  Small = 'thin',
}

export type AcceptHandler = (
  correctionKey: string,
  transformationIndex: number,
  correctionRange: ICorrectionRange,
  replacementText: string,
) => void;

export type IgnoreHandler = (correctionKey: string) => void;

export type ZIndex =
  | number
  | 'auto'
  | '-moz-initial'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset'
  | undefined;
