import { Map, Record, Set } from 'immutable';
import { IGroupedCorrection, IToken, ITransformation } from '../types';
import { Transformation } from './transformation';

export class Correction extends Record<IGroupedCorrection>(
  {
    afterText: '', // String
    applicable: true, // Boolean
    applied: -1, // Int
    caption: 'Correction Needed', // String
    decoratedText: '', // String
    initialEndIndex: -1, // Int
    initialStartIndex: -1, // Int
    jobKey: 'DEFAULT_CORRECTION_JOB_KEY', // String
    key: 'DEFAULT_CORRECTION_KEY', // String
    message: 'DEFAULT_CORRECTION_MESSAGE', // String
    signature: 'DEFAULT_CORRECTION_SIGNATURE', // String
    transformations: [], // Array<Transformation> or List<Transformation> ? TODO
  },
  'Correction',
) {
  constructor(obj: Partial<IGroupedCorrection> = {}) {
    const transformations = obj.transformations || [];
    super({
      ...obj,
      transformations: transformations.map(
        t => new Transformation(t),
      ) as ITransformation[],
    });
  }
  get penalty(): number {
    return this.transformations.reduce((acc, t) => acc + t.penalty, 0);
  }
  get tokensAffected(): Set<IToken> {
    return this.transformations.reduce(
      (acc, t) => acc.union(t.tokensAffected),
      Set<IToken>(),
    );
  }

  // save some correction info for future reference check if we want to ignore the future correction
  get basicInfo() {
    const firstTransformation = this.transformations[0];
    const tokensAddedWithConfidence = this.transformations.reduce(
      (acc, transformation) =>
        acc +
        transformation.tokensAffected.reduce(
          (finalText, token) => finalText + token.value + token.after,
          '',
        ) +
        ':' +
        transformation.confidence +
        ':',
      '',
    );

    return Map({
      appliedAfterText: firstTransformation.appliedAfterText,
      appliedText: firstTransformation.appliedText,
      hasReplacement: firstTransformation.hasReplacement,
      isSuggestion: firstTransformation.isSuggestion,
      penalty: firstTransformation.penalty,
      requestId: firstTransformation.requestId,
      ruleId: firstTransformation.ruleId,
      tokensAddedWithConfidence,
    });
  }
}
