import * as React from 'react';
import { combineLatest, Observable, Subscription } from 'rxjs';

import { map } from 'rxjs/operators';
import {
  IDimension,
  IRelativeOffset,
  IScrollDimension,
  ITargetScroll,
  ZIndex,
} from '../types';

const { useEffect, useRef, useState } = React;

export interface ICorrectionContainerInterface {
  targetScroll$: Observable<ITargetScroll>;
  style: React.CSSProperties;
  relativeOffset$: Observable<IRelativeOffset>;
  scrollDimension$: Observable<IScrollDimension>;
  scrollParentScroll$: Observable<ITargetScroll>;
  correctionContainerPos$: Observable<IDimension>;
  initScrollHeight: number;
  initScrollWidth: number;
  targetZIndex$: Observable<ZIndex>;
}

export const CorrectionContainer = ({
  targetScroll$,
  style,
  relativeOffset$,
  scrollDimension$,
  scrollParentScroll$,
  correctionContainerPos$,
  initScrollHeight,
  initScrollWidth,
  targetZIndex$,
}: ICorrectionContainerInterface) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(initScrollHeight);
  const [scrollWidth, setScrollWidth] = useState(initScrollWidth);
  const [zIndex, setZIndex] = useState('auto' as ZIndex);
  useEffect(() => {
    const sub = targetZIndex$.subscribe(z => {
      setZIndex(z);
    });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    const sub = correctionContainerPos$.subscribe(pos => {
      setHeight(pos.height);
      setWidth(pos.width);
    });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    const sub = combineLatest(relativeOffset$, scrollParentScroll$)
      .pipe(
        map(([offset, { targetScrollX, targetScrollY }]) => ({
          left: offset.left, // + targetScrollX,
          top: offset.top, // + targetScrollY,
        })),
      )
      .subscribe(offset => {
        setLeft(offset.left);
        setTop(offset.top);
      });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    const sub = scrollDimension$.subscribe(scrollDimension => {
      if (scrollHeight < scrollDimension.scrollHeight) {
        setScrollHeight(scrollDimension.scrollHeight);
      }
      if (scrollWidth < scrollDimension.scrollWidth) {
        setScrollWidth(scrollDimension.scrollWidth);
      }
    });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    let sub: Subscription;
    sub = combineLatest(targetScroll$, scrollParentScroll$).subscribe(
      ([
        { targetScrollX, targetScrollY },
        {
          targetScrollX: scrollParentScrollX,
          targetScrollY: scrollParentScrollY,
        },
      ]) => {
        ref.current!.scrollLeft = targetScrollX + scrollParentScrollX;
        ref.current!.scrollTop = targetScrollY + scrollParentScrollY;
      },
    );
    return () => sub.unsubscribe();
  }, []);
  return (
    <div ref={ref} style={{ ...style, height, width, left, top, zIndex }}>
      <div style={{ position: 'absolute', left: 0, top: 0 }}>
        <div
          style={{
            height: scrollHeight * 2,
            width: scrollWidth * 2,
          }}
        />
      </div>
    </div>
  );
};
