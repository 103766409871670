import { addManualSeparator } from './addManualSeparator';

export const extractTextFromContentEditable = (
  node: HTMLElement | ChildNode,
  level: number = 0,
): string => {
  let s = '';
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < node.childNodes.length; i++) {
    const child = node.childNodes[i];
    if (child.nodeType === Node.TEXT_NODE) {
      s += child.textContent!;
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      const el = child as Element;
      s +=
        addManualSeparator(el) +
        extractTextFromContentEditable(el, level + 1) +
        addManualSeparator(el);
    }
  }
  return level === 0 ? s.trim() : s;
};
