import { filter } from 'rxjs/operators';
import { IMessage, SocketResponseType } from '../types';

export const filterChannels = <In extends SocketResponseType, Out extends In>(
  channels: Out[],
) =>
  filter<IMessage<In>, IMessage<Out>>(
    (message: IMessage<In>): message is IMessage<Out> =>
      (channels as string[]).includes(message.channel),
  );
