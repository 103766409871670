import * as React from 'react';
import { render } from 'react-dom';
import { combineLatest, Observable } from 'rxjs';
import { Watermark } from '../components';
import { IDimension, IWatermarkPosition } from '../types';

export const renderWatermark = (
  sign: string,
  watermarkEl: HTMLDivElement,
  watermarkPosition$: Observable<IWatermarkPosition>,
  showLoadingWatermark$: Observable<boolean>,
  targetDimensions$: Observable<IDimension>,
  linkUrl$: Observable<string>,
) => {
  combineLatest(
    watermarkPosition$,
    showLoadingWatermark$,
    targetDimensions$,
    linkUrl$,
  ).subscribe({
    next: ([{ x, y }, loading, targetDimensions, linkUrl]) => {
      render(
        <Watermark
          linkUrl={linkUrl}
          loading={loading}
          sign={sign}
          targetDimensions={targetDimensions}
          x={x}
          y={y}
        />,
        watermarkEl,
      );
    },
  });
};
