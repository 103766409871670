import * as React from 'react';
import { render } from 'react-dom';
import { Observable } from 'rxjs';
import { CorrectionCardContainer } from '../components';
import { AcceptHandler, ICorrectionCardInfo, IgnoreHandler } from '../types';

export const renderCorrectionCard = (
  el: HTMLDivElement,
  correctionCardProps$: Observable<ICorrectionCardInfo | null>,
  accept: AcceptHandler,
  ignore: IgnoreHandler,
) => {
  render(
    <CorrectionCardContainer
      accept={accept}
      ignore={ignore}
      correctionCardProp$={correctionCardProps$}
    />,
    el,
  );
};
