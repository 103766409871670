import { ZIndex } from '../types';

const VALID_Z_INDEX = [
  'auto',
  '-moz-initial',
  'inherit',
  'initial',
  'revert',
  'unset',
];

export const generateValidZIndex = (zIndex: string | null): ZIndex => {
  if (zIndex) {
    if (!isNaN(parseInt(zIndex, 10))) {
      return parseInt(zIndex, 10);
    }
    if (VALID_Z_INDEX.includes(zIndex)) {
      return zIndex as
        | 'auto'
        | '-moz-initial'
        | 'inherit'
        | 'initial'
        | 'revert'
        | 'unset';
    }
  }
  return 'auto';
};
