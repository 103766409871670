export const targetFromSelectionAnchor = (
  node: Node | null,
): HTMLElement | null => {
  if (!node) {
    return null;
  }
  const nodeParent: HTMLElement | null =
    node.nodeType === Node.ELEMENT_NODE
      ? (node as HTMLElement)
      : node.parentElement;
  if (!nodeParent) {
    return null;
  }
  let contentEditableRoot: HTMLElement | null = null;
  for (
    let currentNode = nodeParent;
    currentNode.parentElement !== null;
    currentNode = currentNode.parentElement
  ) {
    if (currentNode.contentEditable === 'true') {
      contentEditableRoot = currentNode;
    }
  }
  return contentEditableRoot;
};
