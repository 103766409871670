import * as React from 'react';
import { render } from 'react-dom';
import { Observable } from 'rxjs';

import { Corrections } from '../components';
import { ICStyle } from '../types';

export const renderCorrectionUnderlines = (
  el: HTMLDivElement,
  sign: string,
  cStyle$: Observable<ICStyle[]>,
) => {
  render(<Corrections rects$={cStyle$} sign={sign} />, el);
};
