import {
  isDiv,
  isMemoryModelBasedEditable,
  nodeTraversalDataFor,
  replaceTextInMemoryModelBasedEditable,
  replaceTextInNodes,
} from '.';
import { ICorrectionRange } from '../types';

export const replaceContentForCorrection = (
  el: HTMLElement,
  correctionRange: ICorrectionRange,
  replacement: string,
  contentWindow: Window,
) => {
  try {
    const {
      endNode,
      offsetInEndNode,
      startNode,
      offsetInStartNode,
    } = correctionRange;
    const parsed = nodeTraversalDataFor(el);
    const startNodeIdx = parsed.findIndex(d => d.node === startNode);
    const endNodeIdx = parsed.findIndex(d => d.node === endNode);
    const nodes = parsed.slice(startNodeIdx, endNodeIdx + 1).map(d => d.node);

    if (isDiv(el) && isMemoryModelBasedEditable(el)) {
      replaceTextInMemoryModelBasedEditable(
        el,
        nodes,
        replacement,
        offsetInStartNode,
        offsetInEndNode,
        contentWindow,
      );
    } else {
      replaceTextInNodes(
        nodes,
        replacement,
        offsetInStartNode,
        offsetInEndNode,
      );
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.log(e);
  }
};
