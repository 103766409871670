import Delta from 'quill-delta';
import { logger } from '../log';

const tokenConsole = logger(false);

export const applyDeltaToString = (
  delta,
  str,
  isLastTokenInLastSentence = false,
) => {
  tokenConsole.log('applyDeltaToString');
  if (!delta || !delta.ops || delta.ops.length === 0) {
    return { delta: null, str };
  }
  let ops = Array.from(delta.ops).reverse();
  let offset = 0; // character offset in current chunk
  // while there are remaining ops, apply ops to chunks:
  let text = str;
  while (ops.length > 0) {
    // pop next op
    const op = ops.pop();
    tokenConsole.log(`op: ${JSON.stringify(op)}`, {
      offset,
      textLength: text.length,
    });
    // get current chunk
    if ('retain' in op) {
      const consumed = Math.min(op.retain, text.length - offset);
      const leftInOp = op.retain - consumed;
      // move offset by amount retaining
      offset += consumed;
      tokenConsole.log('after retain', {
        offset,
        consumed,
        leftInOp,
        text,
      });
      if (leftInOp > 0) {
        // if retain amount was longer than the rest of the chunk, push an op with the remainder
        ops.push({ retain: leftInOp });
        break;
      }
      if (consumed === text.length) {
        // a token just end, break
        if (!isLastTokenInLastSentence) {
          tokenConsole.log('===not lastTokenInLastSentence===');
          break;
        } else {
          tokenConsole.log('===isLastTokenInLastSentence===');
          const nextOp = ops.slice(-1)[0];
          if (
            nextOp &&
            nextOp.insert &&
            !nextOp.insert[0].match(/^[0-9a-zA-Z]+$/)
          ) {
            // break at this token if next op is inserting a non-alphanumeric
            tokenConsole.log(
              '======isLastTokenInLastSentence======, nextOp is:',
              nextOp,
            );
            break;
          }
        }
      }
    } else if ('insert' in op) {
      // build modified string:
      const pre = text.substring(0, offset);
      const post = text.substring(offset);
      text = pre + op.insert + post;
      offset += op.insert.length;
      tokenConsole.log('after insert', {
        offset,
        insert: op.insert,
        pre,
        post,
        text,
      });
      if (offset >= text.length && !isLastTokenInLastSentence) {
        break;
      }
    } else {
      //if ("delete" in op) {
      const deleted = Math.min(op.delete, text.length - offset);
      const leftInOp = op.delete - deleted;
      // build modified string:
      const pre = text.substring(0, offset);
      const post = text.substring(offset + op.delete);
      text = pre + post;
      tokenConsole.log('after delete', {
        deleted,
        leftInOp,
        offset,
        delete: op.delete,
        pre,
        post,
        text,
      });
      if (leftInOp > 0) {
        // if delete amount was longer than the rest of the chunk, push an op with the remainder
        ops.push({ delete: leftInOp });
        //if (!isLastTokenInLastSentence) {
        break;
        //}
      }
    }
  } // while ops
  return { delta: ops.length > 0 ? new Delta(ops.reverse()) : null, str: text };
}; //applyDeltaToString()
