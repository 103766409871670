import * as React from 'react';
import { Observable } from 'rxjs';
import { Correction } from '.';
import { ICStyle } from '../types';

interface ICorrectionProps {
  rects$: Observable<ICStyle[]>;
  sign: string;
}

export const Corrections = (props: ICorrectionProps) => {
  const [rects, setRect] = React.useState<ICStyle[]>([]);
  React.useEffect(() => {
    const sub = props.rects$.subscribe(newRects => {
      setRect(newRects);
    });
    return () => sub.unsubscribe();
  }, []);
  let multi = 0;
  return (
    <>
      {rects.map((rect, idx) => {
        if (idx > 0) {
          const prevCorrectionKey = rects[idx - 1].correction.key;
          if (prevCorrectionKey === rect.correction.key) {
            multi += 1;
          } else {
            multi = 0;
          }
        }
        return (
          <Correction
            key={`${rect.correction.key}:${multi}`}
            sign={props.sign}
            height={rect.style.height}
            type={rect.correction.transformations[0].type}
            width={rect.style.width}
            highlighted={rect.highlighted}
            underlineThickness={rect.underlineThickness}
            left={rect.style.left}
            top={rect.style.top}
            correctionKey={rect.correction.key}
            visible={rect.visible}
          />
        );
      })}
    </>
  );
};
