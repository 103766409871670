import * as React from 'react';
import { Observable } from 'rxjs';

import { ITargetScroll } from '../types';

const { useEffect, useRef, useState } = React;

export interface ITextareaMirrorProps {
  sign: string;
  pos$: Observable<ClientRect | DOMRect>;
  text$: Observable<string>;
  style$: Observable<any>;
  targetScroll$: Observable<ITargetScroll>;
  initWidth: number;
}

export const TextareaMirror = ({
  sign,
  pos$,
  text$,
  style$,
  targetScroll$,
  initWidth,
}: ITextareaMirrorProps) => {
  const [text, setText] = useState('');
  const [style, setStyle] = useState({});
  const [w, setW] = useState(initWidth);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const sub = pos$.subscribe(pos => {
      setW(pos.width);
      setTop(pos.top);
      setLeft(pos.left);
    });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    const sub = style$.subscribe(newStyle => {
      setStyle(newStyle);
    });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    const sub = text$.subscribe((newString: string) => {
      setText(newString);
    });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    const sub = targetScroll$.subscribe(scroll => {
      ref.current!.scrollLeft = scroll.targetScrollX;
      ref.current!.scrollTop = scroll.targetScrollY;
    });
    return () => sub.unsubscribe();
  }, []);

  // for height we always set to 0
  // this seems to fix the bug in Firefox
  // when editor has scroll and padding bottom, scroll to bottom causing underline offset
  // caused by scrollHeight difference
  return (
    <>
      <div
        data-cy={`pt-mirror-${sign}`}
        ref={ref}
        style={{
          ...style,
          color: 'transparent',
          height: 0,
          left: 0,
          overflow: 'hidden',
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          transform: `translate(${left}px, ${top}px)`,
          width: w,
          zIndex: -1,
        }}
      >
        {text.split('\n').map(t => (
          <>
            {t}
            <br />
          </>
        ))}
      </div>
    </>
  );
};
