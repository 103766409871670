import { IMouseMove } from '../types';

export const cursorInCoordinates = (
  correctionPos: ClientRect | DOMRect,
  cursorPos: IMouseMove,
  elIsIframe: boolean,
  targetRect: ClientRect | DOMRect,
) => {
  const { bottom, left, right, top } = correctionPos;
  const { clientX, clientY } = cursorPos;
  if (elIsIframe) {
    const { left: targetLeftRect, top: targetTopRect } = targetRect;
    return (
      clientX > left + targetLeftRect &&
      clientX < right + targetLeftRect &&
      clientY > top + targetTopRect &&
      clientY < bottom + targetTopRect
    );
  }
  return (
    left <= clientX && right >= clientX && bottom >= clientY && top <= clientY
  );
};
