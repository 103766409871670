import { unmountComponentAtNode } from 'react-dom';
import {
  BehaviorSubject,
  ConnectableObservable,
  fromEvent,
  merge,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  multicast,
  pluck,
  startWith,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  computeRangesWith,
  correctionCardKeyFrom,
  correctionCardPropsFrom,
  correctionContainerPos,
  correctionsFrom,
  diffBetween,
  dynamicScrollParentFrom,
  dynamicTargetScrollFor,
  elementFromPoint,
  enhanceCorrectionWithHighlights,
  filterChannels,
  getFirstVisibleCorrectionFrom,
  getRefinedWatermarkPosition,
  grammarScoreFrom,
  highlightedCorrectionKey,
  highlightedCorrectionUnderline,
  isExpectingResponse,
  jobTextForTextarea,
  jobTextFrom,
  mouseMovesToIframe,
  mouseOverCorrectionOrCard,
  paddingFor,
  scrollDimensionFor,
  scrollParentOffsetToTarget,
  scrollParentRectPosition,
  scrollParentRelativeOffset,
  scrollTargetAbsolutePositionFromDocument,
  scrollWithNonPositionedScrollParent,
  scrollWithPositionedScrollParent,
  styleFromRects,
  targetAbsolutePositionFromDocument,
  targetDimensionsNullified,
  targetRectPosition,
  targetRelativeOffset,
  targetRemoved,
  targetScrollFor,
  underlineThicknessFrom,
  WebsocketSubject,
  ZIndexFor,
} from '../observables';
import {
  renderCorrectionCard,
  renderCorrectionContainer,
  renderCorrectionUnderlines,
  renderWatermark,
  renderWatermarkContainer,
} from '../renderers';
import {
  ActiveEditables,
  Job,
  jobPipeline,
  parseCorrectionKey,
  transformationKey,
  updateTextState,
} from '../state';
import {
  AcceptHandler,
  IAcceptUpdater,
  IBodyScroll,
  ICStyle,
  IFeedbackParams,
  IgnoreHandler,
  IIgnoreUpdater,
  IMongoIdUpdater,
  IMouseMoveWithEl,
  IResponsesUpdater,
  IScrollParent,
  ISentJobUpdater,
  ITextStateMap,
  JobResponse,
  JobResponseType,
  SocketResponseType,
} from '../types';
import {
  createContainerDiv,
  extractTextFromContentEditable,
  insertAfter,
  isIframe,
  isTextarea,
  noop,
  replaceContentForCorrection,
} from '../utils';

export const bootstrapEditable = (
  documentMutations$: Observable<MutationRecord[]>,
  bodyScroll$: Observable<IBodyScroll>,
  socketSubject: WebsocketSubject,
  target: HTMLElement,
  sign: string,
  documentMouseMoves$: Observable<IMouseMoveWithEl>,
  elementFromPoint$: Observable<Element | null>,
  additionalTeardown: () => void = noop,
  textareaTarget: HTMLTextAreaElement | null = null,
  grammarScoreCallback: (score: number, target: HTMLElement) => void = noop,
  parentWindow: Window = window,
) => {
  const originalTarget: HTMLElement = textareaTarget || target;
  let inputTarget: HTMLElement = target;
  let scrollTarget: Window | HTMLElement = originalTarget;
  let contentWindow: Window = parentWindow;
  if (isIframe(target)) {
    inputTarget = target.contentDocument!.body;
    scrollTarget = target.contentDocument!.defaultView!;
    contentWindow = target.contentWindow!;
  }
  if (textareaTarget === null) {
    // => This is for a non textarea.
    target.dataset.ptEditor = 'true';
    target.dataset.gramm_editor = 'false';
    target.dataset.cy = `pt-target-${sign}`;
    target.dataset.pt = `pt-target-${sign}`;
    target.setAttribute('spellcheck', 'false');
  }

  // observe input change
  const bodyMutations = new BehaviorSubject<HTMLElement>(inputTarget);
  const mutationObserver = new MutationObserver(_ => {
    bodyMutations.next(inputTarget);
  });
  mutationObserver.observe(inputTarget, {
    characterData: true,
    characterDataOldValue: true,
    childList: true,
    subtree: true,
  });

  // observe style change
  const originalTargetMutations = new BehaviorSubject<HTMLElement>(
    originalTarget,
  );
  const originalTargetMutationObserver = new MutationObserver(_ => {
    originalTargetMutations.next(originalTarget);
  });
  originalTargetMutationObserver.observe(originalTarget, {
    attributeFilter: ['class', 'style'],
    attributes: true,
  });
  const underlineThickness$ = underlineThicknessFrom(
    originalTargetMutations.asObservable(),
    parentWindow,
  );

  // To fix site like boa customer service feedback page where editor has assigned z-index
  const targetZIndex$ = ZIndexFor(
    originalTarget,
    originalTargetMutations.asObservable(),
    parentWindow,
  );
  const targetPadding$ = paddingFor(
    originalTarget,
    originalTargetMutations.asObservable(),
    parentWindow,
  );

  // dynamically get target's scrollParent
  let scrollParent$: Observable<IScrollParent> = of({
    hasScrollParent: false,
    position: '',
    scrollParent: null,
  });

  if (!isIframe(target)) {
    scrollParent$ = dynamicScrollParentFrom(
      parentWindow,
      documentMutations$,
      originalTarget,
    );
  }

  // dynamically generate shadow's width and height
  const scrollDimension$ = scrollDimensionFor(
    bodyMutations.asObservable(),
    inputTarget,
    scrollParent$,
  );

  // scroll for target's scroll parent
  const scrollParentScroll$ = dynamicTargetScrollFor(
    scrollParent$,
    originalTarget,
  );

  // if scrollParent is static, scroll
  const scrollWithNonPositionedScrollParent$ = scrollWithNonPositionedScrollParent(
    parentWindow,
    scrollParent$,
    scrollParentScroll$,
  );
  // if scrollParent is positioned, scroll
  const scrollWithPositionedScrollParent$ = scrollWithPositionedScrollParent(
    parentWindow,
    scrollParent$,
    scrollParentScroll$,
  );

  // offset towards positioned ancestor (with self margin) + self border
  const relativeOffset$ = targetRelativeOffset(
    documentMutations$,
    originalTarget,
    parentWindow,
    scrollWithNonPositionedScrollParent$,
  );

  // targetAbsolutePosition$ and targetRectPosition$ can be merged in one?
  // target absolute position to doc, without margin and border
  const targetAbsolutePosition$ = targetAbsolutePositionFromDocument(
    originalTarget,
    documentMutations$,
    bodyScroll$,
    parentWindow,
  );

  // target rect
  const targetRectPosition$ = targetRectPosition(
    originalTarget,
    documentMutations$,
    bodyScroll$,
    parentWindow,
  );

  const scrollParentRectPosition$ = scrollParentRectPosition(
    scrollParent$,
    documentMutations$,
    bodyScroll$,
    parentWindow,
  );

  // offset towards positioned ancestor (with self margin) + self border
  const scrollParentRelativeOffset$ = scrollParentRelativeOffset(
    documentMutations$,
    scrollParent$,
    parentWindow,
    of({
      targetScrollX: 0,
      targetScrollY: 0,
    }),
  );

  const teardown$ = merge(
    targetRemoved(documentMutations$, originalTarget, sign),
    targetDimensionsNullified(targetAbsolutePosition$, originalTarget, sign),
  );

  // put watermark at scroll parent, get scroll for scrollParent
  // target absolute position to doc, without margin and border
  const scrollTargetAbsolutePosition$ = scrollTargetAbsolutePositionFromDocument(
    scrollParent$,
    documentMutations$,
    bodyScroll$,
    parentWindow,
  );

  // the offset left and top from scroll parent to target
  const scrollParentOffsetToTarget$ = scrollParentOffsetToTarget(
    scrollTargetAbsolutePosition$,
    targetAbsolutePosition$,
    scrollParent$,
  );

  // use scrollWithPositionedScrollParent$
  // only when scroll parent is positioned (e.g. gmail, scroll parent is positioned ancestor), then we need to consider scroll parent scroll

  const watermarkPosition$ = getRefinedWatermarkPosition(
    scrollParent$,
    scrollTargetAbsolutePosition$,
    targetAbsolutePosition$,
    targetPadding$,
  );

  const targetScroll$ = targetScrollFor(scrollTarget);
  const targetInput$ = isTextarea(originalTarget)
    ? jobTextForTextarea(bodyMutations.asObservable(), originalTarget)
    : jobTextFrom(
        bodyMutations.asObservable(),
        extractTextFromContentEditable(inputTarget),
      );

  const config$ = socketSubject.config$;
  const assets$ = socketSubject.assets$;
  const message$ = socketSubject.messagesFor(sign);

  const responseBuffer$ = message$.pipe(
    filterChannels<SocketResponseType, JobResponseType>([
      SocketResponseType.Tokenized,
      SocketResponseType.Unchanged,
      SocketResponseType.Corrections,
    ]),
    pluck('msg'),
    map((response: JobResponse) => [response]),
    map((responses: JobResponse[]): IResponsesUpdater => ({ responses })),
  );

  const acceptIgnoreSubject = new Subject<IAcceptUpdater | IIgnoreUpdater>();

  const accept$ = acceptIgnoreSubject
    .asObservable()
    .pipe(filter((x): x is IAcceptUpdater => 'accept' in x));

  const acceptHandler: AcceptHandler = (
    cKey,
    transformationIndex,
    correctionRange,
    replacementText,
  ) => {
    acceptIgnoreSubject.next({
      accept: transformationKey({
        ...parseCorrectionKey(cKey),
        transformationIndex,
      }),
      correctionRange,
      propSentenceIndex: -1,
      replacementText,
    });
  };

  const ignoreHandler: IgnoreHandler = cKey => {
    acceptIgnoreSubject.next({ ignore: cKey, propSentenceIndex: -1 });
  };

  const sentJob$ = socketSubject
    .sentJobObservable(sign)
    .pipe(map((job: Job): ISentJobUpdater => ({ sentJob: job })));

  const diffBetweenExtractedAndTextContent$ = diffBetween(
    bodyMutations.asObservable(),
    originalTarget,
  );

  const mongoId$ = message$.pipe(
    filterChannels([SocketResponseType.Mongo]),
    map(({ msg }) => msg as IMongoIdUpdater),
  );

  const textStateUpdate$ = merge(
    targetInput$,
    responseBuffer$,
    acceptIgnoreSubject.asObservable(),
    mongoId$,
    sentJob$,
    diffBetweenExtractedAndTextContent$,
  );

  const textStateMap$ = textStateUpdate$.pipe(
    updateTextState(config$),
    multicast(new Subject()),
  ) as ConnectableObservable<ITextStateMap>;

  const grammarScore$ = grammarScoreFrom(textStateMap$);

  const job$ = textStateMap$.pipe(jobPipeline(sign, config$));

  const sendFeedback$: Observable<boolean> = config$.pipe(
    pluck('sendFeedback'),
    distinctUntilChanged(),
  );

  const feedback$: Observable<IFeedbackParams> = textStateMap$.pipe(
    pluck('feedbackParams'),
    filter((fp): fp is IFeedbackParams => fp !== null),
    withLatestFrom(sendFeedback$),
    filter(([_, sendFeedback]) => sendFeedback),
    map(([feedbackParams]) => feedbackParams),
  );

  const corrections$ = correctionsFrom(textStateMap$);
  const keyDown$ = fromEvent<KeyboardEvent>(inputTarget, 'keydown').pipe(
    distinctUntilChanged(),
  );
  const correctionPositions$ = computeRangesWith(
    inputTarget,
    corrections$,
    merge(accept$, targetScroll$, scrollParentScroll$, keyDown$).pipe(
      startWith(null),
    ),
    isIframe(target),
    parentWindow,
    targetRectPosition$,
    targetScroll$,
    scrollParentScroll$,
    scrollParentOffsetToTarget$,
  );

  const cStyles$ = styleFromRects(
    correctionPositions$,
    underlineThickness$,
  ).pipe(multicast(new Subject())) as ConnectableObservable<ICStyle[]>;

  // iframeMouseMoves$: if iframe, mouseMove inside iframe, to iframe viewport, otherwise Observable<never>
  const iframeMouseMoves$ = mouseMovesToIframe(
    isIframe(target),
    contentWindow,
    targetAbsolutePosition$,
    targetScroll$,
  );

  // This container scrolls when editor/scrollParent scrolls
  // so we do not need to re-render on scroll
  const correctionContainerPos$ = correctionContainerPos(
    scrollTargetAbsolutePosition$,
    targetAbsolutePosition$,
    scrollParent$,
  );

  // for iframe only pass the mouseMoveToIframe
  // so we dont trigger hover when mouse over certain position on main doc
  const highlightedCorrectionUnderline$ = highlightedCorrectionUnderline(
    cStyles$,
    merge(iframeMouseMoves$, documentMouseMoves$),
    isIframe(target),
    targetRectPosition$,
    bodyScroll$,
    scrollParent$,
    scrollParentRectPosition$,
  );

  if (isIframe(target)) {
    elementFromPoint$ = merge(
      elementFromPoint$,
      elementFromPoint(
        merge(iframeMouseMoves$, documentMouseMoves$),
        parentWindow,
      ),
    );
  }

  // check if pointer is on correction card
  const mouseMoveOnCorrectionCard$ = correctionCardKeyFrom(
    elementFromPoint$,
    sign,
  );

  const firstVisibleCorrectionKey$ = config$.pipe(
    pluck('disableAutoPopup'),
    distinctUntilChanged(),
    switchMap(disableAutoPopup =>
      disableAutoPopup
        ? of(null)
        : getFirstVisibleCorrectionFrom(
            cStyles$,
            targetRectPosition$,
            targetScroll$,
            scrollParent$,
            scrollParentRectPosition$,
            scrollParentScroll$,
            originalTarget,
            parentWindow,
          ),
    ),
  );

  const highlightedCorrectionKey$ = highlightedCorrectionKey(
    highlightedCorrectionUnderline$,
    mouseMoveOnCorrectionCard$,
    firstVisibleCorrectionKey$,
  );

  const mouseOverCorrectionOrCard$ = mouseOverCorrectionOrCard(
    cStyles$,
    highlightedCorrectionKey$,
  );

  const cStyleWithHighlights$ = enhanceCorrectionWithHighlights(
    cStyles$,
    highlightedCorrectionKey$,
  );

  const correctionCardProp$ = correctionCardPropsFrom(
    mouseOverCorrectionOrCard$,
    assets$,
    targetAbsolutePosition$,
    targetScroll$,
    scrollParentOffsetToTarget$,
    scrollParentScroll$,
  );

  // insert watermark and correction as sibling
  const watermarkContainerDiv = createContainerDiv(parentWindow);
  insertAfter(watermarkContainerDiv, originalTarget);

  renderWatermarkContainer(
    sign,
    watermarkContainerDiv,
    correctionContainerPos$,
    relativeOffset$,
    scrollParentScroll$,
    targetZIndex$,
    scrollDimension$,
    scrollParent$,
    scrollTargetAbsolutePosition$,
    scrollParentRelativeOffset$,
    parentWindow,
  );

  const correctionContainerDiv = createContainerDiv(parentWindow);
  insertAfter(correctionContainerDiv, originalTarget);

  renderCorrectionContainer(
    sign,
    correctionContainerDiv,
    targetScroll$,
    relativeOffset$,
    scrollDimension$,
    scrollWithNonPositionedScrollParent$,
    correctionContainerPos$,
    targetZIndex$,
    scrollParent$,
    scrollTargetAbsolutePosition$,
    scrollParentRelativeOffset$,
    parentWindow,
  );

  const correctionContainer = correctionContainerDiv.firstElementChild!
    .firstElementChild!.firstElementChild as HTMLDivElement;
  const watermarkContainer = watermarkContainerDiv.firstElementChild!
    .firstElementChild!.firstElementChild as HTMLDivElement;

  // Lets render our stuff
  const correctionUnderlinesEl = parentWindow.document.createElement('div');
  const watermarkEl = parentWindow.document.createElement('div');
  const correctionCardEl = parentWindow.document.createElement('div');
  correctionUnderlinesEl.dataset.cy = `pt-correction-underlines-container-${sign}`;
  watermarkEl.dataset.cy = `pt-watermark-container-${sign}`;
  correctionCardEl.dataset.cy = `pt-correction-card-container-${sign}`;
  correctionCardEl.className = 'perfecttense-correction-card-container';

  correctionContainer.appendChild(correctionUnderlinesEl);
  watermarkContainer.appendChild(watermarkEl);

  // insert correction card as direct child of body and set very high z index
  parentWindow.document.body.appendChild(correctionCardEl);
  renderCorrectionUnderlines(
    correctionUnderlinesEl,
    sign,
    cStyleWithHighlights$,
  );

  const showLoadingWatermark$ = isExpectingResponse(textStateMap$);

  renderWatermark(
    sign,
    watermarkEl,
    watermarkPosition$,
    showLoadingWatermark$,
    targetRectPosition$.pipe(
      distinctUntilChanged(
        (a, b) => a.height === b.height && a.width === b.width,
      ),
      map(pos => ({ height: pos.height, width: pos.width })),
    ),
    config$.pipe(
      distinctUntilKeyChanged('linkUrl'),
      pluck('linkUrl'),
    ),
  );
  renderCorrectionCard(
    correctionCardEl,
    correctionCardProp$,
    acceptHandler,
    ignoreHandler,
  );

  // tslint:disable-next-line: no-console
  console.log(`Sign initiated: ${sign}`);
  // Subscribe to imp things
  const grammarScoreSubscription = grammarScore$.subscribe({
    next: (score: number) => {
      if (textareaTarget) {
        grammarScoreCallback(score, textareaTarget);
      } else {
        grammarScoreCallback(score, target);
      }
    },
  });
  const jobSubscription = job$.subscribe({
    next: (job: Job) => {
      socketSubject.sendJob(job);
    },
  });
  const feedbackSubscription = feedback$.subscribe({
    next: (feedbackParams: IFeedbackParams) => {
      socketSubject.sendFeedback(feedbackParams);
    },
  });
  const textStateMapSubscription = textStateMap$.connect();
  const cStyleSubscription = cStyles$.connect();
  const acceptSubscription = accept$.subscribe({
    next: ({ correctionRange, replacementText }) => {
      if (textareaTarget !== null) {
        const {
          endOffsetForTextContent,
          startOffsetForTextContent,
        } = correctionRange;
        const oldValue = textareaTarget.value;
        const pre = oldValue.slice(0, startOffsetForTextContent);
        const suf = oldValue.slice(endOffsetForTextContent);
        const newValue = pre + replacementText + suf;
        textareaTarget.value = newValue;
        if (textareaTarget.innerHTML.length > 0) {
          textareaTarget.innerHTML = newValue;
        }
        let event;
        if (typeof Event === 'function') {
          event = new Event('input', { bubbles: true });
        } else {
          event = document.createEvent('Event');
          event.initEvent('input', true, true);
        }
        textareaTarget.dispatchEvent(event);
      } else {
        replaceContentForCorrection(
          inputTarget,
          correctionRange,
          replacementText,
          parentWindow,
        );
      }
      // bodyMutations.next(inputTarget);
    },
  });
  const teardown = () => {
    // unsubscribe observable subscriptions
    acceptSubscription.unsubscribe();
    cStyleSubscription.unsubscribe();
    textStateMapSubscription.unsubscribe();
    feedbackSubscription.unsubscribe();
    jobSubscription.unsubscribe();
    grammarScoreSubscription.unsubscribe();
    // additional teardown passed from caller
    additionalTeardown();
    // unmount react components
    unmountComponentAtNode(correctionCardEl);
    unmountComponentAtNode(watermarkEl);
    unmountComponentAtNode(correctionUnderlinesEl);
    unmountComponentAtNode(correctionContainerDiv);
    unmountComponentAtNode(watermarkContainerDiv);
    // remove component container elements from DOM
    correctionCardEl.remove();
    watermarkEl.remove();
    correctionUnderlinesEl.remove();
    watermarkContainerDiv.remove();
    correctionContainerDiv.remove();
    mutationObserver.disconnect();
    // delete data attributes from target element
    delete target.dataset.ptEditor;
    delete target.dataset.gramm_editor;
    delete target.dataset.cy;
    delete target.dataset.pt;
    target.removeAttribute('spellcheck');
    // remove from our map of active editables
    ActiveEditables.removeEditable(sign);
  };
  teardown$.pipe(take(1)).subscribe({
    next: teardown,
  });
  // tell server for tracking usage
  socketSubject.createEditablesInMongoFor(sign);
};
