import { unmountComponentAtNode } from 'react-dom';
import { BehaviorSubject, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { bootstrapEditable } from '.';
import { TEXTAREA_ATTRIBUTES } from '../constants';
import {
  inputFromTextarea,
  pluckStylesFrom,
  stylesheetFor,
  targetAbsolutePositionFrom,
  targetScrollFor,
  WebsocketSubject,
} from '../observables';
import { renderTextareaMirror } from '../renderers';
import { IBodyScroll, IMouseMoveWithEl } from '../types';
import { noop } from '../utils';

export const bootstrapTextarea = (
  documentMutations$: Observable<MutationRecord[]>,
  bodyScroll$: Observable<IBodyScroll>,
  socketSubject: WebsocketSubject,
  target: HTMLTextAreaElement,
  sign: string,
  documentMouseMoves$: Observable<IMouseMoveWithEl>,
  elementFromPoint$: Observable<Element | null>,
  grammarScoreCallback: (score: number, target: HTMLElement) => void = noop,
  contentWindow: Window = window,
) => {
  target.dataset.ptEditor = 'true';
  target.dataset.gramm_editor = 'false';
  target.setAttribute('spellcheck', 'false');
  target.dataset.cy = `pt-target-${sign}`;
  target.dataset.pt = `pt-target-${sign}`;
  const bodyMutations = new BehaviorSubject<HTMLTextAreaElement>(target);
  const mutationObserver = new MutationObserver(_ => {
    bodyMutations.next(target);
  });
  mutationObserver.observe(target, {
    attributes: true,
  });

  const style$ = stylesheetFor(
    contentWindow.getComputedStyle(target),
    documentMutations$.pipe(mapTo(target)),
    contentWindow,
  );
  const pluckedStyles$ = pluckStylesFrom(style$, TEXTAREA_ATTRIBUTES);
  const targetAbsolutePosition$ = targetAbsolutePositionFrom(
    target,
    style$,
    bodyScroll$,
    contentWindow,
  );

  const text$ = inputFromTextarea(target, documentMutations$);
  const targetScroll$ = targetScrollFor(target);

  // Lets render our stuff
  const mirrorEl = contentWindow.document.createElement('div');
  mirrorEl.className = 'perfecttense-mirror-el';
  mirrorEl.dataset.cy = `pt-mirror-container-${sign}`;
  contentWindow.document.body.appendChild(mirrorEl);

  renderTextareaMirror(
    mirrorEl,
    sign,
    targetAbsolutePosition$,
    targetScroll$,
    text$,
    pluckedStyles$,
  );

  const teardown = () => {
    unmountComponentAtNode(mirrorEl);
    mirrorEl.remove();
    mutationObserver.disconnect();
    delete target.dataset.ptEditor;
    delete target.dataset.gramm_editor;
    delete target.dataset.cy;
    delete target.dataset.pt;
    target.removeAttribute('spellcheck');
  };

  bootstrapEditable(
    documentMutations$,
    bodyScroll$,
    socketSubject,
    mirrorEl.firstChild as HTMLDivElement,
    sign,
    documentMouseMoves$,
    elementFromPoint$,
    teardown,
    target,
    grammarScoreCallback,
    contentWindow,
  );
};
