const deltaIsRetainAndDeleteOnly = delta => {
  const { ops } = delta;
  return (
    ops.length === 2 &&
    ops[0].retain &&
    ops[0].retain > 0 &&
    ops[1].delete &&
    ops[1].delete === 1
  );
};

export const checkIsPasteFromDelta = (delta, prevDelta = undefined) => {
  const { ops } = delta;
  if (prevDelta) {
    // for telerik, after we paste telerik automatically remove the new line it adds before
    return (
      checkIsPasteFromDelta(delta) ||
      (checkIsPasteFromDelta(prevDelta) && deltaIsRetainAndDeleteOnly(delta))
    );
  } else {
    return (
      ops.reduce((acc, op) => acc + (op.insert ? op.insert.length : 0), 0) > 1
    );
  }
};
