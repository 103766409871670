import Delta from 'quill-delta';
import { Observable, OperatorFunction, pipe } from 'rxjs';
import { scan, withLatestFrom } from 'rxjs/operators';
import { ITextStateMap, TextStateUpdater } from '../../types';
import { Sentence } from '../sentence';
import { TextState } from '../textState';
import { checkIsPasteFromDelta } from './checkIsPasteFromDelta';
import { Config } from './configSchema';

const initialTextState = new TextState({ score: 100 });

export const updateTextState = (
  config$: Observable<Config>,
): OperatorFunction<TextStateUpdater, ITextStateMap> =>
  pipe(
    withLatestFrom(config$),
    scan(
      (
        textStateMap: ITextStateMap,
        [delta, config]: [TextStateUpdater, Config],
      ) => {
        const {
          debounceTime,
          debounceTimeOnPaste,
          debounceTimeOnAccept,
        } = config;
        const { textState, prevDiff } = textStateMap;
        const timestamp = Date.now();
        if ('input' in delta) {
          const { input } = delta;
          if (input !== textState.text) {
            const oldDelta = new Delta().insert(textState.text);
            const newDelta = new Delta().insert(input);
            const diff = oldDelta.diff(newDelta);
            const updatedTextState = textState.applyDelta(diff, timestamp);
            return {
              debounceTime: checkIsPasteFromDelta(diff, prevDiff)
                ? debounceTimeOnPaste
                : debounceTime,
              feedbackParams: null,
              prevDiff: diff,
              textState: updatedTextState,
            };
          }
        } else if ('diff' in delta) {
          const updatedTextState = textState.attachDiff(delta.diff);
          return {
            debounceTime,
            feedbackParams: null,
            prevDiff: null,
            textState: updatedTextState,
          };
        } else if ('responses' in delta) {
          // delta.response is an array of response
          const updatedTextState = textState.applyResponses(
            delta.responses,
            timestamp,
          );
          return {
            debounceTime,
            feedbackParams: null,
            prevDiff: null,
            textState: updatedTextState,
          };
        } else if ('accept' in delta) {
          const result = textState.accept(
            delta.accept,
            timestamp,
            delta.propSentenceIndex,
          );
          return {
            debounceTime: debounceTimeOnAccept,
            prevDiff: null,
            ...result,
          };
        } else if ('ignore' in delta) {
          const result = textState.ignore(
            delta.ignore,
            timestamp,
            delta.propSentenceIndex,
          );
          return { debounceTime, prevDiff: null, ...result };
        } else if ('sentJob' in delta) {
          const job = delta.sentJob;
          const { jobMap, sentences } = textState;
          return {
            debounceTime,
            feedbackParams: null,
            prevDiff: null,
            textState: textState.merge({
              jobMap: jobMap.set(job.key, job),
              sentences: sentences.map((s: Sentence) =>
                s.set('changed', false),
              ),
            }),
          };
        } else if ('mongoId' in delta) {
          const { key, mongoId } = delta;
          const updatedTextState = textState.setIn(
            ['jobMap', key, 'mongoId'],
            mongoId,
          );
          return {
            debounceTime,
            feedbackParams: null,
            prevDiff: null,
            textState: updatedTextState,
          };
        }
        return {
          debounceTime,
          feedbackParams: null,
          prevDiff: null,
          textState,
        };
      },
      {
        debounceTime: new Config().debounceTime,
        feedbackParams: null,
        prevDiff: null,
        textState: initialTextState,
      },
    ),
  );
