import { List, Map, Record, Set } from 'immutable';
import Delta from 'quill-delta';
import { Correction, Job, Token } from '..';
import { ICorrection, ICorrectionResponse } from '../../types';
import {
  correctionKey,
  parseCorrectionKey,
  parseTransformationKey,
  tokenKey,
} from '../keys';
import { logger } from '../log';

const sentenceConsole = logger(false);

export interface ISentenceParams {
  id: number;
  tokens: List<Token>;
  text: string;
  corrections: Map<string, Correction>;
  tokenIndexMap: Map<number, number>;
  offsetInTextState: number;
  offsetInTextStateWithoutNewline: number;
  score: number;
  timestamp: number;
  changed: boolean;
}

/**
 * todo summarize class
 */
export class Sentence extends Record<ISentenceParams>(
  {
    changed: false, // Boolean
    corrections: Map(), // Map<String, Correction>
    id: 0, // Int
    offsetInTextState: 0, // Int (character offset)
    offsetInTextStateWithoutNewline: 0,
    score: 100, // Number
    text: 'DEFAULT_SENTENCE_TEXT', // String
    timestamp: 0,
    tokenIndexMap: Map(), // token id -> token index
    tokens: List(), // List<Token>
  },
  'Sentence',
) {
  private static nextId = 1;
  constructor(args: Partial<ISentenceParams> = {}) {
    let tokenList = List();
    if (args.tokens) {
      tokenList = args.tokens.reduce(
        ({ acc, offset, offsetWithoutNewline }, t) => ({
          acc:
            t.text.length > 0
              ? acc.push(
                  t.merge({
                    offsetInSentence: offset,
                    offsetInSentenceWithoutNewline: offsetWithoutNewline,
                  }),
                )
              : acc,
          offset: offset + t.text.length,
          offsetWithoutNewline:
            offsetWithoutNewline + t.text.replace(/\r?\n|\r/g, '').length,
        }),
        { acc: List(), offset: 0, offsetWithoutNewline: 0 },
      ).acc;
    }
    if (args.id) {
      Sentence.nextId = Math.max(args.id, Sentence.nextId);
    }
    super({
      ...args, // fix token offsets:
      id: args.id || Sentence.nextId++,
      score: args.corrections
        ? args.corrections.reduce((acc, c) => acc - c.penalty, 100)
        : 100,
      text: tokenList.reduce((acc, t) => acc + t.text, ''),
      tokenIndexMap: !tokenList.isEmpty()
        ? Map(tokenList.map((t, i) => [t.id, i]))
        : Map(),
      tokens: tokenList,
    });
  }
  /**
   * Applies a Delta to the Sentence
   * @param {Delta} delta
   * @param {string} timestamp
   * @param {number} sentOffset TODO
   * @param {number} sentOffsetWithoutNewline
   * @param {boolean} isLastSentence
   * @return {Object}
   */
  public applyDelta(
    delta: Delta | null,
    timestamp: number,
    sentOffset: number,
    sentOffsetWithoutNewline: number,
    isLastSentence: boolean = false,
  ): {
    delta: Delta | null;
    sentence: Sentence | null;
    sentenceChanged: boolean;
    tokenSentenceMapDiff: Map<number, number>;
    removedCorrections: Set<string>;
  } {
    if (!delta || !delta.ops || 0 === delta.ops.length) {
      return {
        delta: null,
        removedCorrections: Set<string>(),
        sentence: this.merge({
          offsetInTextState: sentOffset,
          offsetInTextStateWithoutNewline: sentOffsetWithoutNewline,
        }),
        sentenceChanged: false,
        tokenSentenceMapDiff: Map(),
      };
    }
    const { tokens, corrections, tokenIndexMap } = this;
    // build up output tokens as we iterate thru
    let outputTokens = List();
    // "" for corrections
    let outputCorrections = corrections;
    let outputTokenIndexMap = tokenIndexMap;
    let tokenSentenceMapDiff = Map<number, number>();
    let removedCorrections = Set<string>();
    let outputChanged = this.changed;
    // current delta; each step it's updated to the returned remainder delta
    let curDelta: Delta | null = delta;
    // current character offset in the sentence
    let offset = 0; // sentOffset;
    let offsetWithoutNewline = 0;
    let i = 0;
    let inTokens = tokens;
    if (tokens.isEmpty()) {
      inTokens = List.of(
        new Token({
          after: '',
          correction: null,
          ignored: null,
          offsetInSentence: 0,
          offsetInSentenceWithoutNewline: 0,
          value: '',
        }),
      );
    }
    let prevCorrection = null;
    let prevIgnored = null;
    const inputTokensCount = inTokens.count();
    for (const [index, curToken] of inTokens.entries()) {
      const {
        delta: newDelta,
        token: newToken,
        tokenChanged,
        correctionsDiff,
      } = curToken.applyDelta(
        curDelta,
        offset,
        offsetWithoutNewline,
        prevCorrection,
        prevIgnored,
        isLastSentence && inputTokensCount - 1 === index,
      ); // last param isLastTokenInLastSentence, so we apply any remain delta to the token
      if (tokenChanged) {
        sentenceConsole.log('token is changed');
        outputChanged = true;
        prevCorrection = curToken.correction;
        prevIgnored = curToken.ignored;
        // no longer corresponds to a job-sent token; remove job-relative keys from token id map
        // its corrections are no longer valid without all their tokensAffected, so remove them
        outputCorrections = correctionsDiff.reduce(
          (acc, correction, cKey) => (correction ? acc : acc.delete(cKey)),
          outputCorrections,
        );
        removedCorrections = removedCorrections.union(correctionsDiff.keySeq());
      } else {
        prevCorrection = null;
        prevIgnored = null;
      }

      if (newToken) {
        if (newToken.text.length === 0) {
          // empty token
          tokenSentenceMapDiff = tokenSentenceMapDiff.set(newToken.id, 0);
          outputTokenIndexMap = outputTokenIndexMap.delete(newToken.id);
        } else {
          // if token was not deleted, add it to the output list
          outputTokens = outputTokens.push(newToken);
          if (
            newToken.offsetInSentence !== curToken.offsetInSentence ||
            newToken.offsetInSentenceWithoutNewline !==
              curToken.offsetInSentenceWithoutNewline
          ) {
            outputTokenIndexMap = outputTokenIndexMap.set(newToken.id, i);
          }
          offset += newToken.text.length;
          offsetWithoutNewline += newToken.text.replace(/\r?\n|\r/g, '').length;
          i++;
        }
      } else {
        sentenceConsole.log('there is no new token');
        // if token was deleted, add it to collection of deleted tokens
        tokenSentenceMapDiff = tokenSentenceMapDiff.set(curToken.id, 0);
        outputTokenIndexMap = outputTokenIndexMap.delete(curToken.id);
      }
      // continue with remainder delta
      curDelta = newDelta;
    } // for

    // handle any remaining delta
    if (isLastSentence && curDelta && curDelta.ops && curDelta.ops[0].insert) {
      sentenceConsole.log(
        'all tokens in last sentence covered, yet delta of insert remaining, creating new token',
        curDelta.ops,
      );
      // should only have one insert.
      const lastNewToken = new Token({
        after: '',
        correction: null,
        ignored: null,
        offsetInSentence: offset,
        offsetInSentenceWithoutNewline: offsetWithoutNewline,
        value: (curDelta.ops.pop() as { insert: string }).insert,
      });
      outputTokens = outputTokens.push(lastNewToken);
      outputTokenIndexMap = outputTokenIndexMap.set(lastNewToken.id, i);
      tokenSentenceMapDiff = tokenSentenceMapDiff.set(lastNewToken.id, this.id);
      offset += lastNewToken.text.length;
      offsetWithoutNewline += lastNewToken.text.replace(/\r?\n|\r/g, '').length;
      i++;
      outputChanged = true;
    }

    curDelta =
      curDelta && curDelta.ops && curDelta.ops.length > 0 ? curDelta : null;

    if (
      outputTokens.count() === 0 ||
      (outputTokens.count() === 1 && outputTokens.first().text === '')
    ) {
      outputTokens = List();
      return {
        delta: curDelta,
        removedCorrections,
        sentence: null,
        sentenceChanged: true,
        tokenSentenceMapDiff,
      };
    }
    const outputSentenceText = outputTokens.reduce(
      (acc, t) => acc + t.text,
      '',
    );

    outputCorrections = outputCorrections.filter((v, k) =>
      outputTokens.some(t => t.correction === k),
    );

    const outputSentence = new Sentence({
      ...this.toObject(),
      changed: outputChanged,
      corrections: outputCorrections,
      offsetInTextState: sentOffset,
      offsetInTextStateWithoutNewline: sentOffsetWithoutNewline,
      score: outputCorrections.reduce((acc, corr) => acc - corr.penalty, 100),
      text: outputSentenceText,
      timestamp: outputChanged ? timestamp : this.timestamp,
      tokenIndexMap: outputTokenIndexMap,
      tokens: outputTokens,
    });

    return {
      delta: curDelta,
      removedCorrections,
      sentence: outputSentence,
      sentenceChanged: outputChanged,
      tokenSentenceMapDiff,
    };
  } // applyDelta()

  /**
   * Applies a corrections response to the sentence
   *
   * @param {Object} response
   * @param {Job} job
   * @param {Map} tokenIdMap
   * @param {Number} jobSentenceIndex
   * @returns {Object}
   */
  public applyCorrections(
    response: ICorrectionResponse,
    timestamp: number,
    job: Job,
    tokenIdMap: Map<string, number>,
    jobSentenceIndex: number,
  ): {
    updatedSentence: Sentence;
    tokenIdMapDiff: Map<string, number>;
    tokenSentenceMapDiff: Map<number, number>;
    correctionMapDiff: Map<string, number>;
  } {
    sentenceConsole.log('applyCorrections', response);
    const { corrections: correctionsRaw, currentTokens } = response;
    const corrections = correctionsRaw
      .map(
        (c, i) =>
          new Correction({
            ...c,
            key: correctionKey({
              correctionIndex: i,
              jobKey: job.key,
              sentenceIndex: jobSentenceIndex,
            }),
          }),
      )
      .filter(c => c.applicable); // TODO: we are skipping chained correction for now.
    let newCorrections = Map<string, Correction>(); // <- before we initiated with this.corrections, but then the previous corrections were kept.
    let tokenIdMapDiff = Map<string, number>();
    let tokenSentenceMapDiff = Map<number, number>();
    let updatedTokenIndexMap = Map<number, number>(); // <- before we initiated with this.tokenIndexMap, but then the previous tokens were kept.
    let correctionMapDiff = Map<string, number>();
    let offsetInSentence = 0;
    let offsetInSentenceWithoutNewline = 0;
    let newTokens = currentTokens.reduce((acc, t, index) => {
      const newToken = new Token({
        after: t.after,
        offsetInSentence,
        offsetInSentenceWithoutNewline,
        value: t.value,
      });
      offsetInSentence += newToken.text.length;
      offsetInSentenceWithoutNewline += newToken.text.replace(/\r?\n|\r/g, '')
        .length;
      tokenIdMapDiff = tokenIdMapDiff.set(
        tokenKey({
          jobKey: job.key,
          sentenceIndex: jobSentenceIndex,
          tokenIndex: index,
        }),
        newToken.id,
      );
      tokenSentenceMapDiff = tokenSentenceMapDiff.set(newToken.id, this.id);
      updatedTokenIndexMap = updatedTokenIndexMap.set(newToken.id, index);
      return acc.push(newToken);
    }, List<Token>());

    // Copy ignored corrections info from old tokens to new
    let oldTokenIndex = 0;
    let newTokenIndex = 0;
    while (
      oldTokenIndex < this.tokens.count() &&
      newTokenIndex < newTokens.count()
    ) {
      const oldToken = this.tokens.get(oldTokenIndex) as Token; // safe cast because of while condition
      if (!oldToken.ignored) {
        ++oldTokenIndex;
      } else {
        const oldTokenStart = oldToken.offsetInSentence;
        const oldTokenEnd = oldTokenStart + oldToken.text.length;
        const newToken = newTokens.get(newTokenIndex) as Token; // safe case because of while condition
        const newTokenStart = newToken.offsetInSentence;
        const newTokenEnd = newTokenStart + newToken.text.length;
        if (newTokenEnd < oldTokenStart) {
          ++newTokenIndex;
        } else if (oldTokenEnd < newTokenStart) {
          ++oldTokenIndex;
        } else {
          // overlap - copy old ignored to new token
          newTokens = newTokens.setIn(
            [newTokenIndex, 'ignored'],
            oldToken.ignored,
          );
          if (newTokenEnd < oldTokenEnd) {
            ++newTokenIndex;
          } else if (oldTokenEnd < newTokenEnd) {
            ++oldTokenIndex;
          } else {
            ++newTokenIndex;
            ++oldTokenIndex;
          }
        }
      }
    }
    for (const correction of corrections) {
      const key = correction.key;
      let affected = Set<Map<string, number | string | Correction>>();
      for (const token of correction.tokensAffected) {
        const theTokenKey = tokenKey({
          jobKey: job.key,
          sentenceIndex: jobSentenceIndex,
          tokenIndex: currentTokens.findIndex(t => t.id === token.id),
        });
        const tokenId =
          tokenIdMapDiff.get(theTokenKey) || tokenIdMap.get(theTokenKey) || 0;
        const tokenIndex = updatedTokenIndexMap.get(tokenId, -1);
        sentenceConsole.log({ theTokenKey, tokenId, tokenIndex });
        if (-1 === tokenIndex) {
          sentenceConsole.log('!!!TOKEN INDEX NOT FOUND!!!', { tokenId });
          affected = Set();
          break;
        }
        const prevIgnored = newTokens.getIn([tokenIndex, 'ignored']);
        if (prevIgnored && prevIgnored.basicInfo.equals(correction.basicInfo)) {
          sentenceConsole.log('ignored');
          newTokens.setIn([tokenIndex, 'ignored'], correction);
          affected = Set();
          break;
        }
        sentenceConsole.log('correction key:', key);
        const prevCorrection = newTokens.getIn([tokenIndex, 'correction']);
        if (
          prevCorrection &&
          parseCorrectionKey(prevCorrection).jobKey === job.key
        ) {
          // overlaps with a higher-penalty correction from the same job; don't apply
          sentenceConsole.log('overlap');
          affected = Set();
          break;
        } else {
          affected = affected.add(Map({ tokenIndex, key, correction }));
        }
      } // for token of tokensAffected
      for (const a of affected) {
        const tokenIndex = a.get('tokenIndex') as number; // TODO
        const affectedKey = a.get('key') as string; // TODO
        const affectedCorrection = a.get('correction') as Correction; // TODO
        // add correction key to affected token
        newTokens = newTokens.setIn([tokenIndex, 'correction'], affectedKey);
        newCorrections = newCorrections.set(affectedKey, affectedCorrection);
        correctionMapDiff = correctionMapDiff.set(affectedKey, this.id);
      }
    } // for i in 0...corrections.length
    // remove old corrections
    for (const { correction } of this.getRanges()) {
      if (correction) {
        correctionMapDiff = correctionMapDiff.set(correction, 0);
      }
    }
    const updatedSentence = new Sentence({
      ...this.toObject(),
      corrections: newCorrections,
      timestamp,
      tokens: newTokens,
    });
    return {
      correctionMapDiff,
      tokenIdMapDiff,
      tokenSentenceMapDiff,
      updatedSentence,
    };
  } // applyCorrections()

  /**
   * if sentence response is unchange, we update sentence by:
   * set score to 100, remove corrections, remove corrections in tokens, changed to false, timestamp
   * @param {Integer} timestamp
   * @return {Sentence}
   */
  public applyUnchangeResponse(timestamp: number) {
    const newTokens = this.tokens.map(token =>
      token.merge({ correction: null }),
    );
    return this.merge({
      changed: false,
      corrections: Map(),
      score: 100,
      timestamp,
      tokens: newTokens,
    });
  }

  /**
   *
   * @param {Sentence} existingSentence
   * @return {Sentence}
   */
  public mergeWithExistingSentence(existingSentence: Sentence | null) {
    if (existingSentence) {
      let mergedSentence = existingSentence;
      const mergedTokens = mergedSentence.tokens.concat(this.tokens);
      const mergedCorrections = mergedSentence.corrections.merge(
        this.corrections,
      );
      mergedSentence = mergedSentence
        .set('text', mergedSentence.text + this.text)
        .set('changed', true)
        .set('tokens', mergedTokens)
        .set('corrections', mergedCorrections);
      return new Sentence(mergedSentence.toObject());
    } else {
      return this;
    }
  }

  /**
   * Accepts a transformation
   * @this {Sentence}
   * @param {string} tKey the transformation key
   * @param {Map} tokenIdMap the enclosing TextState's tokenIdMap
   * @param {Job} job
   * @param {string} timestamp
   */
  public accept(
    tKey: string,
    tokenIdMap: Map<string, number>,
    timestamp: number,
  ): {
    updatedSentence: Sentence;
    tokenIdMapDiff: Map<string, number>;
    tokenSentenceMapDiff: Map<number, number>;
  } {
    const args = parseTransformationKey(tKey);
    const { jobKey, sentenceIndex, transformationIndex } = args;
    const cKey = correctionKey(args);
    const correction = this.corrections.get(cKey);
    if (!correction) {
      // TODO log
      return {
        tokenIdMapDiff: Map(),
        tokenSentenceMapDiff: Map(),
        updatedSentence: this,
      };
    }
    const transformation = correction.transformations[transformationIndex];
    const startIndex = this.tokens.findIndex(
      token => token.correction === cKey,
    );
    if (startIndex === -1) {
      sentenceConsole.log(
        '!!!!!!Cannot Accept. No Token With Given Correction Key',
      );
      return {
        tokenIdMapDiff: Map(),
        tokenSentenceMapDiff: Map(),
        updatedSentence: this,
      };
    }
    const affected = this.tokens.slice(
      startIndex,
      startIndex + transformation.tokensAffected.length,
    );
    const firstAffected = affected.first() as Token;
    const startOffset = firstAffected.offsetInSentence;
    const startOffsetWithoutNewline =
      firstAffected.offsetInSentenceWithoutNewline;
    const added = transformation.tokensAdded.reduce(
      (acc: List<Token>, { value, after }) => {
        const prev = acc.get(-1);
        return acc.push(
          new Token({
            after,
            offsetInSentence: prev
              ? prev.offsetInSentence + prev.text.length
              : startOffset,
            offsetInSentenceWithoutNewline: prev
              ? prev.offsetInSentenceWithoutNewline +
                prev.text.replace(/\r?\n|\r/g, '').length
              : startOffsetWithoutNewline,
            value,
          }),
        );
      },
      List<Token>(),
    );
    const pre = this.tokens.take(startIndex);
    const lastAdded = added.get(-1) as Token;
    const lastAffected = affected.get(-1) as Token;
    const offsetDiff =
      lastAdded.offsetInSentence +
      lastAdded.text.length -
      (lastAffected.offsetInSentence + lastAffected.text.length);
    const offsetDiffWithoutNewline =
      lastAdded.offsetInSentenceWithoutNewline +
      lastAdded.text.replace(/\r?\n|\r/g, '').length -
      (lastAffected.offsetInSentenceWithoutNewline +
        lastAffected.text.replace(/\r?\n|\r/g, '').length);
    const shifted = this.tokens
      .skip(startIndex + affected.count())
      .map(t =>
        t
          .update('offsetInSentence', offset => offset + offsetDiff)
          .update(
            'offsetInSentenceWithoutNewline',
            offsetWithoutNewline =>
              offsetWithoutNewline + offsetDiffWithoutNewline,
          ),
      );
    const newTokens = pre.concat(added, shifted);
    const updatedTokenIndexMap = newTokens.reduce(
      (newTokenIndexMap, token, index) => newTokenIndexMap.set(token.id, index),
      Map<number, number>(),
    );

    // TODO
    // tokenKey is not unique (before/after accept), so we need to regenerate through newTokens
    // e.g. tokenAffected and tokenAdded length different
    const tokenIdMapDiff = newTokens.reduce(
      (acc, token) =>
        acc.set(
          tokenKey({
            jobKey,
            sentenceIndex,
            tokenIndex: updatedTokenIndexMap.get(token.id),
          }),
          token.id,
        ),
      Map(
        Array.from(
          affected.map(t => [
            tokenKey({
              jobKey,
              sentenceIndex,
              tokenIndex: this.tokenIndexMap.get(t.id),
            }),
            0,
          ]),
        ),
      ).filter((v, k) => tokenIdMap.get(k) !== v),
    );
    const tokenSentenceMapDiff = Map<number, number>([
      ...added.map(t => [t.id, this.id]),
      ...affected.map(t => [t.id, 0]),
    ] as Array<[number, number]>);
    const updatedSentence = new Sentence({
      ...this.toObject(),
      changed: true,
      corrections: this.corrections.delete(cKey),
      timestamp,
      tokenIndexMap: updatedTokenIndexMap,
      tokens: newTokens,
    });
    return { updatedSentence, tokenIdMapDiff, tokenSentenceMapDiff };
  } // accept()

  /**
   *
   * @param {*} tKey
   * @param {*} tokenIdMap
   * @param {*} timestamp
   */
  public ignore(cKey: string, timestamp: number): Sentence {
    const correction = this.corrections.get(cKey);
    // correction.tokensAffected id is from backend response
    // we cannot find the affected token by id and update its correction directly
    // we need to traverse tokens and set correction to null if correction is cKey
    let { tokens, score, corrections } = this;
    if (correction) {
      // remove corrections from tokens
      tokens = this.tokens.map(token => {
        if (token.correction === cKey) {
          return token.merge({
            correction: null,
            ignored: correction,
          });
        }
        return token;
      });
      corrections = corrections.delete(cKey);
      score += correction.penalty; // add back penalties
    }
    const updatedSentence = this.merge({
      corrections,
      score,
      timestamp,
      tokens,
    });
    return updatedSentence;
  } // ignore()

  /**
   * @return {List} ...
   */
  public getRanges(): List<{
    text: string;
    correction: string | null;
    signature: string | null;
  }> {
    let ret = List();
    let p = this.tokens;
    let hasCorrection = false;
    let trailing = '';
    while (!p.isEmpty()) {
      const c = (p.first() as Token).correction;
      hasCorrection = !!c;
      const chunk = p.takeWhile(token => token.correction === c);
      const signature =
        hasCorrection && this.corrections.getIn([c, 'signature'], null);
      if (hasCorrection) {
        if (trailing.length > 0) {
          ret = ret.push({ text: trailing, correction: null, signature: null });
          trailing = '';
        }
        const chunkWithoutLast = chunk.skipLast(1);
        const lastToken = chunk.takeLast(1).first() as Token;
        const text = `${chunkWithoutLast.reduce(
          (acc, t) => `${acc}${t.text}`,
          '',
        )}${lastToken.value}`;
        trailing = lastToken.after;
        ret = ret.push({ text, correction: c, signature });
      } else {
        ret = ret.push({
          correction: null,
          signature: null,
          text: chunk.reduce((acc, t) => `${acc}${t.text}`, trailing),
        });
        trailing = '';
      }
      p = p.skip(chunk.count());
    }
    if (trailing.length > 0) {
      ret = ret.push({ text: trailing, correction: null, signature: null });
    }
    return ret;
  } // getRanges

  public get precedingNewLines() {
    const trimmedText = this.text.trimLeft();
    const noOfWhitespaces = this.text.length - trimmedText.length;
    const prefix = this.text.slice(0, noOfWhitespaces);
    return prefix
      .split('')
      .reduce((acc, char) => (char === '\n' ? acc + 1 : acc), 0);
  }

  public getCorrectionsWithOffsets(delta: Delta): ICorrection[] {
    const { corrections, offsetInTextState, tokens } = this;
    const correctionsWithOffsets: ICorrection[] = [];
    for (const [key, correction] of corrections.entries()) {
      const startToken = tokens.find(token => token.correction === key);
      if (!startToken) {
        continue;
      }
      const endToken = tokens.findLast(token => token.correction === key);
      if (!endToken) {
        continue;
      }
      const startOffset = startToken.offsetInSentence + offsetInTextState;
      const afterChanged = correction.transformations.some(
        t =>
          t.tokensAffected[t.tokensAffected.length - 1].after !==
          endToken.after,
      );
      const endOffset =
        offsetInTextState +
        endToken.offsetInSentence +
        endToken.value.length +
        (afterChanged ? endToken.after.length : 0);
      const so = delta.transformPosition(startOffset);
      const eo = delta.transformPosition(endOffset);
      correctionsWithOffsets.push({
        correction,
        endOffset: eo,
        endOffsetForTextContent: endOffset,
        startOffset: so,
        startOffsetForTextContent: startOffset,
      });
    } // for
    return correctionsWithOffsets.sort((a, b) => a.startOffset - b.startOffset);
  } // getCorrectionsWithOffsets()
} // class Sentence
