import { Observable, timer } from 'rxjs';
import { debounce, map, withLatestFrom } from 'rxjs/operators';

export const debouncer = <T>(
  source: Observable<T>,
  rate: Observable<number>,
) => {
  return source.pipe(
    withLatestFrom(rate),
    debounce(([_, ms]) => timer(ms)),
    map(([s, r]) => s),
  );
};
