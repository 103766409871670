import * as React from 'react';
import { Observable } from 'rxjs';
import { AcceptHandler, ICorrectionCardInfo, IgnoreHandler } from '../types';
import { CorrectionCard } from './CorrectionCard';

interface ICorrectionCardContainerProps {
  accept: AcceptHandler;
  correctionCardProp$: Observable<ICorrectionCardInfo | null>;
  ignore: IgnoreHandler;
}

export const CorrectionCardContainer = (
  props: ICorrectionCardContainerProps,
) => {
  const [
    correctionCardProp,
    setCorrectionCardProp,
  ] = React.useState<ICorrectionCardInfo | null>(null);
  React.useEffect(() => {
    const sub = props.correctionCardProp$.subscribe(newProps => {
      setCorrectionCardProp(newProps);
    });
    return () => sub.unsubscribe();
  }, []);
  if (!correctionCardProp) {
    return null;
  }
  const accept = (cKey: string, tIdx: number) => {
    props.accept(
      cKey,
      tIdx,
      correctionCardProp.correctionRange,
      correctionCardProp.options[tIdx],
    );
  };
  return (
    <CorrectionCard
      accept={accept}
      ignore={props.ignore}
      top={correctionCardProp.top}
      left={correctionCardProp.left}
      correctionKey={correctionCardProp.key}
      options={correctionCardProp.options}
    />
  );
};
