import * as React from 'react';
import { combineLatest, Observable, Subscription } from 'rxjs';

import { map } from 'rxjs/operators';
import {
  IDimension,
  IRelativeOffset,
  IScrollDimension,
  ITargetScroll,
  ZIndex,
} from '../types';

const { useEffect, useRef, useState } = React;

export interface IWatermarkContainerInterface {
  style: React.CSSProperties;
  correctionContainerPos$: Observable<IDimension>;
  relativeOffset$: Observable<IRelativeOffset>;
  scrollParentScroll$: Observable<ITargetScroll>;
  targetZIndex$: Observable<ZIndex>;
  scrollDimension$: Observable<IScrollDimension>;
  initScrollHeight: number;
  initScrollWidth: number;
}

export const WatermarkContainer = ({
  style,
  correctionContainerPos$,
  relativeOffset$,
  scrollParentScroll$,
  targetZIndex$,
  scrollDimension$,
  initScrollHeight,
  initScrollWidth,
}: IWatermarkContainerInterface) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [zIndex, setZIndex] = useState('auto' as ZIndex);
  const [scrollHeight, setScrollHeight] = useState(initScrollHeight);
  const [scrollWidth, setScrollWidth] = useState(initScrollWidth);
  useEffect(() => {
    const sub = targetZIndex$.subscribe(z => {
      setZIndex(z);
    });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    const sub = correctionContainerPos$.subscribe(pos => {
      // need to set container size big enough so we can see 100% watermark even for a small textarea, hovered
      // before it's pos.height + 50
      setHeight(Math.max(pos.height, 130));
      setWidth(Math.max(pos.width, 130));
    });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    const sub = combineLatest(relativeOffset$, scrollParentScroll$)
      .pipe(
        map(([offset, { targetScrollX, targetScrollY }]) => {
          return {
            left: offset.left, // + targetScrollX,
            top: offset.top, // + targetScrollY,
          };
        }),
      )
      .subscribe(offset => {
        // before it's offset.top - 50
        setLeft(offset.left);
        setTop(offset.top);
      });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    const sub = scrollDimension$.subscribe(scrollDimension => {
      if (scrollHeight < scrollDimension.scrollHeight) {
        setScrollHeight(scrollDimension.scrollHeight);
      }
      if (scrollWidth < scrollDimension.scrollWidth) {
        setScrollWidth(scrollDimension.scrollWidth);
      }
    });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    let sub: Subscription;
    sub = scrollParentScroll$.subscribe(
      ({
        targetScrollX: scrollParentScrollX,
        targetScrollY: scrollParentScrollY,
      }) => {
        ref.current!.scrollLeft = scrollParentScrollX;
        ref.current!.scrollTop = scrollParentScrollY;
      },
    );
    return () => sub.unsubscribe();
  }, []);
  // out div is to fix the expanded editor area bug caused by our larger watermark container
  return (
    <div ref={ref} style={{ ...style, height, left, top, width, zIndex }}>
      <div style={{ position: 'absolute', left: 0, top: 0 }}>
        <div
          style={{
            height: scrollHeight * 2,
            width: scrollWidth * 2,
          }}
        />
      </div>
    </div>
  );
};
