import { combineLatest, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import {
  IScrollParent,
  ITargetScroll,
  ITextStateMap,
  IWatermarkPadding,
  IWatermarkPosition,
} from '../types';

// offset x and y for watermark towards shadow div 0,0 when there is scroll parent
// the x and y is the transformX and Y in watermark css
export const watermarkPositionFromTargetPositionWithScrollTargetOffset = (
  targetAbsPosition$: Observable<ClientRect | DOMRect>,
  scrollParentScroll$: Observable<ITargetScroll>,
  targetPadding$: Observable<IWatermarkPadding>,
  scrollTargetAbsolutePosition$: Observable<ClientRect | DOMRect>,
  scrollParent: HTMLElement,
) => {
  const watermarkOffsetX = 0;
  const watermarkOffsetY = 0;
  return combineLatest(
    targetAbsPosition$,
    targetPadding$,
    scrollParentScroll$,
    scrollTargetAbsolutePosition$,
  ).pipe(
    map(
      ([
        { height, left: targetAbsLeft, top: targetAbsTop, width },
        { paddingBottom, paddingRight },
        { targetScrollX, targetScrollY },
        { left: scrollTargetAbsLeft, top: scrollTargetAbsTop },
      ]) => {
        // We exclude padding for site like boa customer service feedback page
        // who puts a higher z-index element at the position of padding
        let x =
          width -
          watermarkOffsetX -
          paddingRight +
          targetScrollX +
          targetAbsLeft -
          scrollTargetAbsLeft +
          scrollParent.scrollLeft;
        if (x < 0) {
          x = Math.max((width - 33) / 2, 0);
        }
        let y =
          height -
          watermarkOffsetY -
          paddingBottom +
          targetScrollY +
          targetAbsTop -
          scrollTargetAbsTop +
          scrollParent.scrollTop;
        if (y < 0) {
          y = Math.max((height - 33) / 2, 0);
        }

        return { x, y };
      },
    ),
    distinctUntilChanged((a, b) => a.x === b.x && a.y === b.y),
  );
};

// offset x and y for watermark towards shadow div 0,0 when there is no scroll parent
// the x and y is the transformX and Y in watermark css
export const watermarkPositionFromTargetPosition = (
  targetAbsPosition$: Observable<ClientRect | DOMRect>,
  scrollParentScroll$: Observable<ITargetScroll>,
  targetPadding$: Observable<IWatermarkPadding>,
): Observable<IWatermarkPosition> => {
  const watermarkOffsetX = 0;
  const watermarkOffsetY = 0;
  return combineLatest(
    targetAbsPosition$,
    targetPadding$,
    scrollParentScroll$,
  ).pipe(
    map(
      ([
        { height, width },
        { paddingBottom, paddingRight },
        { targetScrollX, targetScrollY },
      ]) => {
        // We exclude padding for site like boa customer service feedback page
        // who puts a higher z-index element at the position of padding
        let x = width - watermarkOffsetX - paddingRight + targetScrollX;
        if (x < 0) {
          x = Math.max((width - 33) / 2, 0);
        }
        let y = height - watermarkOffsetY - paddingBottom + targetScrollY;
        if (y < 0) {
          y = Math.max((height - 33) / 2, 0);
        }

        return { x, y };
      },
    ),
    distinctUntilChanged((a, b) => a.x === b.x && a.y === b.y),
  );
};

// offset x and y for watermark towards shadow div 0,0
// the x and y is the transformX and Y in watermark css
export const getRefinedWatermarkPosition = (
  scrollParent$: Observable<IScrollParent>,
  scrollTargetAbsolutePosition$: Observable<ClientRect | DOMRect>,
  targetAbsolutePosition$: Observable<ClientRect | DOMRect>,
  targetPadding$: Observable<IWatermarkPadding>,
): Observable<IWatermarkPosition> => {
  return scrollParent$.pipe(
    distinctUntilChanged(
      (a, b) =>
        a.scrollParent === b.scrollParent &&
        a.hasScrollParent === b.hasScrollParent &&
        a.position === b.position,
    ),
    switchMap(({ hasScrollParent, scrollParent }) => {
      if (hasScrollParent && scrollParent) {
        return watermarkPositionFromTargetPositionWithScrollTargetOffset(
          targetAbsolutePosition$,
          of({ targetScrollX: 0, targetScrollY: 0 }),
          targetPadding$,
          scrollTargetAbsolutePosition$,
          scrollParent,
        );
      }
      return watermarkPositionFromTargetPosition(
        targetAbsolutePosition$,
        of({ targetScrollX: 0, targetScrollY: 0 }),
        targetPadding$,
      );
    }),
  );
};

export const isExpectingResponse = (textStateMap$: Observable<ITextStateMap>) =>
  textStateMap$.pipe(
    map(textStateMap => {
      const sentenceIndexMap = textStateMap.textState.sentenceIndexMap;
      const jobMap = textStateMap.textState.jobMap.filter(
        job =>
          !job.tokenizedSentenceIds.some(
            id => sentenceIndexMap.get(id) === undefined,
          ),
      );
      const sortedJob = jobMap
        .valueSeq()
        .sortBy(j => -j.timestamp)
        .toArray();
      if (sortedJob.length === 0) {
        return false;
      }
      if (sortedJob[0].longText) {
        return true;
      }
      for (const job of sortedJob) {
        if (job.timestamp < Date.now() - 10000) {
          break;
        }
        if (
          job.tokenizedResponse === null ||
          job.sentenceResponses.size < job.tokenizedSentenceIds.size
        ) {
          return true;
        }
      }
      return false;
    }),
    startWith(false),
    distinctUntilChanged((a, b) => a === b),
  );
