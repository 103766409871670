import { BehaviorSubject, EMPTY, from, merge, Observable } from 'rxjs';
import { filter, mergeMap, startWith } from 'rxjs/operators';
import { bootstrapEditable, bootstrapTextarea } from '.';
import {
  elementFromPoint,
  mouseMoveFromDoc,
  newlyAddedFocusedIframeEditables,
  newlyAddedIframeEditables,
  newlyAddedNonIframeEditables,
  newlyFocusedIframeEditables,
  newlyFocusedNonIframeEditables,
  scrollFromBody,
  WebsocketSubject,
} from '../observables';
import { ActiveEditables } from '../state';
import { EditorType, IInitOptions, ITargetWithType } from '../types';
import { noop, targetFromSelectionAnchor } from '../utils';

export const bootstrapFactory = (
  opts: IInitOptions,
  contentWindow: Window = window,
) => {
  const observerOptions = {
    attributes: true,
    childList: true,
    subtree: true,
  };
  const documentMutations$ = new BehaviorSubject<MutationRecord[]>([]);
  const observer = new MutationObserver(mutations => {
    const cond = mutations.some(m => {
      if (m.target.nodeType !== Node.ELEMENT_NODE) {
        return true;
      }
      const el = m.target as Element;
      return !el.classList.contains('perfecttense-general');
    });
    if (cond) {
      documentMutations$.next(mutations);
    }
  });
  observer.observe(contentWindow.document, observerOptions);
  const documentMouseMoves$ = mouseMoveFromDoc(contentWindow);
  const scroll$ = scrollFromBody(contentWindow);
  const elementFromPoint$ = elementFromPoint(
    documentMouseMoves$,
    contentWindow,
  );

  const {
    autoload = false,
    clientId,
    grammarScoreCallback = noop,
    onGrammarScoreChanged = noop,
    socketUrl,
    targetSelector,
  } = opts;

  const internalGrammarScoreCallback = (score: number, target: HTMLElement) => {
    grammarScoreCallback(score, target);
    onGrammarScoreChanged(score, target.id);
  };

  let isTarget: (el: HTMLElement) => boolean = el => true;

  if (targetSelector) {
    if (typeof targetSelector === 'string') {
      isTarget = el =>
        el.matches(targetSelector) || el.matches(`${targetSelector} *`);
    } else if (Array.isArray(targetSelector)) {
      isTarget = el =>
        [...targetSelector, ...targetSelector.map(s => `${s} *`)].some(s =>
          el.matches(s),
        );
    } else {
      isTarget = targetSelector;
    }
  }

  const onConnect = () => {
    const activeElement = contentWindow.document.activeElement;
    let initialTargets: ITargetWithType[] = [];
    if (activeElement) {
      // if there's a valid target element focused when PT loads, we want to initialize an editor instance on it
      let type: EditorType;
      let target: HTMLElement | null;
      if (activeElement.tagName === 'TEXTAREA') {
        type = EditorType.Textarea;
        target = activeElement as HTMLTextAreaElement;
      } else if (
        activeElement.tagName === 'IFRAME' &&
        (activeElement as HTMLIFrameElement).contentDocument &&
        (activeElement as HTMLIFrameElement).contentDocument!.body
      ) {
        type = EditorType.IframeContentEditable;
        target = activeElement as HTMLIFrameElement;
      } else {
        type = EditorType.ContentEditable;
        target = targetFromSelectionAnchor(activeElement);
      }
      if (target && isTarget(target)) {
        const sign = ActiveEditables.addEditable(type, target);
        initialTargets = [{ sign, target, type }];
      }
    }
    let addedTargets: Observable<ITargetWithType> = EMPTY;
    if (autoload) {
      const initialTextareaTargets: ITargetWithType[] = Array.from(
        contentWindow.document.querySelectorAll('textarea'),
      )
        .map(target => {
          const sign = ActiveEditables.addEditable(EditorType.Textarea, target);
          return { sign, target, type: EditorType.Textarea };
        })
        .filter(({ sign }) => sign !== null);

      const initialContentEditableTargets: ITargetWithType[] = (Array.from(
        contentWindow.document.querySelectorAll('[contenteditable=true]'),
      ) as HTMLElement[])
        .map((t: HTMLElement): {
          sign: string | null;
          target: HTMLElement;
          type: EditorType;
        } | null => {
          const target = targetFromSelectionAnchor(t);
          if (!target) {
            return null;
          }
          const sign = ActiveEditables.addEditable(
            EditorType.ContentEditable,
            target,
          );
          return { sign, target, type: EditorType.ContentEditable };
        })
        .filter((x): x is ITargetWithType => x !== null && x.sign !== null);

      const initialIframeTargets: ITargetWithType[] = Array.from(
        contentWindow.document.querySelectorAll('iframe'),
      )
        .filter(
          iframe =>
            iframe.contentDocument &&
            iframe.contentDocument.querySelector('[contenteditable=true]'),
        )
        .map(target => {
          const sign = ActiveEditables.addEditable(
            EditorType.IframeContentEditable,
            target,
          );
          return { sign, target, type: EditorType.IframeContentEditable };
        })
        .filter(({ sign }) => sign !== null);

      initialTargets = [
        ...initialTextareaTargets,
        ...initialContentEditableTargets,
        ...initialIframeTargets,
      ];

      addedTargets = merge(
        newlyAddedNonIframeEditables(documentMutations$.asObservable()),
        newlyAddedIframeEditables(documentMutations$.asObservable()),
      ).pipe(
        mergeMap((targets: HTMLElement[]) => {
          return targets.map(target => {
            let type: EditorType;
            switch (target.nodeName) {
              case 'TEXTAREA':
                type = EditorType.Textarea;
                break;
              case 'IFRAME':
                type = EditorType.IframeContentEditable;
                break;
              default:
                type = EditorType.ContentEditable;
            }
            const sign = ActiveEditables.addEditable(type, target);
            return { sign, target, type };
          });
        }),
        filter(({ sign }) => sign !== null),
      );
    }
    merge(
      from(initialTargets.filter(({ sign }) => sign !== null)),
      addedTargets,
      newlyFocusedNonIframeEditables(contentWindow),
      newlyFocusedIframeEditables(
        Array.from(contentWindow.document.querySelectorAll('iframe')),
        contentWindow,
      ),
      newlyAddedFocusedIframeEditables(
        documentMutations$.asObservable(),
        contentWindow,
      ),
    )
      .pipe(filter(value => isTarget(value.target)))
      .subscribe({
        next: ({ sign, target, type }) => {
          const currentBodyScroll = {
            scrollX: contentWindow.pageXOffset,
            scrollY: contentWindow.pageYOffset,
            target: (contentWindow.document as unknown) as HTMLElement,
          };
          if (type === EditorType.Textarea) {
            bootstrapTextarea(
              documentMutations$.asObservable(),
              scroll$.pipe(startWith(currentBodyScroll)),
              socketSubject,
              target as HTMLTextAreaElement,
              sign as string,
              documentMouseMoves$,
              elementFromPoint$,
              internalGrammarScoreCallback,
              contentWindow,
            );
          } else {
            bootstrapEditable(
              documentMutations$.asObservable(),
              scroll$.pipe(startWith(currentBodyScroll)),
              socketSubject,
              target as HTMLDivElement,
              sign as string,
              documentMouseMoves$,
              elementFromPoint$,
              noop,
              null,
              internalGrammarScoreCallback,
              contentWindow,
            );
          }
        },
      });
  };
  const socketSubject = new WebsocketSubject(socketUrl!, clientId, onConnect);
  socketSubject.connect();
};
