import { fromEvent } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { bootstrapFactory } from '../bootstrap';
import { IInitOptions } from '../types';

const bootstrap = (options: IInitOptions, contentWindow: Window) => {
  bootstrapFactory(options, contentWindow);
  if (process.env.NODE_ENV === 'development') {
    (contentWindow as any).appReady = true;
  }
};

export const initialize = (
  options: IInitOptions,
  contentWindow: Window = window,
) => {
  options = {
    clientId: '',
    socketUrl: 'https://io.perfecttense.com',
    ...options,
  };
  const { clientId } = options;
  if (clientId.trim().length === 0) {
    throw new Error('Please provide valid credentials.');
  }

  if (contentWindow.document.readyState !== 'complete') {
    const onLoadEvent = fromEvent(contentWindow, 'load');
    const delayedLoadEvent = onLoadEvent.pipe(
      delay(300),
      take(1),
    );
    delayedLoadEvent.subscribe({
      next: () => {
        bootstrap(options, contentWindow);
      },
    });
  } else {
    bootstrap(options, contentWindow);
  }
};
