import { EditorType } from '../types';

interface IActiveEditableAggregate {
  count: number;
  targetMap: Map<string, HTMLElement>;
}

export class ActiveEditables {
  public static addEditable(type: EditorType, el: HTMLElement): string | null {
    ActiveEditables.addType(type);
    const { count, targetMap } = ActiveEditables.initializedMap.get(type)!;
    if (ActiveEditables.hasTarget(type, el)) {
      // tslint:disable-next-line: no-console
      // console.log('Already initialied target. Skipping...', el);
      return null;
    }
    const newSign = `${type}-${count}`;
    const newTargetMap = targetMap.set(newSign, el);
    ActiveEditables.initializedMap = ActiveEditables.initializedMap.set(type, {
      count: count + 1,
      targetMap: newTargetMap,
    });
    return newSign;
  }

  public static removeEditable(sign: string): boolean {
    const type = sign.split('-')[0] as EditorType;
    const { count, targetMap } = ActiveEditables.initializedMap.get(type)!;
    const deleted = targetMap.delete(sign);
    if (deleted) {
      ActiveEditables.initializedMap = ActiveEditables.initializedMap.set(
        type,
        {
          count,
          targetMap,
        },
      );
    }
    return deleted;
  }

  public static hasTarget(type: EditorType, target: HTMLElement): boolean {
    if (!ActiveEditables.initializedMap.has(type)) {
      return false;
    }
    const { targetMap } = ActiveEditables.initializedMap.get(type)!;
    const typeEditables = new Set<HTMLElement>(targetMap.values());
    return typeEditables.has(target);
  }

  private static initializedMap = new Map<
    EditorType,
    IActiveEditableAggregate
  >();

  private static addType(type: EditorType) {
    if (ActiveEditables.initializedMap.has(type)) {
      return;
    }
    ActiveEditables.initializedMap = ActiveEditables.initializedMap.set(type, {
      count: 0,
      targetMap: new Map<string, HTMLElement>(),
    });
  }
}
