import { Map, Record } from 'immutable';
import Delta from 'quill-delta';
import { applyDeltaToString } from '.';
import { Correction } from '..';
import { logger } from '../log';

const tokenConsole = logger(false);

export interface ITokenParams {
  id: number;
  value: string;
  after: string;
  offsetInSentence: number;
  offsetInSentenceWithoutNewline: number;
  correction: string | null;
  ignored: Correction | null;
}

export class Token extends Record<ITokenParams>(
  {
    after: '',
    correction: null,
    id: 0,
    ignored: null,
    offsetInSentence: 0,
    offsetInSentenceWithoutNewline: 0,
    value: 'DEFAULT_TOKEN',
  },
  'Token',
) {
  private static nextId = 1;
  // Int // String // String // Int (character offset) // optional String key // Boolean
  constructor(args: Partial<ITokenParams> = {}) {
    const id = args.id || Token.nextId++;
    super({ ...args, id });
  }

  get text() {
    return `${this.value}${this.after}`;
  }

  public applyDelta(
    delta: Delta | null,
    offset: number,
    offsetWithoutNewline: number,
    prevCorrection: string | null = null,
    prevIgnored: Correction | null = null,
    isLastTokenInLastSentence: boolean = false,
  ) {
    let updatedToken = this;
    let correctionsDiff = Map<string, Correction | null>();
    tokenConsole.log('token apply delta', {
      delta,
      offset,
      prevCorrection,
    });
    const { delta: newDelta, str: newText } = applyDeltaToString(
      delta,
      this.text,
      isLastTokenInLastSentence,
    );
    const unchanged = this.text === newText;
    tokenConsole.log('after apply delta to string', {
      newDelta,
      newText,
      unchanged,
    });
    if (unchanged) {
      if (prevCorrection && prevCorrection === this.correction) {
        updatedToken = updatedToken.merge({
          correction: null,
          ignored: null,
          offsetInSentence: offset,
          offsetInSentenceWithoutNewline: offsetWithoutNewline,
        });
      } else if (prevIgnored && prevIgnored === this.ignored) {
        updatedToken = updatedToken.merge({
          correction: null,
          ignored: null,
          offsetInSentence: offset,
          offsetInSentenceWithoutNewline: offsetWithoutNewline,
        });
      } else {
        updatedToken = updatedToken.merge({
          offsetInSentence: offset,
          offsetInSentenceWithoutNewline: offsetWithoutNewline,
        });
      }
    } else {
      updatedToken = updatedToken.merge({
        after: '',
        correction: null,
        ignored: null,
        offsetInSentence: offset,
        offsetInSentenceWithoutNewline: offsetWithoutNewline,
        value: newText || '',
      });
    }
    if (this.correction && !updatedToken.correction) {
      correctionsDiff = correctionsDiff.set(this.correction, null);
    }
    return {
      correctionsDiff,
      delta: newDelta,
      token: updatedToken,
      tokenChanged: !unchanged,
    };
  }
} // class Token
