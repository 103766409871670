import * as React from 'react';

import '../styles/Correction.css';
import '../styles/Main.css';
import { TransformationType, UnderlineThickness } from '../types';

interface ICorrectionProps {
  correctionKey: string;
  height: number;
  highlighted: boolean;
  left: number;
  underlineThickness: UnderlineThickness;
  top: number;
  type: TransformationType;
  width: number;
  sign: string;
  visible: boolean;
}

export const Correction = React.memo((props: ICorrectionProps) => {
  const {
    correctionKey,
    height,
    highlighted,
    underlineThickness,
    left,
    top,
    type,
    width,
    sign,
    visible,
  } = props;
  const transform = `translate(${left}px, ${top}px)`;
  const highlightedClass = highlighted ? 'correction-highlighted' : '';

  return (
    <div
      data-cy={`pt-correction-${sign}`}
      data-k={correctionKey}
      className={`perfecttense-general correction correction-${type} ${highlightedClass} correction-${underlineThickness} correction-${
        visible ? 'visible' : 'hidden'
      }`}
      style={{ height, transform, width }}
    />
  );
});
